.add_subs, .edit_sub, .add_coupon, .edit_coupon {
	.double-grid {
		display: grid;
		grid-template-columns: 1fr 1fr;
		gap: 1rem;
	}
	.triple-grid {
		display: grid;
		grid-template-columns: 1fr 1fr 1fr;
		gap: 1rem;
	}
	padding: 2rem 2rem 6rem 2rem;
	width: 95%;

	article {
		h4 {
			font-weight: bold;
			font-size: 1.6rem;
			margin-bottom: 2rem;
		}

		form {
			margin: auto;
			display: grid;
			width: 90%;
			gap: 1rem 4rem;

			.datepicker-toggle-button {
				content: "";
				display: block;
				box-sizing: border-box;
				position: absolute;
				width: 20px;
				height: 20px;
				bottom: 8px;
				right: 10px;
				background: transparent url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTkiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTUgMHYxaDhWMGgxdjFoMmEyIDIgMCAwMTIgMnYxNGEyIDIgMCAwMS0yIDJIMmEyIDIgMCAwMS0yLTJWM2EyIDIgMCAwMTItMmgyVjBoMXptMTIgN0gxdjEwYTEgMSAwIDAwMSAxaDE0YTEgMSAwIDAwMS0xVjd6TTQgMTZ2MUgydi0xaDJ6bTMgMHYxSDV2LTFoMnptMyAwdjFIOHYtMWgyem0tNi0ydjFIMnYtMWgyem0zIDB2MUg1di0xaDJ6bTMgMHYxSDh2LTFoMnptMyAwdjFoLTJ2LTFoMnptMyAwdjFoLTJ2LTFoMnpNNCAxMnYxSDJ2LTFoMnptMyAwdjFINXYtMWgyem0zIDB2MUg4di0xaDJ6bTMgMHYxaC0ydi0xaDJ6bTMgMHYxaC0ydi0xaDJ6TTQgMTB2MUgydi0xaDJ6bTMgMHYxSDV2LTFoMnptMyAwdjFIOHYtMWgyem0zIDB2MWgtMnYtMWgyem0zIDB2MWgtMnYtMWgyek03IDh2MUg1VjhoMnptMyAwdjFIOFY4aDJ6bTMgMHYxaC0yVjhoMnptMyAwdjFoLTJWOGgyek00IDJIMmExIDEgMCAwMC0xIDF2M2gxNlYzYTEgMSAwIDAwLTEtMWgtMnYzaC0xVjJINXYzSDRWMnoiIGZpbGw9IiNBMEFFQzAiIGZpbGwtcnVsZT0iZXZlbm9kZCIvPjwvc3ZnPg==') no-repeat center center;
				z-index: 1;
				&:hover {
					cursor: pointer;
				}
			}
			#image {
				height: auto;
				padding: 0 0.5rem;
			}
			.img {
				display: flex;
				flex-direction: column;
				.content {
					margin: 2.5rem 0;
					input {
						display: none;
					}
					label {
						cursor: pointer;
						padding: 1rem;
						border-radius: 50px;
						border: 1px solid #FFFFFF;
					}
					img {
						margin-left: 8rem;
						max-width: 200px;
						height: auto;
					}
			}
			}
			.input {
				position: relative;
				display: flex;
				flex-flow: row wrap;
				margin-bottom: 1.5rem;
				&:hover {
					&.img {
						img {
							display: block;
						}
					}
				}
				.access {
					display: flex;
					width: 100%;
					input {
						height: auto;
						width: 10%;
					}
				}

				label:not(.radio) {
					display: block;
					width: 100%;
					min-width: 25rem;
					height: 0.7rem;
					padding: 0.8rem 1rem;
					background-color: rgba(202, 211, 255, 0.0676517);
					border-radius: 0.3rem 0.3rem 0 0;
					font-size: 1.1rem;
				}

				input {
					display: block;
					width: 100%;
					min-width: 25rem;
					height: 0.4rem;
					position: relative;
					font-family: $fontRegular;
					padding: 0.8rem 1rem 1.2rem;
					background-color: rgba(202, 211, 255, 0.0676517);
					border: none;
					border-bottom: 2px solid $white;
					color: grey;
					border-radius: 0 0 0.3rem 0.3rem;

					&::placeholder {
						color: rgba(255, 255, 255, 0.6);
					}

					&[type="text"], &[type="email"] {
						padding: 0.8rem 1rem 1.6rem;
					}
					&[type="date"]::-webkit-calendar-picker-indicator {
						opacity: 0
					}
				}

				select {
					width: calc(100% + 2rem);
					padding: 0.4rem 0.5rem 0.5rem;

					border-radius: 0 0 0.3rem 0.3rem;
					background-color: rgba(202, 211, 255, 0.0676517);
					border: none;
					border-bottom: 2px solid $white;
					color: $white;
					font-family: $fontRegular;
					option {
						color: $white;
						background: rgb(66, 74, 87);
					}
				}

				&.select {
					label,
					input {
						color: $white !important;
						background-color: rgba(202, 211, 255, 0.24) !important;
					}
				}

				.errorDisplay {
					position: absolute;
					top: 50%;
					transform: translateY(-50%);
					right: -1rem;
					color: $red;

					font-size: 1.3rem;
					font-weight: bolder;
					text-transform: uppercase;
					animation: wiggleSmall 0.1s linear 3;
				}
				&.select {
					label,
					input {
						color: $white !important;
						background-color: rgba(202, 211, 255, 0.24) !important;
					}
				}

				&.error::before {
					content: " ";
					display: block;
					position: absolute;
					left: 0rem;
					height: 100%;
					width: 0.3rem;
					border-radius: 0.2rem;
					background-color: $red;

					animation: wiggle 0.1s linear 4;
				}
			}
			input[type="submit"] {
				width: 13rem;
				background: transparent;
				padding: 1rem;
				border: 2px solid $white;
				border-radius: 1.5rem;
				color: $white;
				margin-bottom: 1rem;
			}
			.submit {
				display: flex;
                position: static;
			}
		}
	}
}
@media (max-width: $mobile) {
	.add_subs, .add_coupon{
		width: auto;
	}
}
