.bo-slideshow {
	> nav {
		margin-top: 1rem;
		ul {
			width: 80%;
			margin: auto;
			display: flex;
			justify-content: center;

			li:not(:last-child) {
				border: 1px $white solid;
				border-radius: 2rem;
				padding: 1rem;
				margin-right: 3rem;
				transition: 0.2s all;
				&:hover {
					background-color: lighten($blue, 40%);
					border-color: transparent;
					transition: 0.2s all;
				}
				&.selected {
					background-color: lighten($blue, 40%);
					border-color: transparent;
				}
			}
		}
	}

	.switch-block {
		display: flex;
		align-items: center;
		grid-gap: 10px;
	}

	.event {
		overflow: hidden;
		border-radius: 1rem;
		position: relative;
		aspect-ratio: 16/9;
		width: 30rem;
		height: 15rem;
		margin-bottom: 1rem;
		&.display-1 {
			position: relative;
			.title {
				position: absolute;
				bottom: 12%;
				left: 20%;
				z-index: 5;
				h1 {
					font-weight: 900;
					font-size: 0.8rem;
					margin-bottom: 0.4rem;
					backdrop-filter: blur(4px);
					border: 1px solid rgba(255, 255, 255, 0.6);
					display: block;
					padding: 0.5rem 0.5rem;
					transform: skewX(195deg);
					span {
						transform: skewX(-195deg);
						display: block;
						text-align: center;
					}
				}

				h2 {
					font-weight: 600;
					font-size: 0.6rem;
				}
			}
			.decorations {
				user-select: none;
				position: absolute;
				bottom: 0;
				width: 100%;
				img {
					position: absolute;
					bottom: 0;
					left: 0;
					height: 4rem;

					&:last-of-type {
						left: unset;
						right: 0;

						height: 5rem;
					}
				}
			}
			.dots {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				z-index: 3;
				img {
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					z-index: 3;
				}
			}
			.play {
				pointer-events: none;
				@include center(true, true);
				z-index: 5;

				transform: translate(-50%, -50%);

				border-radius: 50%;
				width: 2rem;
				height: 2rem;

				background: rgba(255, 255, 255, 0.31);
				border: 1px solid #ffffff;

				display: flex;
				justify-content: center;
				align-items: center;

				img {
					width: 1rem;
					height: 1rem;
					position: relative;
					left: 0.1rem;
				}
			}
			> img {
				width: 100%;
				height: 100%;
			}
		}

		&.display-2 {
			$space: -0.4rem;
			.title {
				z-index: 5;
				@include center(false, true);
				top: 60%;
				h1 {
					position: relative;
					width: 20rem;
					display: flex;
					justify-content: space-evenly;

					> div {
						position: relative;

						span.team {
							width: 4rem;
							font-size: 1rem;
							font-weight: bolder;
							white-space: nowrap;
							overflow-x: hidden;
							text-align: center;
							display: block;
							background-color: rgba(239, 47, 42, 1);
							padding: 0.5rem 1.6rem;
							clip-path: polygon(10% 0%, 100% 0%, 90% 100%, 0% 100%);
							position: relative;
						}
						.white-strip-top,
						.white-strip-bottom {
							height: 0.05rem;
							width: 20%;
							background-color: $white;
							position: absolute;

							top: -0.15rem;
							left: 0.8rem;

							&::after {
								content: "";
								display: block;
								height: 0.05rem;
								width: 30%;
								background-color: $white;
								position: absolute;

								top: 0rem;
								left: calc(100% + 0.5rem);
							}
						}
						.white-strip-bottom {
							top: unset;
							bottom: -0.15rem;
							left: unset;
							right: 0.8rem;
							transform: scaleX(-100%);
						}
						.decorations {
							display: block;
							position: absolute;
							top: 0;
							left: calc(#{-$space} / 2);
							height: 100%;
							li {
								display: inline-block;
								margin: 0 $space;
								width: 0.2rem;
								height: 100%;
								background-color: rgba(239, 47, 42, 1);
								transform: skewX(-22deg);
								position: relative;
								&::after,
								&::before {
									content: "";
									display: block;
									height: 50%;
									width: 0.05rem;
									background-color: $white;
									position: absolute;
									top: 0;
									left: 0;
								}
								&::before {
									display: none;
								}
								&:nth-of-type(1) {
									&::after {
										height: 35%;
										top: 75%;
										left: 0;
									}
								}
								&:nth-of-type(2) {
									&::after {
										height: 35%;
										top: -10%;
										left: 100%;
									}
								}
								&:nth-of-type(3) {
									&::after {
										height: 35%;
										top: 50%;
										transform: translateY(-50%);
										left: 100%;
									}
								}
								&:nth-of-type(4) {
									&::after {
										height: 35%;
										top: 70%;

										left: 100%;
									}
									&::before {
										display: block;
										height: 35%;
										top: 0%;

										left: 0;
									}
								}
								&:nth-of-type(5) {
									&::after {
										height: 75%;
										top: -12%;
										left: 0;
									}
								}
							}
						}

						&:last-of-type {
							span.team {
								clip-path: polygon(0% 0%, 90% 0%, 100% 100%, 10% 100%) !important;
								padding: 0.5rem 1.6rem 0.5rem 1.6rem;
							}
							.decorations {
								right: calc(#{-$space} / 2);
								transform: scaleX(-100%);
								li {
									transform: skewX(-25deg);
								}
							}

							.white-strip-top {
								left: unset;
								right: 1.8rem;
							}
							.white-strip-bottom {
								left: 1.8rem;
								right: unset;
							}
						}
					}
				}
				h2 {
					font-size: 0.8rem;
					font-weight: normal;
					text-shadow: 0.8px 0.8px 0 black;
					text-align: center;
					position: relative;
					top: 1rem;
				}
			}
			.play {
				pointer-events: none;
				cursor: pointer;

				z-index: 5;

				@include center(false, true);
				top: 60%;
				border-radius: 50%;
				width: 1.7rem;
				height: 1.7rem;

				background: rgba(255, 255, 255, 0.31);
				border: 1px solid #ffffff;

				display: flex;
				justify-content: center;
				align-items: center;

				img {
					width: 1rem;
					height: 1rem;
					position: relative;
					left: 0.1rem;
				}
			}
			.decorations {
				display: none;
			}
			.dots {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				z-index: 3;
				img {
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					z-index: 3;
				}
			}
			> img {
				width: 100%;
				height: 100%;
			}
		}

		&.display-3, &.display-4 {
			$space: -0.4rem;
			.title {
				z-index: 5;
				@include center(false, true);
				top: 60%;
				h1 {
					font-weight: 900;
					font-size: 0.8rem;
					margin-bottom: 0.4rem;
					-webkit-backdrop-filter: blur(4px);
					backdrop-filter: blur(4px);
					border: 1px solid rgba(255, 255, 255, 0.6);
					display: block;
					padding: 0.5rem 0.5rem;
					transform: skewX(195deg);
					span {
						transform: skewX(-195deg);
						display: block;
						text-align: center;
					}
				}
			}
			.play {
				pointer-events: none;
				@include center(true, true);
				z-index: 5;
				transform: translate(-50%, -50%);
				border-radius: 50%;
				width: 2rem;
				height: 2rem;
				background: rgba(255, 255, 255, 0.31);
				border: 1px solid #ffffff;
				display: flex;
				justify-content: center;
				align-items: center;

				img {
					width: 1rem;
					height: 1rem;
					position: relative;
					left: 0.1rem;
				}
			}
			.decorations {
				user-select: none;
				position: absolute;
				bottom: 0;
				width: 100%;
				img {
					position: absolute;
					bottom: 0;
					left: 0;
					height: 4rem;

					&:last-of-type {
						left: unset;
						right: 0;

						height: 5rem;
					}
				}
			}
			.dots {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				z-index: 3;
				img {
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					z-index: 3;
				}
			}
			> img {
				width: 100%;
				height: 100%;
			}
		}

	}
	.bo-slide-component {
		@import "./handleSlideShow";
		@import "./editSlide";
		@import "./slideEditor";
	}
}
