.bo-webTVS {
	margin-top: 2rem;

	position: relative;
	.sticky {
		position: absolute;
		top: 3rem;
		right: -1rem;
		width: 0rem;
		.submit {
			width: 20rem;
		}
	}
	.list {
		background-color: rgba(129, 141, 207, 0.274);
		border-radius: 2rem;
		padding: 2rem;
		height: 100%;
		.search {
			max-width: 30rem !important;
			margin-bottom: 1rem;
			position: relative;
			label {
				position: absolute;
				top: 0rem;
				left: 0rem;
				width: 100%;
				svg {
					fill: $white;
					width: 1.5rem;
					top: 0.2rem;
					left: 0.4rem;
					position: absolute;

					&.cross {
						cursor: pointer;
						left: unset;
						right: 1.2rem;
						fill: darken(darkgray, 40%);
						z-index: 4;
					}
				}
			}

			input {
				display: block;
				width: 90%;
				height: 0.4rem;

				position: relative;
				font-family: $fontRegular;
				padding: 1.2rem 0rem 1.2rem 2.1rem;
				background-color: lighten(rgba(202, 211, 255, 0.336), 10%);
				border: none;
				border-bottom: 2px solid $white;
				color: $white;
				border-radius: 0.3rem;
				&::placeholder {
					color: rgba(99, 85, 85, 0.6);
				}
			}

			.result {
				position: absolute;
				top: 100%;
				z-index: 10;
				background-color: rgb(38, 42, 68);
				width: calc(100% + 0.5rem);
				border-radius: 0 0 1rem 1rem;
				overflow: hidden;
				display: none;
				&.use {
					display: block;
				}
				ul {
					li {
						padding: 1rem;
						border-bottom: 1px solid black;
						position: relative;
						&:last-of-type {
							border-bottom: unset;
							padding-bottom: 1.1rem;
						}

						svg {
							fill: $red;
							width: 1.5rem;
							top: 50%;
							right: 0.8rem;
							transform: translateY(-50%);
							position: absolute;

							&.added {
								fill: $green;
							}
						}
					}
				}
			}
		}

		.webtvs {
			display: flex;

			.tv {
				margin-right: 1rem;
				min-width: 13rem;
			}
		}
	}
	.webtvs {
		width: 100%;
		overflow-x: auto;
		overflow-y: hidden;
		max-width: 75vw;
	}

	.tv {
		//min-width: 15rem !important;
		//max-width: 16rem !important;

		border-radius: 8px;
		text-align: center;
		height: 10rem;
		display: flex;
		align-items: center;
		justify-content: center;
		margin-bottom: 1rem;
		position: relative;
		&::after {
			content: attr(data-number);
			position: absolute;
			display: block;
			top: 0.5rem;
			left: 0.5rem;
			z-index: 550;
			width: 0.75rem;
			height: 0.75rem;
			color: $white;
			font-size: 1.2rem;
			line-height: 0.8rem;
			background-color: lighten($blue, 30%);
			padding: 1rem;
			border-radius: 50%;
			z-index: 4;
		}
		.edit {
			cursor: pointer;
			position: absolute;
			top: 0.5rem;
			right: 0.5rem;
			display: flex;
			flex-direction: column;
			align-items: center;
			.cross {
				filter: drop-shadow(1px 1px 1.3px rgba(0, 0, 0, 0.938));
			}
			svg {
				fill: $white;
				margin-bottom: 1rem;
			}
			.color {
				position: relative;
				filter: drop-shadow(1px 1px 1.3px rgba(0, 0, 0, 0.938));
				input {
					cursor: pointer;
					appearance: none;
					border: none;
					border-radius: 1rem;
					width: 2rem;
					height: 2rem;
					background-color: transparent;
					opacity: 0;
					z-index: 5;
					padding: 0;
					border-color: transparent;
					outline: transparent 0px solid;
				}
				&::before {
					pointer-events: none;
					cursor: pointer;
					content: " ";
					width: 2rem;
					height: 2rem;
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%);

					mask: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBkPSJNOC45OTcgMTMuOTg1Yy4wMSAxLjEwNC0uODggMi4wMDgtMS45ODYgMi4wMTUtMS4xMDUuMDA5LTIuMDA1LS44OC0yLjAxMS0xLjk4NC0uMDEtMS4xMDUuODc5LTIuMDA1IDEuOTgyLTIuMDE2IDEuMTA2LS4wMDcgMi4wMDkuODgzIDIuMDE1IDEuOTg1em0tLjk3OC0zLjk4NmMtMS4xMDQuMDA4LTIuMDA4LS44OC0yLjAxNS0xLjk4Ny0uMDA5LTEuMTAzLjg3Ny0yLjAwNCAxLjk4NC0yLjAxMSAxLjEwMi0uMDEgMi4wMDguODc3IDIuMDEyIDEuOTgyLjAxMiAxLjEwNy0uODggMi4wMDYtMS45ODEgMi4wMTZ6bTcuOTgxLTQuMDE0Yy4wMDQgMS4xMDItLjg4MSAyLjAwOC0xLjk4NSAyLjAxNS0xLjEwNi4wMS0yLjAwOC0uODc5LTIuMDE1LTEuOTgzLS4wMTEtMS4xMDYuODc4LTIuMDA2IDEuOTg1LTIuMDE1IDEuMTAxLS4wMDYgMi4wMDUuODgxIDIuMDE1IDEuOTgzem0tMTIgMTUuODQ3YzQuNTg3LjM4IDIuOTQ0LTQuNDkyIDcuMTg4LTQuNTM3bDEuODM4IDEuNTM0Yy40NTggNS41MzctNi4zMTUgNi43NzItOS4wMjYgMy4wMDN6bTE0LjA2NS03LjExNWMxLjQyNy0yLjIzOSA1Ljg0Ni05Ljc0OCA1Ljg0Ni05Ljc0OC4zNTMtLjYyMy0uNDI5LTEuMjczLS45NzUtLjgxMyAwIDAtNi41NzIgNS43MTQtOC41MTEgNy41MjUtMS41MzIgMS40MzItMS41MzkgMi4wODYtMi4wMzUgNC40NDdsMS42OCAxLjRjMi4yMjctLjkxNSAyLjg2OC0xLjA0IDMuOTk1LTIuODExem0tMTIuNjIyIDQuODA2Yy0yLjA4NC0xLjgyLTMuNDItNC40NzktMy40NDMtNy40NDctLjA0NC01LjUxIDQuNDA2LTEwLjAzIDkuOTItMTAuMDc1IDMuODM4LS4wMjEgNi40NzkgMS45MDUgNi40OTYgMy40NDdsMS42NjMtMS40NTZjLTEuMDEtMi4yMjMtNC4xODItNC4wNDUtOC4xNzYtMy45OTItNi42MjMuMDU1LTExLjk1NSA1LjQ2Ni0xMS45MDMgMTIuMDkyLjAyMyAyLjkxMiAxLjA4MyA1LjU3IDIuODIzIDcuNjM1Ljk1OC40OTIgMi4xMjMuMzI5IDIuNjItLjIwNHptMTIuNzk3LTEuOTA2YzEuMDU5IDEuOTctMS4zNTEgMy4zNy0zLjU0NSAzLjk5Mi0uMzA0LjkxMi0uODAzIDEuNzIxLTEuMzc0IDIuMzExIDUuMjU1LS41OTEgOS4wNjEtNC4zMDQgNi4yNjYtNy44ODktLjQ1OS42ODUtLjg5NyAxLjE5Ny0xLjM0NyAxLjU4NnoiLz48L3N2Zz4=");
					mask-size: contain;
					mask-repeat: no-repeat;
					filter: drop-shadow(0 0 0.75rem crimson);
					background-color: $white;
				}
			}
			.clear {
				position: relative;
				filter: drop-shadow(1px 1px 1.3px rgba(0, 0, 0, 0.938));
				top: 0.6rem;
			}
		}
		img {
			width: 82%;
			height: 82%;
			object-fit: contain;
			user-select: none;
		}

		h4 {
			display: none;
			color: white !important;
		}
	}
}
