.bo-translations, .bo-all_subs, .bo-all_accounts, .bo-all_coupons{
	margin: 0 2rem;
	h2 {
		font-size: 3rem;
		border-bottom: 1px solid grey;
		margin-bottom: 2rem;
		padding-bottom: 1rem;
		text-transform: uppercase;
		font-weight: bolder;
	}
	.controls {
		margin-top: 1rem;
		display: flex;
		justify-content: space-evenly;
		.pages {
			display: flex;
			flex-direction: column;
			align-items: center;
			button {
				border: none;
				background-color: lighten($blue, 30%);
				color: $white;
				padding: 1rem;
				border-radius: 1rem;
				margin-right: 1.2rem;
			}
			p {
				font-style: italic;
				margin-top: 0.8rem;
			}
		}
		.offset {
			label {
				margin-right: 1rem;
			}
			input {
				background-color: lighten($blue, 30%);
				color: $white;
				border: none;
				padding: 1rem;
				text-align: center;
				border-radius: 1rem;
			}
		}
		.search {
			max-width: 30rem !important;

			position: relative;
			label {
				position: absolute;
				top: 0rem;
				left: 0rem;
				width: 100%;
				svg {
					fill: $white;
					width: 1.5rem;
					top: 0.2rem;
					left: 0.4rem;
					position: absolute;

					&.cross {
						cursor: pointer;
						left: unset;
						right: 1.2rem;
						fill: darken(darkgray, 40%);
						z-index: 4;
					}
				}
			}

			input {
				display: block;
				width: 90%;
				height: 0.4rem;

				position: relative;
				font-family: $fontRegular;
				padding: 1.2rem 0rem 1.2rem 2.1rem;
				background-color: lighten(rgba(202, 211, 255, 0.336), 10%);
				border: none;
				border-bottom: 2px solid $white;
				color: $white;
				border-radius: 0.3rem;
				&::placeholder {
					color: rgba(180, 179, 179, 0.918);
				}
			}
		}
	}
	table {
		margin-top: 2rem;
		width: 100%;
		thead {
			font-weight: bolder;
			text-transform: uppercase;
			border-bottom: 1px solid $white;
			tr {
				background-color: lighten($blue, 30%) !important;
			}
		}
		tr:nth-of-type(2n) {
			background-color: rgba(255, 255, 255, 0.3);
		}
		tr:nth-of-type(2n + 1) {
			background-color: rgba(255, 255, 255, 0.089);
		}
		td,
		th {
			padding: 1rem;
			border-right: 1px solid $white;
			vertical-align: middle;
			button {
				border: none;
				background-color: #11572b;
				color: $white;
				padding: 1rem;
				border-radius: 1rem;
				margin-right: 1.2rem;
			}
			&:last-of-type {
				border-right: none;
			}
		}
		tbody {
			td:not(:first-of-type) {
				cursor: pointer;
				&:hover {
					background-color: lighten(rgba(255, 255, 255, 0.452), 80%);
				}
			}
		}
		td {
			//width: 25% !important;
		}
	}
	.editor {
		padding: 2rem;
		background-color: rgba(29, 37, 75, 0.956);
		width: 35vw;
		min-width: 35rem;
		border-radius: 1rem;
		position: fixed;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		.controls {
			justify-content: center;
			button {
				width: 4rem;
				margin: 0 0.75rem;
				border: none;
				background-color: lighten($blue, 30%);
				color: $white;
				font-family: $fontRegular;
				padding: 1rem;
				border-radius: 1rem;

				&.bold {
					font-weight: bolder;
				}
				&.italic {
					font-style: italic;
				}

				&.underline {
					text-decoration: underline;
				}
			}
		}
		.data-controls {
			display: flex;
			justify-content: center;

			svg {
				margin-right: 1rem;
				margin-left: 1rem;
				transition: 0.1s all;
				fill: $green;
				cursor: pointer;
				&:first-of-type {
					fill: $red;
				}
				&:hover {
					transform: scale(1.1);
					transition: 0.1s all;
				}
			}
		}
		.DraftEditor-root {
			color: black;
			background-color: $white;
			padding: 1rem;
			border-radius: 1rem;
			margin: 1rem;
		}
	}
}
