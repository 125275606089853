.popup {
	position: fixed;
	right: 3.75%;
	top: calc(100% - 22rem);
	padding: 1.5rem;
	font-size: 14px;
	line-height: 20px;
	background: linear-gradient(0deg, rgba(0, 0, 0, 0.24), rgba(0, 0, 0, 0.24)),
		radial-gradient(
			100% 100% at 0% 0%,
			rgba(255, 255, 255, 0.328) 0%,
			rgba(255, 255, 255, 0) 100%
		);
	box-shadow: inset -5px -5px 250px rgba(255, 255, 255, 0.02);
	backdrop-filter: blur(42px);
	border-radius: 12px;
	z-index: 6;
	max-width: 310px;
	.title {
		display: flex;
		.icon {
			width: 2rem;
			height: 2rem;
			cursor: pointer;
			background: radial-gradient(
				100% 100% at 0% 0%,
				rgba(255, 255, 255, 0.28) 0%,
				rgba(255, 255, 255, 0) 100%
			);
			box-shadow: inset -5px -5px 250px rgba(255, 255, 255, 0.02);
			-webkit-backdrop-filter: blur(20px);
			backdrop-filter: blur(20px);
			border-radius: 50%;
			padding: 0.5rem;
			border: 1px solid rgba(255, 255, 255, 0.34);
			position: relative;
			svg {
				width: 2rem;
				height: 2rem;
				position: relative;
				top: -0.2rem;
				fill: #ffffff;
			}
		}
		span {
			margin-top: auto;
			margin-bottom: auto;
			margin-left: 12px;
			font-weight: bold;
		}
	}
	.content {
		margin-top: 1rem;
		margin-bottom: 1rem;
		li {
			display: flex;
			margin-bottom: 0.5rem;
			margin-left: 0.5rem;
			text-transform: capitalize;
			i.icon {
				margin-right: 0.8rem;
				box-sizing: border-box;
				position: relative;
				display: block;
				transform: scale(var(--ggs, 1));
				width: 18px;
				height: 18px;
				border: 2px solid;
				border-radius: 4px;
				&:before,
				&:after {
					content: "";
					display: block;
					box-sizing: border-box;
					position: absolute;
					width: 12px;
					height: 2px;
					background: currentColor;
					transform: rotate(45deg);
					border-radius: 5px;
					top: 6px;
					left: 1px;
				}
				&:after {
					transform: rotate(-45deg);
				}
			}
		}
	}
	.row {
		display: flex;
		justify-content: space-between;
	}
	button {
		display: flex;
		box-shadow: 0 2px 2px -2px rgba(34, 38, 50, 0.3);
		border-radius: 43px;
		border: none;
		padding: 0.8rem 2.5rem;
		&.grey {
			background: #dde5ff;
			color: #627893;
			&:hover {
				background: #b3bad2;
				color: #475463;
			}
		}
		&.primary {
			margin-left: 5px;
			background: #2e56e4;
			color: #ffffff;
			&:hover {
				background: #2244bb;
			}
		}
	}
}
