.preferences {
	padding: 2rem 2rem 6rem 2rem;
	width: 100%;
	.multiSelectContainer {
		.searchWrapper {
			.chip {
				background-color: #3e4451;
				color: white;
			}
		}
	}


	h3 {
		font-weight: bolder;
		font-size: 1.8rem;
		margin-bottom: 4rem;
		position: relative;
		top: 0.6rem;
		&::after {
			content: " ";
			position: absolute;
			bottom: -1rem;
			left: 0;
			width: 100%;
			height: 0.1rem;
			background-color: grey;
		}
	}
	.input {
		input[type="checkbox"] {
			position: absolute;
			left: -9999rem;
			top: -9999rem;
			&:checked + label > .box svg {
				display: block;
			}
		}
		.checkbox {
			margin-right: unset;
			margin-top: 1rem;
			display: flex;
			grid-gap: 1rem;
			input[type="checkbox"] {
				position: absolute;
				left: -9999rem;
				top: -9999rem;

				&:checked + label {
					position: relative;
					border: $green 1px solid;
					&::after {
						cursor: pointer;
						content: " ";
						width: 1.5rem;
						height: 1.5rem;
						position: absolute;
						right: -0.25rem;
						top: -0.25rem;

						mask: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBkPSJNMjAuMjg1IDJsLTExLjI4NSAxMS41NjctNS4yODYtNS4wMTEtMy43MTQgMy43MTYgOSA4LjcyOCAxNS0xNS4yODV6Ii8+PC9zdmc+");
						mask-size: contain;
						mask-repeat: no-repeat;
						background-color: $white;
					}
					&::before {
						cursor: pointer;
						content: " ";
						width: 2rem;
						height: 2rem;
						right: -0.5rem;
						top: -0.5rem;
						position: absolute;
						border-radius: 50%;
						background-color: $green;
					}
				}
			}
			label {
				cursor: pointer;
				display: flex;
				flex-direction: column;
				padding: 0.5rem;
				position: relative;
				background: radial-gradient(
					100% 100% at 0% 0%,
					rgba(255, 255, 255, 0.28) 0%,
					rgba(255, 255, 255, 0) 100%
				);
				box-shadow: inset -5px -5px 250px rgba(255, 255, 255, 0.02);
				backdrop-filter: blur(20px);
				border: 1px solid grey;

				border-radius: 6px;
				img {
					width: 10rem;
					display: block;
					margin-right: 2rem;
				}
				p {
					display: block;
				}
			}
		}

		label.radio {
			margin-bottom: 1rem;
			display: flex;
			flex-direction: row !important;
			align-items: center;
			max-width: 25rem;
			a {
				color: rgb(121, 178, 252);
			}
			.box {
				.div {
					width: 2rem;
					height: 2rem;
				}

				svg {
					@include center(true, true);
					display: none;
				}
			}
			p {
				width: 100%;
			}
		}
	}
	input[type="submit"] {
		width: 11rem;
		background: transparent;
		padding: 1rem;
		border: 2px solid $white;
		border-radius: 1.5rem;
		color: $white;
		&.success {
			border: 2px solid #21ad56;
		}
		&.error {
			border: 2px solid red;
		}
	}
}
@media (max-width: $mobile) {
	.preferences {
		width: auto;
		.checkbox {
			display: block !important;
		}
	}
}