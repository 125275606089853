.homepage {
	width: 100%;
	> ul {
		width: 100%;
		> li {
			cursor: pointer;
			padding: 2rem 2rem;
			border-bottom: 1px solid grey;
			position: relative;

			> span {
				font-size: 2rem;
				display: block;

				&::after {
					cursor: pointer;
					content: " ";
					width: 2rem;
					height: 2rem;
					position: absolute;
					right: 2rem;
					top: 2.8rem;
					mask: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBkPSJNMCA3LjMzbDIuODI5LTIuODMgOS4xNzUgOS4zMzkgOS4xNjctOS4zMzkgMi44MjkgMi44My0xMS45OTYgMTIuMTd6Ii8+PC9zdmc+");
					mask-size: contain;
					mask-repeat: no-repeat;
					background-color: $white;
					transform: translateY(-50%) rotateZ(0deg);
					transition: all 0.2s;
				}
				&.hover::after {
					transform: translateY(-50%) rotateZ(180deg);
					transition: all 0.2s;
				}
				&.hover + div {
					display: block;
				}
			}

			> div {
				display: none;
			}
		}
	}
	.submit {
		margin: 2rem;
		position: absolute;
		top: -4rem;
		right: 2rem;
		button {
			border: unset;
			font-family: $fontRegular;
			color: $white;
			padding: 1rem;
			border-radius: 1rem;
			background-color: darken($green, 20%);
			transition-duration: 0.2s;
			&:hover {
				background-color: $green;
			}
			&:active {
				transition-duration: 0s;
				transform: scale(1.1);
			}

			display: flex;
			flex-direction: row-reverse;
			align-items: center;
			span {
				width: 100%;
				word-wrap: none;
				white-space: nowrap;
			}
			svg {
				position: relative;
				top: 0.3rem;
				margin-right: 1rem;

				fill: $white;
				animation: rotate 1.5s infinite ease-in-out;
				display: none;
				&.display {
					display: block !important;
				}
			}
		}
	}
	@import "./navigation-bar";
	@import "./slideshow/slideshow";
	@import "./webTVS";
	@import "./playlists";
	@import "./highlights/highlights";
	@import "./status";
}
