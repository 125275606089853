.webTVS {
	width: 100%;
	margin: 3rem auto;
	.titles {
		width: 90%;
		margin: auto;
		display: flex;
		align-items: center;
		justify-content: space-between;
		text-transform: uppercase;
		h3 {
			font-size: 2rem;
		}
		h5 {
			cursor: pointer;
			font-size: 1.2rem;
			svg {
				vertical-align: middle;
				height: 1.1rem;
				fill: $white;
			}
		}
	}

	.webTVS-container {
		margin-top: 2rem;
		position: relative;
		max-width: 95%;
		margin-left: auto;

		&::after {
			content: "";
			display: block;
			background: linear-gradient(
				270.21deg,
				rgb(9, 22, 41) 10%,
				rgba(9, 22, 41, 0) 99.82%
			);
			width: 5%;
			height: 100%;
			position: absolute;
			top: 0;
			right: 0;
			z-index: 5;
		}
		.arrow-slideshow {
			background-color: #36598d;
			border-radius: 2rem;
			padding: 2rem 1.2rem;
			border: none;
			position: absolute;
			transform: translateY(-50%) scale(100%);
			top: calc(50% - 0.5rem);
			left: -5rem;
			z-index: 6;
			transition: all 0.3s linear;
			&:hover {
				transform: translateY(-50%) scale(102%);
				transition: all 0.3s linear;
				background-color: #4168a8;
				svg {
					transition: all 0.3s linear;
					transform: scale(98%);
				}
			}
			svg {
				fill: $white;
				width: 1.5rem;
			}
			&.right {
				left: unset;
				right: 2rem;
			}
			&.disabled {
				svg {
					opacity: 0.3;
				}
			}
		}
		.slick-track {
			margin-left: 0;
		}
		.tv {
			border-radius: 8px;
			text-align: center;
			height: 15rem;
			display: flex;
			align-items: center;
			justify-content: center;
			margin-right: 1rem;
			img {
				width: 82%;
				height: 82%;
				object-fit: contain;
			}

			h4 {
				display: none;
				color: white !important;
			}
		}

		.last-card {
			width: unset!important;
			cursor: pointer;
			border-radius: 8px;
			overflow: hidden;
			position: relative;
			height: 15rem;
			display: flex !important;
			justify-content: center;
			align-items: center;
			flex-direction: column;
			margin-right: 1rem;
			&::before {
				content: "";
				display: block;
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				background: rgba(9, 22, 41, 0.84);
				z-index: 2;
			}
			svg {
				fill: $white;

				z-index: 5;
				width: 5rem;
			}

			h3 {
				font-size: 1.7rem;
				text-align: center;
				position: relative;
				margin-top: 1rem;
				z-index: 5;
			}

			img {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				object-fit: cover;
				z-index: -1;
			}

			.dots {
				img {
					z-index: 2;
				}
			}
		}
	}
}
@media (max-width: $mobile) {
	.webTVS {
		.webTVS-container {
			.arrow-slideshow {
				top: calc(50% - 0.5rem);

				&.left {
					left: 1rem;
					right: unset;
				}
			}
		}
	}
}
