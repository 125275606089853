.delete-buttons {
    display:flex;
    align-self: flex-end;
}
#delete,#delete-avatar {
    display: flex;
    align-items: flex-start;
    padding-top: 4rem;

    button {
        &.cancel {
            border: 2px solid $white;
        }
        vertical-align: middle;
        cursor: pointer;
        background: transparent;
        padding: 1rem;
        border: 2px solid $red;
        border-radius: 1.5rem;
        color: $white;
        margin-right: 1rem;
    }

    p {
        margin: 0;
    }

    .modal-delete {
        background: transparent;
        padding: 2rem;
        border: 2px solid $red;
        border-radius: 1.5rem;
        color: $white;
        width: 100%;

        &.cancel {
            border: 2px solid $white;
        }
        .button-block {
            margin-top: 2rem;
            float: right;
        }
        .errorDisplay {
            position: absolute;
            bottom: 1rem;
            transform: translateY(-50%);
            right: 1rem;
            color: $red;

            font-size: 1.3rem;
            font-weight: bolder;
            text-transform: uppercase;
            animation: wiggleSmall 0.1s linear 3;
        }
    }
}
