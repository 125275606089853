.banner {
	.banner-container {
		position: relative;
		&::before {
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 25%;
			opacity: 1;
			background: linear-gradient(
				180deg,
				rgb(7, 17, 32) 0%,
				rgba(7, 17, 32, 0) 100%
			);
			z-index: 3;
		}

		.item {
			position: relative;
			.title {
				position: absolute;
				bottom: 25%;
				z-index: 5;
				transform: translate(-50%, -50%);
				left: 50%;
				text-align: center;
				width: fit-content;
				&:before,
				&:after {
					content: "";
					background: white;
					height: 2px;
					width: 114px;
					position: absolute;
				}
				&:before {
					top: -15px;
					right: 55px;
				}
				&:after {
					bottom: -15px;
					left: 15px;
				}

				h1.decor {
					backdrop-filter: blur(4px);
					border: 1px solid rgba(255, 255, 255, 0.6);
					display: inline-block;
					padding: 1em 1.2em;
					transform: skewX(195deg);
					span {
						font-weight: 900;
						font-size: 6.2rem;
						text-transform: uppercase;
						transform: skewX(-195deg);
						display: block;
					}
					&:before,
					&:after {
						content: "";
						background: white;
						height: 2px;
						width: 25px;
						position: absolute;
					}
					&:before {
						top: -16px;
						right: 0;
					}
					&:after {
						bottom: -16px;
						left: 125px;
					}
				}
			}
			.dots {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				z-index: 3;
				img {
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					z-index: 3;
				}
			}
			> img {
				width: 100vw;
				object-fit: cover;
				user-select: none;
				height: 40vh;
			}
		}
	}
}
@media (max-width: $mobile) {
	.banner {
		.banner-container {
			.item {
				.title {
					h1 {
						span {
							font-size: 5vh !important;
						}
					}
				}
				> img {
					max-height: 35vh !important;
					height: 100vh;
					object-position: center;
				}
			}
		}
	}
}
