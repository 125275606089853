.slide-editor {
	width: 90%;
	margin: 0 auto;
	flex: 1;
	max-height: 80vh;
	overflow-y: auto;
	position: relative;
	section {
		h2 {
			flex: 100%;
			font-size: 2rem;
			margin-bottom: 1rem;
			text-transform: uppercase;
		}
		border-bottom: 1px solid grey;
		&:last-of-type {
			border-bottom: unset;
		}
		&:not(section:first-of-type) {
			margin-top: 2rem;
		}
		margin-bottom: 2rem;
	}
	.style {
		display: flex;

		flex-wrap: wrap;

		.slide {
			overflow: hidden;
			margin-right: 2rem;
			z-index: 20;
			position: relative;
			width: auto;
			height: auto;
			&.selected,
			&:hover {
				&::before {
					content: " ";
					width: 2rem;
					height: 2rem;
					position: absolute;
					right: 0.5rem;
					top: 0.5rem;
					z-index: 20;
					mask: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBkPSJNMTIgMGMtNi42MjcgMC0xMiA1LjM3My0xMiAxMnM1LjM3MyAxMiAxMiAxMiAxMi01LjM3MyAxMi0xMi01LjM3My0xMi0xMi0xMnptLTEuMjUgMTcuMjkybC00LjUtNC4zNjQgMS44NTctMS44NTggMi42NDMgMi41MDYgNS42NDMtNS43ODQgMS44NTcgMS44NTctNy41IDcuNjQzeiIvPjwvc3ZnPg==");
					mask-size: contain;
					mask-repeat: no-repeat;
					background-color: $green;
				}
			}

			.event {
				width: 20rem;
				height: 13rem;
				aspect-ratio: 16/9;

				.title {
					h1 {
						font-size: 0.7rem;
						padding: 0.2rem 0.3rem;
					}
					h2 {
						font-weight: 400;
						font-size: 0.6rem;
					}

					span.team {
						width: 2.5rem;
						padding: 0.4rem 1.3rem;
						font-size: 0.7rem;
					}
				}
			}
		}
	}

	.teams {
		.teams-inputs {
			display: flex;

			.input {
				input {
					width: 80%;
				}
				label {
					width: 80%;
				}
			}
		}
	}

	.img {
		.input {
			display: flex;
		}
		.image {
			width: 100%;
			img {
				width: 100%;
			}
		}
		input {
			position: absolute;
			top: -9999rem;
			left: -9999rem;
		}
		label {
			margin-top: 1rem;
			display: block;
			cursor: pointer;
			width: 20rem;
			text-align: center;
			margin-right: 1rem;
			background: transparent;
			padding: 1rem;
			border: 2px solid $white;
			border-radius: 1.5rem;
			color: $white;
			min-height: 3rem;
			font-size: 1.5rem;
			line-height: 1.5rem;
		}
		p {
			font-style: italic;
			margin-top: 1em;
		}
	}

	.visibility {
		.icon-switch {
			fill: $white;

			width: 2rem;
			height: 2rem;
			position: relative;
			left: 0.5rem;
			top: 0.4rem;
			&.unchecked {
				fill: $blue;
			}
		}
	}
	.sticky {
		position: sticky;
		top: 0;
		left: 0;
		height: calc(100% - 1px);
		width: calc(100% - 1px);
		float: left;
		margin-right: -100%;

		z-index: 25;
	}
	.submit {
		position: absolute;
		right: 0rem;
		top: 4rem;
		transform: translate(0, -50%);
	}

	.delete {
		position: absolute;
		right: 2rem;
		top: 12rem;
		transform: translate(0, -50%);
		button {
			border: unset;
			font-family: "Fira Sans", sans-serif;
			color: #ffffff;
			padding: 0.5rem;
			border-radius: 1rem;
			background-color: $red;
			transition-duration: 0.2s;
			width: 15rem;

			&:active {
				transform: scale(1.1);
				transition-duration: 0.2s;
			}
		}
	}
}
