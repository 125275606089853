.parameters-container {
	@media (min-width: $mobile + 1px) {
		&::before {
			content: " ";
			display: block;
			position: absolute;
			width: 100vw;
			height: 100vh;
			top: 50%;
			left: 50%;
			z-index: -1;
			transform: translate(-50%, -50%);
			background-color: rgba(7, 17, 32, 0.658);
		}

		position: fixed;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		z-index: 8;
		border-radius: 1.5rem;
		border: 1px solid grey;
		min-height: 80%;
		max-width: 100vw;
	}

	&.display {
		z-index: 99;
		visibility: visible;
		opacity: 1;
		transition: all $fade linear;
	}
	transition: all $fade linear;
	visibility: hidden;
	opacity: 0;

	background: radial-gradient(
		100% 100% at 0% 0%,
		rgba(255, 255, 255, 0.41) 0%,
		rgba(255, 255, 255, 0) 100%
	);
	box-shadow: inset -5px -5px 250px rgba(255, 255, 255, 0.02);
	backdrop-filter: blur(42px);
	display: flex;
	.block {
		padding: 2rem 3rem 2rem 3rem;
		margin: 0 2rem;
		width: 72%;
		border-radius: 8px;
		.title {
			text-transform: capitalize;
			font-weight: bolder;
			border-bottom: 1px solid hsla(0, 0%, 100%, .2);
			padding-bottom: 1.5rem;
			display: flex;
			justify-content: space-between;
			align-items: center;
			h3 {
				font-size: 1.8rem;
			}
			.edit {
				width: 10%;
				height: 5%;
				font-weight: 400;
				cursor: pointer;
				padding: 1rem;
				border-radius: 8px;
				text-align: center;
				border: 1px solid hsla(0, 0%, 100%, .2);
			}
		}

		article {
			padding-top: 2rem;
		}
	}

	nav {
		padding: 2rem 5rem 2rem 2.5rem;
		background: radial-gradient(
			100% 100% at 0% 0%,
			rgba(255, 255, 255, 0) 0%,
			rgba(255, 255, 255, 0.205) 100%
		);
		box-shadow: inset -5px -5px 250px rgba(255, 255, 255, 0.02);
		backdrop-filter: blur(42px);
		&.menu {
			border-radius: 1.5rem 0 0 1.5rem;
		}

		h3 {
			font-weight: bolder;
			font-size: 2.5rem;
			margin-bottom: 4rem;
			position: relative;

			&::after {
				content: " ";
				position: absolute;
				bottom: -1rem;
				left: 0;
				width: 100%;
				height: 0.1rem;
				background-color: grey;
			}
		}

		ul {
			li {
				margin-bottom: 2.8rem;
				cursor: pointer;
				display: flex;
				&.selected {
					position: relative;
					z-index: 10;
					&::before {
						z-index: -2;
						content: " ";
						width: calc(100% + 4rem);
						border-radius: 0 2rem 2rem 0;
						background: linear-gradient(
								0deg,
								rgba(255, 255, 255, 0.1),
								rgba(255, 255, 255, 0.1)
							),
							radial-gradient(
								100% 100% at 0% 0%,
								rgba(255, 255, 255, 0.287) 0%,
								rgba(255, 255, 255, 0) 100%
							);
						box-shadow: inset -5px -5px 250px rgba(255, 255, 255, 0.02);
						backdrop-filter: blur(42px);
						position: absolute;
						left: -2.5rem;
						top: -1rem;
						height: calc(100% + 2rem);
					}
				}
				svg {
					fill: $white;
					vertical-align: middle;
					margin-right: 1.5rem;
				}
				span {
					margin: auto 0;
				}
			}
		}
	}
	.cross {
		position: absolute;
		right: 5px;
		top: 5px;
		cursor: pointer;
		height: 4rem;
		width: 4rem;
		order: 3;
		background: radial-gradient(
			100% 100% at 0% 0%,
			rgba(255, 255, 255, 0.28) 0%,
			rgba(255, 255, 255, 0) 100%
		);
		box-shadow: inset -5px -5px 250px rgba(255, 255, 255, 0.02);
		backdrop-filter: blur(20px);
		border-radius: 50%;
		border: 1px solid grey;
		z-index: 10;
		svg {
			width: 2rem;
			@include center(true, true);
			fill: $white;
		}
	}

	@import "./preferencesParam";
	@import "./securityParam";
	@import "./notifParam";
}

@media (max-width: $mobile) {
	.parameters-container {
		position: fixed !important;
		z-index: 130;
		min-height: 100vh;
		height: 100%;
		flex-direction: column !important;
		width: 100%;
		top: 0;
		bottom: 0;
		overflow-y: auto;
		overflow-x: hidden;
		nav {
			width: 100%;
			padding: 2rem 2rem 2rem 0 !important;
			h3 {
				margin-bottom: 2rem;
				margin-left: 5rem;

				&::after {
					left: -5rem;
					width: calc(100% + 5rem);
				}
			}
			&.menu {
				border-radius: 0;
			}
			ul {
				display: flex;
				justify-content: space-evenly;
				li {
					margin-bottom: -0.5rem !important;
					svg {
						display: none;
					}
					&.selected {
						&::after {
							display: block;
							content: "";
							background-color: $red;
							position: absolute;
							width: 100%;
							height: 0.1rem;
							bottom: -1.55rem;
						}
						&::before {
							display: none;
						}
					}
				}
			}
		}
		.cross {
			position: absolute;
			top: 1.5rem;
			left: 1.5rem;
			height: 2.5rem;
			width: 2.5rem;
			z-index: 8;
			svg {
				width: 1.5rem;
			}
		}
		form {
			width: 100%;
			margin: auto;
			display: block !important;
		}
	}
}
