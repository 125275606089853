.playlists {
	width: 90%;
	margin: 50px auto auto auto;
	.titles {
		display: flex;
		align-items: center;
		justify-content: space-between;
		text-transform: uppercase;
		h3 {
			font-size: 2rem;
		}
		h5 {
			cursor: pointer;
			font-size: 1.2rem;
			svg {
				vertical-align: middle;
				height: 1.1rem;
				fill: $white;
			}
		}
	}
	.playlists-container {
		display: grid;
		margin-top: 2rem;
		grid-template-columns: repeat(8, 9.1%);
		gap: 35px;
		.playlist {
			background: radial-gradient(
				100% 100% at 0% 0%,
				rgba(255, 255, 255, 0.41) 0%,
				rgba(255, 255, 255, 0) 100%
			);
			box-shadow: inset -5px -5px 250px rgba(255, 255, 255, 0.02);
			backdrop-filter: blur(42px);
			border-radius: 50%;
			border: 2px solid rgba(255, 255, 255, 0.6);
			padding: 10px;
			width: 70px;
			height: 70px;
			transition: all 0.1s;
			cursor: pointer;
			h4 {
				text-align: center;
				font-size: 0.8em;
			}

			&:hover {
				background-color: blue;
				transition: all 0.1s;
			}
		}
	}
	.page {
		margin-top: 20px;
		display: flex;
		justify-content: space-evenly;
		span {
			padding: 10px;
		}
		button {
			color: white;
			font-family: $fontRegular;
			background-color: transparent;
			border-radius: 20px;
			border: 1px solid grey;
			padding: 10px;
			cursor: pointer;
			transition: all 0.1s;
			&:hover {
				background-color: blue;
				transition: all 0.1s;
			}
		}
	}
}
