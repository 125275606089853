.main-header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin: 1.5rem 5rem 1.5rem 2rem;
	.mobile-menu {
		display: none;
	}
	.hide-background,
	.hide-background-not {
		&.hover {
			visibility: visible;
			opacity: 1;
			transition: all $fade;
			z-index: 9;
		}
		position: fixed;
		top: 0;
		left: 0;
		width: 100vw;
		height: 100vh;
		background-color: rgba(7, 17, 32, 0.92);
		visibility: hidden;
		opacity: 0;
		transition: all $fade;
	}

	.logo {
		position: relative;
		z-index: 15;
		a {
			color: $white;
			text-decoration: none;
		}
		img {
			width: 20rem;
		}
		h2 {
			padding-top: 1rem;
			border-top: rgba(196, 196, 196, 0.3) solid 1px;
			margin-top: 2rem;
			width: 13rem;
			position: relative;
			top: -1rem;
		}
	}

	.status {
		position: absolute;
		right: -3rem;
		top: -3rem;
		fill: $white;
		transition: all 0.1s;
		cursor: pointer;
		color: $white;
		h4 {
			display: inline-block;
			margin-left: 0.5rem;
		}
		svg {
			vertical-align: middle;
		}
		&:hover {
			fill: lighten($blue, 40%);
			color: lighten($blue, 40%);
			transition: all 0.1s;
		}
		ul {
			color: $white;
			min-width: 25vw;
			&.active {
				display: block;
			}
			display: none;
			position: absolute;
			right: 0;
			background-color: rgba(66, 61, 85, 0.884);
			padding: 1rem;
			border: 1px solid grey;
			border-radius: 0.5rem;
			li {
				margin-bottom: 1.5rem;
				list-style: disc;
				margin-left: 1rem;
				border-bottom: 1px solid grey;
				padding-bottom: 0.5rem;
				em {
					font-style: italic;
				}
				u {
					text-decoration: underline;
				}
				strong {
					font-weight: bolder;
				}
				&:last-of-type {
					padding-bottom: 0;
					border-bottom: 0px;
					margin-bottom: 0.5rem;
					background-color: #d32b2b;
				}
			}
		}
	}

	@media (min-width: $mobile + 1) {
		nav {
			position: relative;
			z-index: 99;
			footer,
			.logo {
				display: none;
			}

			> ul {
				display: flex;
				li.menu {
					display: none;
				}
				> li {
					margin-top: auto;
					margin-bottom: auto;
					margin-right: 40px;
					cursor: pointer;
					font-size: 15px;
					a {
						display: block;
						color: $white;
						text-decoration: none;
						padding: 0.8rem 2.2rem;
					}

					&.sports-container {
						@include icons(
										"data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBkPSJNMCA3LjMzbDIuODI5LTIuODMgOS4xNzUgOS4zMzkgOS4xNjctOS4zMzkgMi44MjkgMi44My0xMS45OTYgMTIuMTd6Ii8+PC9zdmc+"
						);
					}
					a.active, &.active {
						background: radial-gradient(
							100% 100% at 0% 0%,
							rgba(255, 255, 255, 0.4) 0%,
							rgba(255, 255, 255, 0) 100%
						);
						backdrop-filter: blur(20px);
						box-shadow: 0 0 2px 0 rgba(255, 255, 255, 0.34);
						border-radius: 47px;
					}
				}
				.btn-profile {
					margin: 0;
					margin-left: 20px;
				}
			}
		}
		.sports-container {
			padding: 0.8rem 2.2rem;
			position: relative;
			z-index: 99;
			.sports.hover {
				visibility: visible;
				opacity: 1;
				transition: all $fade;
			}

			.sports {
				position: absolute;
				top: 100%;
				left: 50%;
				transform: translateX(-50%);
				visibility: hidden;
				opacity: 0;
				transition: all $fade;

				ul {
					position: relative;
					top: 3rem;
					padding: 1rem 1.5rem;
					display: flex;
					background: radial-gradient(
						107.61% 109.2% at 5.13% -9.22%,
						rgba(255, 255, 255, 0.41) 0%,
						rgba(255, 255, 255, 0) 100%
					);
					box-shadow: inset -5px -5px 250px rgba(255, 255, 255, 0.02);
					backdrop-filter: blur(42px);
					border-radius: 10rem;
					min-height: 6rem;
					align-items: center;
					justify-content: space-around;
					border: 1px solid rgba(255, 255, 255, 0.34);
					li {
						user-select: none;
						a {
							text-align: center;
							line-height: 4rem;
							width: 7.6rem;
							height: 9rem;
							padding: 0 1rem;
							img {
								width: 7rem;
								height: 7rem;
							}
							span {
								font-weight: bolder;
								font-size: 13px;
								line-height: 2rem;
								display: block;
							}
						}
					}

					&::before {
						content: "";
						display: block;
						position: absolute;
						left: 50%;
						top: -1.15rem;

						background: radial-gradient(
							107.61% 109.2% at 5.13% -9.22%,
							rgba(255, 255, 255, 0.41) 0%,
							rgba(255, 255, 255, 0) 100%
						);
						box-shadow: inset -5px -5px 250px rgba(255, 255, 255, 0.02);
						backdrop-filter: blur(42px);
						width: 2rem;
						height: 2rem;
						border: 1px solid rgba(255, 255, 255, 0.34);
						transform: translateX(-50%) rotate(225deg);
						clip-path: polygon(100% 0, 0% 100%, 100% 100%);
					}
				}
			}
		}
	}
	.account-section {
		display: flex;
		z-index: 99;
		> div {
			position: relative;
			margin: auto 1rem;
			&.notifications.hover:before {
				content: "";
				display: block;
				position: absolute;
				left: 50%;
				top: 5rem;
				background: radial-gradient(
					107.61% 109.2% at 5.13% -9.22%,
					rgba(255, 255, 255, 0.41) 0%,
					rgba(255, 255, 255, 0) 100%
				);
				box-shadow: inset -5px -5px 250px rgba(255, 255, 255, 0.02);
				backdrop-filter: blur(42px);
				width: 2rem;
				height: 2rem;
				border: 1px solid rgba(255, 255, 255, 0.34);
				transform: translateX(-50%) rotate(225deg);
				clip-path: polygon(100% 0, 0% 100%, 100% 100%);
			}
			&.search-button,
			&.notifications {
				width: 2rem;
				height: 2rem;
				cursor: pointer;
				background: radial-gradient(
					100% 100% at 0% 0%,
					rgba(255, 255, 255, 0.28) 0%,
					rgba(255, 255, 255, 0) 100%
				);
				box-shadow: inset -5px -5px 250px rgba(255, 255, 255, 0.02);
				backdrop-filter: blur(20px);
				border-radius: 50%;
				padding: 1rem;
				border: 1px solid rgba(255, 255, 255, 0.34);
				position: relative;
			}
			&.languages {
				text-transform: capitalize;
				@include icons(
					"data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBkPSJNMCA3LjMzbDIuODI5LTIuODMgOS4xNzUgOS4zMzkgOS4xNjctOS4zMzkgMi44MjkgMi44My0xMS45OTYgMTIuMTd6Ii8+PC9zdmc+"
				);
			}
			svg {
				width: 2rem;
				position: relative;
				top: -0.2rem;
				fill: $white;
			}

			.notifications-number {
				position: absolute;
				top: 0;
				right: -0.2rem;
				background-color: #d32b2b;
				padding: 0.2rem;
				border-radius: 50%;
				height: 1.2rem;
				width: 1.2rem;

				span {
					font-size: 1.2rem;
					@include center(true, true);
				}
			}

			&.account {
				span {
					margin-left: 1rem;
					cursor: pointer;
				}
				img {
					cursor: pointer;
					width: 4rem;
					border-radius: 50%;
					aspect-ratio: 1/1;
					object-fit: cover;
				}

				.buttons {
					a:not(.not-button) {
						text-decoration: none;

						background-color: transparent;
						font-family: $fontRegular;
						font-weight: 500;
						font-size: 1.2rem;

						color: $white;
						border: 1.5px solid $white;
						padding: 1rem;
						border-radius: 2rem;

						&#register-button {
							color: $blue;
							background-color: $white;
							margin-right: 1.5rem;
						}
					}
				}
				.account-menu {
					@include icons(
						"data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBkPSJNMCA3LjMzbDIuODI5LTIuODMgOS4xNzUgOS4zMzkgOS4xNjctOS4zMzkgMi44MjkgMi44My0xMS45OTYgMTIuMTd6Ii8+PC9zdmc+"
					);
					position: relative;
					.sub-menu {
						position: absolute;
						top: calc(100% + 1rem);
						visibility: hidden;
						opacity: 0;
						transition: all $fade;
						&.hover {
							visibility: visible;
							opacity: 1;
							transition: all $fade;
						}
						ul {
							li:first-of-type {
								display: none;
							}
							position: relative;
							&::before {
								content: "";
								display: block;
								position: absolute;
								left: 50%;
								top: -1.15rem;

								background: radial-gradient(
									107.61% 109.2% at 5.13% -9.22%,
									rgba(255, 255, 255, 0.41) 0%,
									rgba(255, 255, 255, 0) 100%
								);
								box-shadow: inset -5px -5px 250px rgba(255, 255, 255, 0.02);
								backdrop-filter: blur(42px);
								width: 2rem;
								height: 2rem;
								border: 1px solid rgba(255, 255, 255, 0.34);
								transform: translateX(-50%) rotate(225deg);
								clip-path: polygon(100% 0, 0% 100%, 100% 100%);
							}
							width: 100%;
							background: linear-gradient(
									0deg,
									rgba(255, 255, 255, 0.11),
									rgba(255, 255, 255, 0.11)
								),
								radial-gradient(
									100% 95.39% at 0% -0.54%,
									rgba(255, 255, 255, 0.41) 0%,
									rgba(255, 255, 255, 0) 100%
								);
							box-shadow: inset -5px -5px 250px rgba(255, 255, 255, 0.02);
							backdrop-filter: blur(42px);

							border-radius: 8px;
							li {
								display: flex;
								cursor: pointer;
								list-style: none;
								padding: 1rem;
								border-bottom: 1px solid rgb(85, 84, 84);

								&:last-of-type {
									border-bottom: none;
								}
								span {
									position: relative;
									top: 0.8rem;
								}
								svg {
									height: 100%;
									background: linear-gradient(
											0deg,
											rgba(255, 255, 255, 0.07),
											rgba(255, 255, 255, 0.07)
										),
										linear-gradient(
											180deg,
											rgba(255, 255, 255, 0.6) 0%,
											rgba(255, 255, 255, 0) 100%
										);
									box-shadow: inset -5px -5px 250px rgba(255, 255, 255, 0.02);
									backdrop-filter: blur(20px);
									border-radius: 50%;
									padding: 0.5rem;
									border: 1px solid grey;
									vertical-align: middle;
								}
							}
						}
					}
				}
			}

			&.languages {
				position: relative;
				cursor: pointer;
				img {
					width: 1.6rem;
					height: unset;
				}
				ul {
					visibility: hidden;
					opacity: 0;
					transition: all $fade;
					&.hover {
						visibility: visible;
						opacity: 1;
						transition: all $fade;
					}
					background: radial-gradient(
						100% 100% at 0% 0%,
						rgba(255, 255, 255, 0.28) 0%,
						rgba(255, 255, 255, 0) 100%
					);
					box-shadow: inset -5px -5px 250px rgba(255, 255, 255, 0.02);
					backdrop-filter: blur(20px);
					border: 1px solid rgba(255, 255, 255, 0.34);
					border-radius: 1rem;
					position: absolute;
					top: calc(100% + 2rem);
					left: -3rem;
					width: 8.5rem;

					padding: 0 0.5rem;
					li {
						padding: 0.3rem;
						cursor: pointer;
						margin: 1rem auto;
						border-radius: 0.5rem;

						span {
							margin-left: 0.5rem;
						}

						&:hover {
							background: radial-gradient(
								100% 100% at 0% 0%,
								rgba(255, 255, 255, 0.28) 0%,
								rgba(255, 255, 255, 0) 100%
							);
							box-shadow: inset -5px -5px 250px rgba(255, 255, 255, 0.02);
							backdrop-filter: blur(20px);
						}
					}
					&::before {
						content: "";
						display: block;
						position: absolute;
						left: 50%;
						top: -1.15rem;

						background: radial-gradient(
							107.61% 109.2% at 5.13% -9.22%,
							rgba(255, 255, 255, 0.41) 0%,
							rgba(255, 255, 255, 0) 100%
						);
						box-shadow: inset -5px -5px 250px rgba(255, 255, 255, 0.02);
						backdrop-filter: blur(42px);
						width: 2rem;
						height: 2rem;
						border: 1px solid rgba(255, 255, 255, 0.34);
						transform: translateX(-50%) rotate(225deg);
						clip-path: polygon(100% 0, 0% 100%, 100% 100%);
					}
				}
			}
		}
	}
}
.notifications.sub-menu {
	&.hover {
		display: block;
	}
	display: none;
	position: absolute;
	z-index: 9999;
	right: 15rem;
	background: linear-gradient(
			0deg,
			rgba(255, 255, 255, 0.11),
			rgba(255, 255, 255, 0.11)
		),
		radial-gradient(
			100% 95.39% at 0% -0.54%,
			rgba(255, 255, 255, 0.41) 0%,
			rgba(255, 255, 255, 0) 100%
		);
	box-shadow: inset -5px -5px 250px rgba(255, 255, 255, 0.02);
	backdrop-filter: blur(42px);
	border-radius: 8px;
	padding: 20px;
	min-width: 380px;
	max-width: 400px;
	.item-rows {
		overflow-y: auto;
		max-height: 330px;
		padding-top: 5px;
		padding-left: 15px;
		margin-left: -15px;
		padding-right: 15px;
		margin-right: -15px;
	}
	.title {
		justify-content: space-between;
		display: flex;
		padding-bottom: 5px;
		span:hover {
			cursor: pointer;
		}
	}
	.content {
		position: relative;
		.item {
			text-decoration: none;
			color: white;
			display: flex;
			background: rgba(255, 255, 255, 0.2);
			border-radius: 8px;
			cursor: pointer;
			list-style: none;
			padding: 1rem;
			margin: 1rem 0;
			h3 {
				font-style: normal;
				font-weight: bold;
				font-size: 14px;
				line-height: 20px;
				color: #ffffff;
			}
			.image {
				float: left;
				width: 25%;
				display: flex;
				padding: 5px;
				position: relative;
				img {
					margin: auto;
					width: 100%;
				}
				span {
					position: absolute;
					bottom: 10px;
					padding: 4px;
					font-size: 10px;
					left: 0;
					right: 0;
					text-align: center;
					border-radius: 10px;
					&.live {
						background-color: red;
						border: 0.5px solid red;
					}
					&.off {
						background: grey;
						border: 0.5px solid white;
					}
				}
			}
			.message {
				padding-left: 5px;
				float: left;
				width: 75%;
				.message-text {
					padding: 5px 0;
					font-size: 13px;
					line-height: 19px;
					letter-spacing: -0.3px;
				}
				.date {
					font-size: 14px;
					line-height: 22px;
					letter-spacing: -0.408px;
					opacity: 0.7;
				}
			}
		}
		&.new {
			&:before {
				content: "";
				display: block;
				position: absolute;
				border-radius: 1rem;
				width: 1.5rem;
				height: 1.5rem;
				background: red;
				right: -5px;
				top: -5px;
			}
		}
	}
}
@media (max-width: $mobile) {
	.main-header {
		margin: 1.5rem 2rem 1.5rem;
		.logo {
			flex: 2;
		}
		#register-button,
		#login-button {
			display: none;
		}
		.mobile-menu {
			display: block;
			cursor: pointer;
			margin-left: 0.5rem;
			width: 2rem;
			height: 2rem;
			background: radial-gradient(
				100% 100% at 0% 0%,
				rgba(255, 255, 255, 0.28) 0%,
				rgba(255, 255, 255, 0) 100%
			);
			box-shadow: inset -5px -5px 250px rgba(255, 255, 255, 0.02);
			backdrop-filter: blur(20px);
			z-index: 9;
			border-radius: 50%;
			padding: 1rem;
			border: 1px solid rgba(255, 255, 255, 0.34);
			svg {
				width: 2rem;
				position: relative;
				top: -0.2rem;
				fill: $white;
			}
		}

		.account-section {
			.languages {
				display: none;
			}
			.notifications {
				display: none;
			}
			.search-button {
				margin-right: 0;
			}
			.account {
				&::after {
					display: none !important;
				}
				.account-menu {
					&::after {
						display: none !important;
					}
				}
				span {
					display: none;
				}

				.sub-menu {
					position: fixed !important;
					left: 0 !important;
					transform: translate(0, 0);
					&.hover {
						transform: translate(0, -100%);
					}
					ul {
						width: 100vw !important;
						min-height: 35vh;
						&::before {
							display: none !important;
						}
						li:first-of-type {
							display: block !important;
							position: relative;
							.notifications {
								display: inline-block;

								.notifications-number {
									left: 15rem !important	;
									top: 50%;
									span {
										top: 0.45rem !important;
										left: -0.4rem;
									}
								}
							}
							span {
								position: relative;
								top: 0rem !important;
								left: -0.5rem;
							}
						}
						li {
							padding: 2rem 2rem 0rem !important;
							border-bottom: unset !important;
							span {
								display: inline-block;
							}
						}
					}
				}
			}
		}
		nav {
			display: none;
		}
		&.menu-open {
			isolation: isolate;
			z-index: 15;
			position: relative;

			.mobile-close {
				display: block;
				position: absolute;
				top: 0.8rem;
				right: 13rem;
				margin: 1rem;
				cursor: pointer;
				height: 3rem;
				width: 3rem;

				svg {
					width: 2.5rem;
					@include center(true, true);
					fill: $white;
				}
			}
			nav {
				overflow-x: auto;
				display: block;
				z-index: 4;
				box-sizing: border-box;
				background: rgb(7, 17, 32);
				width: 100vw;
				position: fixed;
				left: 0;
				top: 0;
				height: 100vh;

				> ul {
					width: 100%;
					margin-top: 8rem;
					.btn-profile {
						a {
							background-color: transparent;
							padding: 0;
						}	
					}
					> li {
						cursor: pointer;
						padding: 2rem 3rem;
						border-bottom: 1px solid grey;
						font-size: 2rem;

						> a {
							text-decoration: none;
							color: $white;
						}
						.country,
						.sports {
							position: relative;
							&::after {
								cursor: pointer;
								content: " ";
								width: 2rem;
								height: 2rem;
								position: absolute;
								right: 4rem;
								top: -2rem;
								mask: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBkPSJNMCA3LjMzbDIuODI5LTIuODMgOS4xNzUgOS4zMzkgOS4xNjctOS4zMzkgMi44MjkgMi44My0xMS45OTYgMTIuMTd6Ii8+PC9zdmc+");
								mask-size: contain;
								mask-repeat: no-repeat;
								background-color: $white;
								transform: rotateZ(0deg);
								transition: all 0.2s;
							}
							ul {
								display: none;
							}

							&.hover {
								position: relative;

								&::after {
									transform: rotateZ(180deg);
								}
								ul {
									margin-top: 1rem;
									display: flex;
									align-items: center;
									justify-content: flex-start;

									li {
										margin-right: 2rem;

										a {
											text-decoration: none;
											color: $white;
											display: flex;
											flex-direction: column;
											justify-content: center;
											align-items: center;
											img {
												width: 10rem;
												height: 10rem;
											}
										}
									}
								}
							}
						}
					}
				}
				.logo {
					display: block;
					position: absolute;
					top: 1rem;
					left: 2rem;
				}
				footer {
					grid-template-columns: 1fr 1fr !important;
					grid-template-rows: 1fr 1fr !important;
					.login-section {
						grid-row: 2/3;
						> a {
							display: none;
						}
					}
					.contact {
						display: none;
					}
				}
			}
		}
	}
	.notifications.sub-menu {
		position: fixed !important;
		height: 100%;
		overflow-y: auto;
		overflow-x: hidden;
		max-width: 100%;
		right: 0;
		top: 0;
		.item-rows {
			max-height: 100%;
		}
		.title {
			position: relative;
			margin-top: -20px;
			margin-left: -20px;
			margin-right: -20px;
			padding: 20px;
			padding-left: 40px;
			font-size: 24px;
			font-weight: 500;
			background: radial-gradient(
				100% 100% at 0% 0%,
				rgba(255, 255, 255, 0) 0%,
				rgba(255, 255, 255, 0.205) 100%
			);
			box-shadow: inset -5px -5px 250px rgba(255, 255, 255, 0.02);
			&::before {
				border-color: #ffffff;
				border-style: solid;
				border-width: 0 0 3px 3px;
				content: "";
				display: block;
				height: 10px;
				left: 20px;
				position: absolute;
				width: 10px;
				margin-top: -3px;
				top: 44%;
				transition: left 0.1s;
				transform: rotate(45deg);
			}
		}
	}

	.status {
		right: -2rem !important;
		top: -2rem !important;
	}
}
