.security {
	padding: 2rem 2rem 6rem 2rem;
	width: 100%;
	h3 {
		font-weight: bolder;
		font-size: 1.8rem;
		margin-bottom: 4rem;
		position: relative;
		top: 0.6rem;
		&::after {
			content: " ";
			position: absolute;
			bottom: -1rem;
			left: 0;
			width: 100%;
			height: 0.1rem;
			background-color: grey;
		}
	}
	form {
		margin-bottom: 3rem;


		.error {
			grid-column: 1/3;
			margin-bottom: 2rem;
			.validator {
				margin-top: 2rem;
				display: flex;
				div {
					&:first-of-type {
						svg {
							margin-left: 0;
						}
					}
					svg {
						vertical-align: middle;
						fill: $red;
						width: 1.2rem;
						margin: 0 1rem 0 3rem;

						&.validate {
							fill: $green;
						}
					}
				}
			}
		}
		input[type="submit"] {
			width: 11rem;
			background: transparent;
			padding: 1rem;
			border: 2px solid $white;
			border-radius: 1.5rem;
			color: $white;
		}
	}
}
@media (max-width: $mobile) {
	.security {
		width: auto;
	}
}