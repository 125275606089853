.bo-admin {
	padding-left: 2rem;

	h2 {
		font-size: 2rem;
		font-weight: bolder;
		text-transform: uppercase;
		margin-bottom: 2rem;
	}
	form {
		margin-bottom: 3rem;
		width: 40%;
		.input {
			margin-bottom: 1.5rem;
			position: relative;

			label:not(.radio) {
				display: block;
				width: 100%;

				min-width: 25rem;
				height: 0.7rem;
				padding: 0.8rem 1rem;
				background-color: rgba(202, 211, 255, 0.0676517);
				border-radius: 0.3rem 0.3rem 0 0;
				font-size: 1.1rem;
			}

			input {
				display: block;
				width: 100%;

				min-width: 25rem;
				height: 0.4rem;

				position: relative;
				font-family: $fontRegular;
				padding: 0.8rem 1rem 1.2rem;
				background-color: rgba(202, 211, 255, 0.0676517);
				border: none;
				border-bottom: 2px solid $white;
				color: grey;
				border-radius: 0 0 0.3rem 0.3rem;
				&::placeholder {
					color: rgba(255, 255, 255, 0.6);
				}
			}

			&.select {
				label,
				input {
					color: $white !important;
					background-color: rgba(202, 211, 255, 0.24) !important;
				}
			}

			&.error::before {
				content: " ";
				display: block;
				position: absolute;
				left: -1.5rem;
				height: 100%;
				width: 0.3rem;
				border-radius: 0.2rem;
				background-color: $red;

				animation: wiggle 0.1s linear 4;
			}
		}
		.error {
			grid-column: 1/3;
			margin-bottom: 2rem;
			.validator {
				margin-top: 2rem;
				display: flex;
				div {
					&:first-of-type {
						svg {
							margin-left: 0;
						}
					}
					svg {
						vertical-align: middle;
						fill: $red;
						width: 1.2rem;
						margin: 0 1rem 0 3rem;

						&.validate {
							fill: $green;
						}
					}
				}
			}
		}
		input[type="submit"] {
			width: 11rem;
			background: transparent;
			padding: 1rem;
			border: 2px solid $white;
			border-radius: 1.5rem;
			color: $white;
		}
	}
}
