.login {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-evenly;
	width: 100%;
	height: 100vh;

	.return {
		flex: 1;

		display: flex;
		flex-direction: column-reverse;
		justify-content: space-evenly;
		align-items: center;
		width: 100%;
		height: 100%;
		img {
			width: 40%;
			height: 40%;
			min-width: 10rem;
			object-fit: contain;
		}
	}
	h2 {
		flex: 0.5;
		height: 4rem;
		font-size: 3rem;
		font-weight: bolder;
		margin-bottom: 2rem;
	}

	form {
		flex: 2;

		#submit {
			background-color: #2e56e4;
			border-radius: 4rem;
			padding: 2rem;
			width: 100%;
			color: $white;
			border: none;
		}
	}
}
