.bo-edit_account {
    margin-top: 2rem;

    .edit_block {
        > nav {
            margin-top: 1rem;

            ul {
                width: 80%;
                display: flex;
                justify-content: center;
                margin: auto auto 5rem;

                li {
                    border: 1px $white solid;
                    border-radius: 2rem;
                    padding: 1rem;
                    margin-right: 3rem;
                    transition: 0.2s all;

                    &:hover {
                        background-color: lighten($blue, 40%);
                        border-color: transparent;
                        transition: 0.2s all;
                    }

                    &.selected {
                        background-color: lighten($blue, 40%);
                        border-color: transparent;
                    }
                }
            }
        }

        .grid {
            display: grid;
            grid-gap: 2rem;
            grid-template-columns: .7fr 1fr;

            .element {
                margin-top: 1rem;
                background-color: #fff;
                color: #667085;
            }

            .plans {
                display: flex;
                height: 15rem;
                justify-content: space-between;
            }

            .checkbox, .content {
                margin: auto 1.5rem;

                h2 {
                    font-weight: 600;
                    font-size: 1.8rem;
                    margin-bottom: 1rem;
                    text-transform: none;
                    border: none;
                    padding: 0;
                }
            }

            .price {
                display: grid;
                margin: auto 1.5rem;

                .month {
                    color: rgba(0, 0, 0, 0.51);
                    font-weight: bolder;
                    font-size: 1.8rem;
                }

                .year, .week {
                    color: #c3c3c3;
                }
            }

            .active-plan {
                display: flex;
                height: 12rem;
                flex-direction: column;
                justify-content: space-around;
            }
        }
    }

    .add_stripe {
        display: block;
        position: relative;
        padding: 0.75rem 1.25rem;
        margin-bottom: 1rem;
        border: 1px solid transparent;
        border-radius: 0.35rem;
        color: #806520;
        background-color: #fdf3d8;

        strong {
            font-weight: 700;
        }

        button {
            display: flex;
        }
    }
}
