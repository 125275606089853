.bo-highlights {
	> nav {
		margin-top: 1rem;
		ul {
			width: 80%;
			margin: auto;
			display: flex;
			justify-content: center;

			li {
				border: 1px $white solid;
				border-radius: 2rem;
				padding: 1rem;
				margin-right: 3rem;
				transition: 0.2s all;
				&:hover {
					background-color: lighten($blue, 40%);
					border-color: transparent;
					transition: 0.2s all;
				}
				&.selected {
					background-color: lighten($blue, 40%);
					border-color: transparent;
				}
			}
		}
	}
	.bo-highlight-element {
		margin-bottom: 1rem;
	}
	.bo-highlight-component {
		@import "./handleHighlights";
		@import "./editHighlight";
		@import "./highlightEditor";
	}
}
