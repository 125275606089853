.bo-handle-highlights {
	display: flex;
	margin-top: 2rem;
	justify-content: center;
	position: relative;
	.slideshow,
	.slides {
		margin-right: 2rem;
		display: flex;
		flex-direction: column;
		position: relative;
		align-items: center;
		background-color: rgba(129, 141, 207, 0.274);
		border-radius: 2rem;
		padding: 2rem;

		h3 {
			font-size: 1.8rem;
			text-transform: uppercase;
			font-weight: bold;
			margin-bottom: 1rem;
		}
	}
	.bo-highlight-element.number {
		position: relative;
		&::after {
			content: attr(data-number);
			position: absolute;
			display: block;
			top: 0.5rem;
			right: 0.5rem;
			z-index: 550;
			width: 0.75rem;
			height: 0.75rem;
			color: $white;
			font-size: 1.2rem;
			line-height: 0.8rem;
			background-color: lighten($blue, 30%);
			padding: 1rem;
			border-radius: 50%;
		}
	}
}
