footer {
	clear: both;
	display: grid;
	grid-template-columns: 40% 1fr 1fr 1fr;
	padding: 6rem;
	margin-top: 2rem;
	background: radial-gradient(
		100% 100% at 0% 0%,
		rgba(255, 255, 255, 0.123) 0%,
		rgba(255, 255, 255, 0) 100%
	);
	box-shadow: inset -5px -5px 250px rgba(255, 255, 255, 0.02);
	backdrop-filter: blur(42px);
	z-index: 1;
	position: relative;

	.login-section {
		.login {
			margin-top: 3rem;
			ul {
				display: flex;

				li {
					a {
						color: $white;
						text-decoration: none;
					}

					cursor: pointer;
					width: 10rem;
					height: 3rem;
					border-radius: 6rem;
					text-align: center;
					line-height: 3rem;
					margin-right: 1rem;
					border: 1px solid #ffffff;
					&:first-of-type {
						background-color: $white;
						a {
							color: $background;
						}
					}
				}
			}
		}
		.copyrights {
			margin-top: 3rem;
			font-size: 1rem;
			span {
				display: block;
				margin-bottom: 0.3rem;
				a {
					color: $white;
					text-decoration: none;
				}
			}
		}
	}
	section {
		h4 {
			font-weight: bold;
			margin-bottom: 2rem;
		}
		ul {
			li {
				margin-bottom: 1.2rem;
			}
		}
		address {
			margin-bottom: 1.2rem;
			span {
				display: block;
				margin-bottom: 0.5rem;
			}
		}
		a,
		#contact-button {
			cursor: pointer;
			font-weight: 300;
			color: $white;
			text-decoration: none;
			font-size: 1.2rem;

			&[href^="mailto"] {
				display: block;
				margin-top: 3rem;
			}
		}
	}
}
@media (max-width: $mobile) {
	footer {
		grid-template-columns: 1fr 1fr !important;
		grid-template-rows: 1fr 1fr 1fr;
		text-align: center;
		> div,
		> section {
			border-bottom: 1px solid grey;
			margin-bottom: 2rem;
			padding-bottom: 1rem;
		}

		.login-section {
			grid-row: 3/4;
			grid-column: 1/3;
			border-bottom: unset;
			ul {
				justify-content: center;
			}
		}
		.contact {
			grid-column: 1/3;
		}
	}
}
