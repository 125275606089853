.profile {
    background-color: #192330;
    margin-bottom: -2rem;
    padding-bottom: 6rem;
    position: relative;

    .mobile-menu {
        display: none;
    }

    .checkbox {
        .d-block {
            div {
                display: block !important;
            }
        }

        label {
            padding: 0;
        }
    }

    .item>img {
        height: 25rem !important;
    }

    .header-data {
        position: absolute;
        z-index: 5;
        bottom: 4rem;
        left: 50%;
        transform: translate(-50%, 0);
        width: 90%;
        height: 17rem;
        background: rgba(0, 0, 0, 0.5);
        box-shadow: inset -5px -5px 250px rgba(255, 255, 255, 0.02);
        border-radius: 24px;
        border: 1px solid #FFFFFF;
        display: flex;
        justify-content: space-between;

        .name {
            font-size: 3rem;
        }

        .year {
            font-size: 2rem;
        }

        .logo-container {
            margin: auto 2rem auto 2rem;
            position: relative;
            overflow: hidden;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 10rem;

            img {
                border-radius: 50%;
                width: 12rem;
                height: 12rem;
                object-fit: contain;
            }

        }

        .club-container {
            margin: auto 4rem auto auto;
            ;
            text-align: center;

            img {
                width: 9rem;
                height: 9rem;
                object-fit: contain;
            }

            .club-name {
                font-size: 1.5rem;
            }
        }
    }

    .submenu {
        background-color: #091629;
        padding: 2rem 5% 2rem 5%;

        span {
            border-radius: 24px;
            padding: 0.5rem 1rem;
        }

        ul {
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-wrap: wrap;
            font-style: normal;
            padding: 20px 0;
            font-size: 20px;
            font-weight: 400;
            list-style: none;

            li {
                cursor: pointer;

                .nav-link {
                    color: #98A2B3 !important;

                    &.active {
                        color: #FFFFFF !important;
                    }
                }

                &.counter-complete {
                    font-size: 16px;
                    background-color: #FDB022;
                    padding: 1rem;
                    border-radius: 2rem;
                }
            }
        }
    }

    .groups {
        padding: 6rem;
        display: grid;
        grid-gap: 2rem;
        grid-template-columns: 1fr 1fr 1fr 1fr;

        &.single {
            grid-template-columns: 1fr 1fr 1.5fr 1fr 1fr;
            grid-template-areas:
                ". . a a . .";

            .group {
                grid-area: a;
            }

            .club {
                cursor: pointer;

                &:hover {
                    color: #98A2B3;
                }
            }
        }

        .group {
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            button {
                background-color: transparent;
                border: 1px solid #EF2F2A;
                border-radius: 50px;
                width: 100%;

                &:hover,
                &:focus {
                    background-color: #EF2F2A;
                }
            }

            .clubs {
                h2 {
                    font-size: 20px;
                    padding-bottom: 2rem;
                }

                .club {
                    margin-bottom: 16px;

                    img {
                        margin-right: 1.5rem;
                        width: 1.8rem;
                        height: auto;
                    }

                    .name {
                        display: inline;
                        font-size: 16px;
                        line-height: 24px;
                    }
                }
            }

        }
    }

    article {
        padding: 2rem 5%;

        .ppv {
            width: 20vw;
            background: #f9fafb;
            border-radius: 5px;
            z-index: 999;

            h3 {
                font-size: 20px;
                color: #000000;
            }

            .voucher {
                margin: 1.5rem 2rem;

                label {
                    font-size: 16px;
                    color: #000000;
                    left: -1rem;
                    padding: 1rem 0;
                }

                .input {
                    display: flex;
                    margin-bottom: 0.5rem;
                }
            }

            .content-block {
                .buttons {
                    button {
                        margin-right: 1rem;
                        padding: 10px 15px;
                        background-color: #06132D;
                        border-radius: 50px;
                    }

                    .selected {
                        background: #182d5b;
                    }
                }

                .StripeElement {
                    background-color: #182d5b;
                }
            }

            .btn-stripe {
                background-color: #06132D;
                border-radius: 20px;
                padding: 10px 20px;
            }
        }

        .info-page,
        .orders-page,
        .shop-page,
        .videos-page,
        .plans-page {
            h3 {
                font-size: 24px;
                line-height: 28.8px;
                margin: 30px 0;
            }

            .info-block {
                padding: 2.5rem 4.5rem;
                background-color: #FEF0C7;
                color: #091629;
                line-height: 2rem;
                display: flex;
                font-size: 1.8rem;
                font-weight: 300;
            }

            .nav-filter {
                .filter-tabs {
                    display: flex;

                    .filter-item {
                        cursor: pointer;
                        margin: 0 24px;
                        color: #98A2B3;
                        line-height: 19px;
                    }

                    .active {
                        color: #FFFFFF;
                    }

                }
            }

            .shop-items,
            .videos,
            .plans-items {

                display: grid;
                grid-template-columns: repeat(4, 1fr);
                grid-gap: 2rem;
                margin-top: 24px;

                .item {
                    display: flex;
                    flex-direction: column;
                    background-color: #091629;

                    .content {
                        height: 100%;
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;
                        padding: 18px 16px;

                        h4 {
                            font-size: 16px;
                            line-height: 19px;
                        }

                        h2 {
                            margin-top: 16px;
                            font-weight: 500;
                            font-size: 18px;
                            line-height: 22px;
                            text-transform: uppercase;
                        }

                        p {
                            margin-top: 16px;
                            font-size: 16px;
                            line-height: 19px;
                            display: flex;
                            align-items: center;
                            white-space: initial;
                        }

                        img {
                            width: 100%;
                            height: auto;
                        }

                        .being-created {
                            position: relative;
                            width: 100%;
                            height: 284px;
                            display: flex;
                            flex-direction: column;
                            justify-content: flex-end;

                            h3 {
                                font-size: 18px;
                                line-height: 22px;
                                margin-top: 10px;
                                margin-bottom: 5px;
                                color: #98A2B3;
                            }

                            .loader-dot {
                                position: absolute;
                                top: 20%;
                                left: 40%;
                                transform: translate(-50%, -50%);
                                animation: dot 3s linear infinite;
                                width: 85px;
                                height: 80px;

                                svg {
                                    width: 100%;
                                    height: 100%;
                                }
                            }

                            @keyframes dot {
                                0% {
                                    transform: rotate(0deg);
                                }

                                100% {
                                    transform: rotate(360deg);
                                }
                            }

                            .loader {
                                background-color: #E0E0E033;
                                position: relative;
                                top: 0;
                                left: 0;
                                width: 100%;
                                height: 100%;
                                opacity: 0.5;
                                z-index: 999;
                            }
                        }

                        .buy {
                            margin-top: 16px;
                            display: flex;
                            justify-content: space-between;

                            button {
                                padding: 0;
                                background-color: transparent;
                                color: #0068FF;
                                font-size: 18px;
                                line-height: 22px;
                            }

                            .price {
                                font-size: 18px;
                            }
                        }

                        .buttons {
                            margin-top: 20px;
                            display: flex;
                            justify-content: space-between;

                            button {
                                border: 1px solid #FFFFFF;
                                border-radius: 20px;
                                padding: 10px;
                                font-size: 12px;
                                background-color: transparent;
                            }
                        }
                    }

                }
            }

            .billing,
            .orders {
                display: grid;
                grid-gap: 2rem;
                grid-template-columns: repeat(3, 1fr);

                .absolute-center {

                    label,
                    input {
                        background-color: #242f3e !important;
                        border: unset;
                    }

                    input {
                        border-radius: 0 0 3px 3px;
                    }

                    .d-grid {
                        gap: 2rem;
                        grid-template-columns: repeat(2, 1fr);
                    }
                }

                .invoice {
                    background-color: #091629;
                    border: none;
                    border-radius: 10px;
                    display: block;

                    .content {
                        padding: 30px;

                        .title {
                            font-size: 18px;
                            font-weight: 500;
                            line-height: 21.6px;
                        }

                        .descr {
                            font-size: 14px;
                            line-height: 16.8px;
                            padding: 8px 0;
                            color: rgba(255, 255, 255, 0.6);
                        }

                        .info {
                            display: flex;
                            justify-content: space-between;
                            color: #E4E7EC;
                            line-height: 19.2px;
                        }

                        button {
                            background-color: transparent;
                            border: 1px solid #FFFFFF;
                            border-radius: 20px;
                        }
                    }
                }

                .container {
                    width: 90%;
                    margin: 0;

                    h3 {
                        color: #98A2B3;
                        font-size: 20px;
                        line-height: 38px;
                    }

                    .number {
                        font-weight: 500;
                        line-height: 24px;
                        font-size: 15px;
                    }

                    .image {
                        width: 7rem;
                    }

                    .expire {
                        font-size: 13px;
                        color: #98A2B3;
                        margin-bottom: 24px;
                        padding-top: 0.5rem;
                    }

                    .edit {
                        cursor: pointer;
                    }

                    .no-bill {
                        margin-left: 1rem;
                        vertical-align: middle;
                    }

                    img {
                        vertical-align: middle;
                    }

                    .method {
                        background-color: #091629;
                        border: none;
                        border-radius: 10px;
                        display: flex;
                        justify-content: space-between;
                        color: #FFFFFF;
                    }
                }
            }
        }

        .player-page {
            .title {
                display: flex;
                align-items: center;
                margin-bottom: 45px;

                svg {
                    margin-right: 20px;
                    cursor: pointer;
                }

                p {
                    font-size: 24px;
                    line-height: 29px;
                }
            }

            .d-grid {
                grid-template-columns: 1fr 1fr;
                gap: 3rem;

                .video-iframe {
                    width: 100%;
                }
            }

            .share {
                h2 {
                    font-size: 24px;
                    line-height: 29px;
                }

                .invitations {
                    h3 {
                        margin-bottom: 18px;
                        line-height: 19px;
                        color: #98A2B3;
                    }

                    .items {
                        display: grid;
                        grid-template-columns: auto auto auto;
                        grid-gap: 2rem;

                        .tag-item {
                            background: #0D1D34;
                            border-radius: 16px;
                            height: 19px;
                            width: 142px;
                            padding: 10px;
                            display: flex;
                            align-items: center;

                            svg {
                                margin-right: 0;
                                margin-left: auto;
                            }
                        }
                    }
                }

                .link,
                .email {
                    margin-top: 24px;

                    h3 {
                        margin-bottom: 18px;
                        line-height: 19px;
                        color: #98A2B3;
                    }

                    input {
                        width: calc(100% - 180px);
                        background: #D0D5DD;
                        border-radius: 8px 0 0 8px;
                        padding: 10px;
                        line-height: 19.2px;
                        border: 0;
                    }

                    button {
                        width: 160px;
                        background: #0D1D34;
                        padding: 10px;
                        color: #FFFFFF;
                        border: none;
                        line-height: 19px;
                    }
                }
            }

            .video-data {
                border-bottom: none;

                .first-row {
                    margin-bottom: 1rem;

                    .download {
                        cursor: pointer;
                        position: relative;

                        .download-button {
                            display: block;
                            height: 20px;
                            border-radius: 102px;
                            border: 1px solid grey;
                            padding: 1rem 2rem;

                            svg {
                                margin-right: 0.5rem;
                                vertical-align: middle;
                            }

                            p {
                                font-size: 1.7rem;
                                font-weight: 400;
                                vertical-align: middle;
                            }
                        }
                    }
                }
            }
        }

        h2 {
            font-size: 2.4rem;
        }

        .image {
            align-items: center;
            font-size: 1.4rem;

            .btn {
                width: fit-content;
            }

            p {
                max-width: 27rem;
            }
        }

        .modal {
            h3 {
                font-size: 1.8rem;
                color: #000000;
                font-weight: normal;
            }
        }

        .StripeElement {
            display: block;
            margin: 10px 0 5px 0;
            max-width: 500px;
            min-width: 20rem;
            padding: 10px 14px;
            border-radius: 4px;
            background: hsla(0, 0%, 100%, .12);
            border: 1px solid #c3c2c2;
        }

        .d-grid.plans {
            grid-gap: 2rem;
            grid-template-columns: repeat(3, 1fr);

            .element {
                margin-top: 1rem;
                background-color: #06132D;
                color: #667085;
                border-radius: 6px;
                border: 1px solid white;

                &.plan {
                    color: #FFFFFF;
                    display: grid;
                    grid-template-rows: auto 1fr;
                    padding: 2rem;

                    .advantages {
                        border-top: 1px solid #FFFFFF;

                        h3 {
                            line-height: 24px;
                        }

                        p {
                            display: block;
                            margin-bottom: 2rem;

                            img {
                                margin-right: 1.2rem;
                            }
                        }
                    }

                    button {
                        width: 90%;
                        margin: 2.5rem auto;
                    }

                    .price {
                        display: grid;
                        margin: auto 1.5rem;


                        .year,
                        .week,
                        .month,
                        .season {
                            color: #D0D5DD;
                            font-weight: 700;
                            font-size: 28px;
                            line-height: 36px;
                        }
                    }
                }
            }
        }
    }

    .row-inputs {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-gap: 3rem;

        .career {
            grid-column: 1/4;

            .inputs-block {
                display: grid;
                grid-template-columns: repeat(7, 1fr);
                background-color: rgba(202, 211, 255, 0.0676517);

                .input {
                    margin-bottom: 0 !important;

                    input {
                        min-width: unset;
                        border-bottom: unset;
                        background-color: unset;
                    }
                }
            }
        }

        .achievement,
        .national-achievement,
        .diplomas {
            grid-column: 1/3;

            .inputs-block {
                display: grid;
                grid-template-columns: repeat(3, 1fr);
                background-color: rgba(202, 211, 255, 0.0676517);

                .input {
                    margin-bottom: 0 !important;

                    input {
                        min-width: unset;
                        border-bottom: unset;
                        background-color: unset;
                    }
                }
            }
        }

        .national-achievement {
            grid-column: 3/5;
        }

        .diplomas {
            grid-column: 1/3;

            .inputs-block {
                display: grid;
                grid-template-columns: repeat(5, 1fr);
            }
        }
    }

    .submit {
        display: flex;
    }

    .method {
        color: #000000;
        margin-top: 1rem;
        width: 90%;
        border: 1px solid #182d5b;
        border-radius: 5px;
        display: flex;
        justify-content: space-between;

        &.selected {
            border-color: #EF2F2A;
        }
    }

    @import "../components/account/avatar";
    @import "../components/account/delete";

    @media (max-width: $mobile) {
        .avatar {
            flex-wrap: nowrap;
        }

        .edit-info {
            .d-grid {
                display: block;
            }
        }

        .plans {
            display: block;
        }

        .billing {
            display: flex !important;
            flex-flow: column;
        }

        .ppv {
            width: 80vw !important;
        }

        .modal {
            max-width: 100%;

            .float-right {
                flex-wrap: unset;
            }
        }

        .orders-page {
            .orders {
                display: flex !important;
                flex-direction: column;
            }
        }

        .shop-page {
            .shop-items {
                display: block !important;

                .item {
                    margin-bottom: 2.5rem;
                }
            }
        }

        .plans-page {
            .plans-items {
                display: flex !important;
                flex-direction: column;

                .item {
                    margin-bottom: 2.5rem;
                }
            }
        }

        .athlete-page {
            .submenu {
                display: flex;
                justify-content: space-between;
                align-items: center;
                span {
                    padding: 1rem;
                }
                .nav-tabs {
                    grid-area: nav-tabs;
                    display: none;
                }
                &.menu-open {
				    box-sizing: border-box;
				    background: rgb(7, 17, 32);
				    width: 100vw;
				    left: 0;
				    top: 0;
                    display: grid;
                    grid-template-areas:
                        "counter-complete . mobile-menu"
                        "nav-tabs nav-tabs nav-tabs";
                    > ul {
                        display: flex;
                        flex-direction: column;
                        width: 100%;
                        > li {
                            cursor: pointer;
                            padding: 2rem 3rem;
                            border-bottom: 1px solid grey;
                            font-size: 2rem;
                            width: calc(100% - 6rem);
                        }
                    }
                }
            }
            .mobile-menu {
                display: flex;
                justify-content: flex-end;
                cursor: pointer;
                margin-left: 0.5rem;
                width: 2rem;
                height: 2rem;
                grid-area: mobile-menu;
                background: radial-gradient(
                    100% 100% at 0% 0%,
                    rgba(255, 255, 255, 0.28) 0%,
                    rgba(255, 255, 255, 0) 100%
                );
                box-shadow: inset -5px -5px 250px rgba(255, 255, 255, 0.02);
                backdrop-filter: blur(20px);
                z-index: 9;
                border-radius: 50%;
                padding: 1rem;
                border: 1px solid rgba(255, 255, 255, 0.34);
                svg {
                    width: 2rem;
                    position: relative;
                    top: -0.2rem;
                    fill: $white;
                }
            }
    
            .d-flex {
                li {
                    margin-bottom: 2rem;
                    padding: 0;
                }
                li:last-child {
                    margin-left: 10px;
                }
            }
        }

        .club-page {
            .submenu {
                ul {
                    font-size: 22px;

                    li {
                        margin-bottom: 2.5rem;

                        // &:nth-last-child(-n+3) {
                        //     margin-bottom: 0;
                        // }

                        &.counter-complete {
                            width: 100%;
                            grid-area: counter-complete;
                        }
                    }
                }
            }
        }
        .logo-container {
            margin: auto 1rem auto 1rem !important;
        }
        .name {
            font-size: 2.5rem !important;
        }
    }

    @media (max-width: 1100px) {
        .videos-page .videos {
            grid-template-columns: repeat(3, 1fr) !important;
        }
        .row-inputs {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            grid-gap: 3rem;
        }
    }

    @media (max-width: 820px) {
        .videos-page {
            .videos {
                grid-template-columns: repeat(2, 1fr) !important;
            }
            .nav-filter {
                .filter-tabs .filter-item {
                        margin: 0 16px !important;
                }
            }
        }
        .row-inputs {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-gap: 3rem;
            .national-achievement {
                grid-column: 1/3;
            }
        }
        .athlete-page {
            .image.d-flex.ml-4 {
                #id_card {
                    margin-top: 30px;
                }
            }
        }
    }

    @media (max-width: 607px) {
        .videos-page {
            .videos {
                grid-template-columns: repeat(1, 1fr) !important;
            }
            .nav-filter {
                .filter-tabs .filter-item {
                        margin: 0 8px !important;
                }
            }
        }
        .row-inputs {
            display: grid;
            grid-template-columns: repeat(1, 1fr);
            grid-gap: 3rem;
        }
    }
}