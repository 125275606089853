.events {
	width: 100%;
	margin: 3rem auto;
	.titles {
		width: 90%;
		margin: auto;
		display: flex;
		align-items: center;
		justify-content: space-between;
		text-transform: uppercase;
		h3 {
			font-size: 2rem;
			margin: auto 0;
			&.right-arrow {
				svg {
					vertical-align: middle;
					height: 1.1rem;
					fill: $white;
				}
			}
		}
		a {
			text-decoration: none;
			cursor: pointer;
			color: white;
			font-size: 1.5rem;
		}
	}
	> div {
		width: 100%;
		margin: 0;
	}
	.events-container {
		&::after {
			content: "";
			display: none;
			background: linear-gradient(
				270.21deg,
				rgb(9, 22, 41) 10%,
				rgba(9, 22, 41, 0) 99.82%
			);
			width: 5%;
			height: 100%;
			position: fixed;
			top: 0;
			right: 0;
			z-index: 5;
		}
		position: relative;
		max-width: 95%;
		margin-left: auto;
		margin-top: 2rem;
		.slick-track {
			margin-left: 0;
		}
		.arrow-slideshow {
			background-color: #36598d;
			border-radius: 2rem;
			padding: 2rem 1.2rem;
			border: none;
			position: absolute;
			transform: translateY(-50%) scale(100%);
			top: 50%;
			left: -5rem;
			z-index: 20;
			transition: all 0.3s linear;
			&:hover {
				transform: translateY(-50%) scale(102%);
				transition: all 0.3s linear;
				background-color: #4168a8;
				svg {
					transition: all 0.3s linear;
					transform: scale(98%);
				}
			}
			svg {
				position: relative;
				z-index: 20;
				fill: $white;
				width: 1.5rem;
			}
			&.right {
				left: unset;
				right: 2rem;
			}
			&.disabled {
				svg {
					opacity: 0.3;
				}
			}
		}

		.event {
			&.issue {
				.division,
				.logos {
					opacity: 0.8;
					filter: grayscale(0.9) brightness(0.5);
				}
				border: 2px solid rgba(145, 23, 23, 0.6);
				span.issue {
					display: block;
					cursor: pointer;
					white-space: nowrap;
					background: linear-gradient(
						rgba(255, 255, 255, 0.4),
						rgba(255, 255, 255, 0)
					);
					border: 1px solid rgba(167, 167, 167, 0.4);
					svg {
						fill: $white;
						position: relative;
						top: -0.2rem;
						left: -0.3rem;
						vertical-align: middle;
						width: 1.2rem;
						margin-left: 1rem;
					}
				}
			}
			height: 40rem !important;
			text-align: center;
			width: 30rem !important;

			background: radial-gradient(
				100% 100% at 0% 0%,
				rgba(255, 255, 255, 0.41) 0%,
				rgba(255, 255, 255, 0) 100%
			);
			box-shadow: inset -5px -5px 250px rgba(255, 255, 255, 0.02);
			backdrop-filter: blur(42px);
			border-radius: 8px;
			border: 2px solid rgba(255, 255, 255, 0.6);

			display: grid !important;
			grid-template-areas:
				"division"
				"logos"
				"live"
				"title"
				"date"
				"button";

			.title-container {
				grid-area: title;
				h4 {
					width: 80%;
					margin: auto;
				}
			}
			.division {
				max-height: 40px;
				padding: 3px;
				grid-area: division;
				display: flex;
				align-items: center;
				justify-content: center;
				flex-direction: row-reverse;
				position: relative;
				&::after {
					content: "";
					display: block;
					position: absolute;
					bottom: 0;
					left: 50%;
					transform: translateX(-50%);
					width: 75%;
					height: 2px;
					background-color: white;
				}
				h5 {
					font-size: 1.5rem;
				}
				img {
					width: auto;
					height: 4.5rem;
					margin-right: 0.5rem;
				}
				span {
					display: block;
					width: 20px;
					height: 20px;
				}
			}
			.logos {
				grid-area: logos;
				display: flex;
				align-items: center;
				justify-content: center;
				margin: 10px;
				* {
					margin: 10px;
				}
				.logo-container {
					width: 11rem;
					height: 11rem;
					> img {
						margin: 0;
						object-fit: contain;
						width: 100%;
						height: 100%;
					}
				}

				.vs {
					img {
						margin: -0.5rem;
						width: 1.5rem;
					}
					.VS-V {
						width: 1.5rem;
						position: relative;
						top: 1.2rem;
					}
					.VS-S {
						width: 1.5rem;
						position: relative;
						bottom: 1.2rem;
						right: -0.6rem;
					}
				}
			}
			.live {
				grid-area: live;
				span {
					display: block;
					width: 50%;
					margin: auto;
					padding: 6px;
					font-size: 14px;
					line-height: 19px;
					text-transform: uppercase;
					border-radius: 20px;
					&.live {
						background-color: #d32b2b;
					}
					&.off {
						background: linear-gradient(
							rgba(255, 255, 255, 0.4),
							rgba(255, 255, 255, 0)
						);
						border: 1px solid rgba(167, 167, 167, 0.4);
					}
				}
			}
			.date {
				grid-area: date;
				span {
					font-size: smaller;
					font-style: italic;
					color: grey;
				}
			}
			.button {
				grid-area: button;
				button {
					background: transparent;
					font-family: $fontRegular;
					padding: 10px;
					border-radius: 200px;
					border: 1px solid rgba(255, 255, 255, 0.4);
					color: white;
					transition: all 0.1s;
					cursor: pointer;
					&:hover {
						background-color: blue;
						transition: all 0.1s;
					}
				}
			}
		}
		a {
			color: $white;
			text-decoration: none;
			z-index: 5;
			position: relative;
		}
	}
}
@media (max-width: $mobile) {
	.events {
		.events-container {
			.arrow-slideshow {
				top: calc(50% - 4.5rem);

				&.left {
					left: unset;
				}
			}
		}
	}
}
