.bo-subscription, .bo-coupon {
	> nav {
		margin-top: 1rem;
		ul {
			width: 80%;
			display: flex;
			justify-content: center;
			margin: auto auto 5rem;

			li {
				border: 1px $white solid;
				border-radius: 2rem;
				padding: 1rem;
				margin-right: 3rem;
				transition: 0.2s all;
				cursor: pointer;
				&:hover {
					background-color: lighten($blue, 40%);
					border-color: transparent;
					transition: 0.2s all;
				}
				&.selected {
					background-color: lighten($blue, 40%);
					border-color: transparent;
				}
			}
		}
	}
}
