.naviguation-bar {
	position: relative;

	article {
		margin: 2rem;
		h2 {
			font-size: 2rem;
		}

		.countries-container,
		.sports-container {
			display: flex;
			.checkbox {
				margin-top: 1rem;
				display: grid;
				grid-template-columns: 1fr 1fr;
				input[type="checkbox"] {
					position: absolute;
					left: -9999rem;
					top: -9999rem;

					&:checked + label {
						position: relative;
						border: $green 1px solid;
						&::after {
							cursor: pointer;
							content: " ";
							width: 1.5rem;
							height: 1.5rem;
							position: absolute;
							right: -0.25rem;
							top: -0.25rem;

							mask: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBkPSJNMTIuMDE1IDdjNC43NTEgMCA4LjA2MyAzLjAxMiA5LjUwNCA0LjYzNi0xLjQwMSAxLjgzNy00LjcxMyA1LjM2NC05LjUwNCA1LjM2NC00LjQyIDAtNy45My0zLjUzNi05LjQ3OC01LjQwNyAxLjQ5My0xLjY0NyA0LjgxNy00LjU5MyA5LjQ3OC00LjU5M3ptMC0yYy03LjU2OSAwLTEyLjAxNSA2LjU1MS0xMi4wMTUgNi41NTFzNC44MzUgNy40NDkgMTIuMDE1IDcuNDQ5YzcuNzMzIDAgMTEuOTg1LTcuNDQ5IDExLjk4NS03LjQ0OXMtNC4yOTEtNi41NTEtMTEuOTg1LTYuNTUxem0tLjAxNSAzYy0yLjIxIDAtNCAxLjc5MS00IDRzMS43OSA0IDQgNGMyLjIwOSAwIDQtMS43OTEgNC00cy0xLjc5MS00LTQtNHptLS4wMDQgMy45OTljLS41NjQuNTY0LTEuNDc5LjU2NC0yLjA0NCAwcy0uNTY1LTEuNDggMC0yLjA0NGMuNTY0LS41NjQgMS40NzktLjU2NCAyLjA0NCAwcy41NjUgMS40NzkgMCAyLjA0NHoiLz48L3N2Zz4=");
							mask-size: contain;
							mask-repeat: no-repeat;
							background-color: $white;
						}
						&::before {
							cursor: pointer;
							content: " ";
							width: 2rem;
							height: 2rem;
							right: -0.5rem;
							top: -0.5rem;
							position: absolute;
							border-radius: 50%;
							background-color: $green;
						}
					}
				}
				label {
					cursor: pointer;
					display: flex;
					flex-direction: column;
					padding: 0.5rem;
					margin: 1rem 2rem 1rem 0;
					position: relative;
					background: radial-gradient(
						100% 100% at 0% 0%,
						rgba(255, 255, 255, 0.28) 0%,
						rgba(255, 255, 255, 0) 100%
					);
					box-shadow: inset -5px -5px 250px rgba(255, 255, 255, 0.02);
					backdrop-filter: blur(20px);
					border: 1px solid grey;

					border-radius: 6px;

					&::after {
						cursor: pointer;
						content: " ";
						width: 1.5rem;
						height: 1.5rem;
						position: absolute;
						right: -0.25rem;
						top: -0.25rem;

						mask: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBkPSJNMTkuNjA0IDIuNTYybC0zLjM0NiAzLjEzN2MtMS4yNy0uNDI4LTIuNjg2LS42OTktNC4yNDMtLjY5OS03LjU2OSAwLTEyLjAxNSA2LjU1MS0xMi4wMTUgNi41NTFzMS45MjggMi45NTEgNS4xNDYgNS4xMzhsLTIuOTExIDIuOTA5IDEuNDE0IDEuNDE0IDE3LjM3LTE3LjAzNS0xLjQxNS0xLjQxNXptLTYuMDE2IDUuNzc5Yy0zLjI4OC0xLjQ1My02LjY4MSAxLjkwOC01LjI2NSA1LjIwNmwtMS43MjYgMS43MDdjLTEuODE0LTEuMTYtMy4yMjUtMi42NS00LjA2LTMuNjYgMS40OTMtMS42NDggNC44MTctNC41OTQgOS40NzgtNC41OTQuOTI3IDAgMS43OTYuMTE5IDIuNjEuMzE1bC0xLjAzNyAxLjAyNnptLTIuODgzIDcuNDMxbDUuMDktNC45OTNjMS4wMTcgMy4xMTEtMi4wMDMgNi4wNjctNS4wOSA0Ljk5M3ptMTMuMjk1LTQuMjIxcy00LjI1MiA3LjQ0OS0xMS45ODUgNy40NDljLTEuMzc5IDAtMi42NjItLjI5MS0zLjg1MS0uNzM3bDEuNjE0LTEuNTgzYy43MTUuMTkzIDEuNDU4LjMyIDIuMjM3LjMyIDQuNzkxIDAgOC4xMDQtMy41MjcgOS41MDQtNS4zNjQtLjcyOS0uODIyLTEuOTU2LTEuOTktMy41ODctMi45NTJsMS40ODktMS40NmMyLjk4MiAxLjkgNC41NzkgNC4zMjcgNC41NzkgNC4zMjd6Ii8+PC9zdmc+");
						mask-size: contain;
						mask-repeat: no-repeat;
						background-color: $white;
					}
					&::before {
						cursor: pointer;
						content: " ";
						width: 2rem;
						height: 2rem;
						right: -0.5rem;
						top: -0.5rem;
						position: absolute;
						border-radius: 50%;
						background-color: grey;
					}
					img {
						width: 10rem;
						height: 10rem;
						display: block;
					}
					p {
						display: block;
					}
				}
			}
		}
	}
}
