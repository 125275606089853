.account-page {
	display: flex;
	position: relative;
	margin-bottom: -2rem;
	.banner {
		&::before {
			content: "";
			position: absolute;
			width: 100%;
			height: 100%;
			z-index: 1;
			background-color: rgba(11, 19, 83, 0.507);
		}

		min-width: 45%;
		max-width: 45%;
		position: relative;
		> img {
			width: 100%;
			height: 100%;
			object-fit: cover;
			object-position: center;
		}

		.dots {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;

			img {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
			}
		}

		.decorations {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;

			img {
				position: absolute;
				bottom: 0;
				left: 0;
				width: 50%;
				z-index: 2;
				&:nth-of-type(4) {
					right: 0;
					left: unset;
				}
			}
		}
	}

	.account {
		display: flex;
		width: 100%;
		background: radial-gradient(
			100% 100% at 0% 0%,
			rgba(255, 255, 255, 0.256) 0%,
			rgba(255, 255, 255, 0) 100%
		);
		box-shadow: inset -5px -5px 250px rgba(255, 255, 255, 0.02);
		backdrop-filter: blur(20px);
		.account-container {
			position: relative;
			width: 65%;
			margin: 16rem auto 5rem;

			h2 {
				font-size: 2rem;
				font-weight: bolder;
				margin-bottom: 2rem;
				display: none;
				&.display,
				&.untouch {
					display: block !important;
				}

				&.border {
					position: relative;
					margin-bottom: 4rem;
					&::before {
						display: block;
						content: "";
						position: absolute;
						left: 0;
						bottom: -2rem;
						width: 50%;
						height: 0.1rem;
						background-color: rgba(128, 128, 128, 0.452);
					}
				}
			}

			a {
				text-decoration: none;
				color: $white;

				article {
					margin-top: 2rem;
					background: radial-gradient(
						100% 100% at 0% 0%,
						rgba(255, 255, 255, 0.28) 0%,
						rgba(255, 255, 255, 0) 100%
					);
					box-shadow: inset -5px -5px 250px rgba(255, 255, 255, 0.02);
					backdrop-filter: blur(20px);

					border-radius: 1.2rem;
					margin-bottom: 2rem;
					min-height: 4rem;

					padding: 1rem;
					display: flex;

					align-items: center;
					.text {
						margin-left: 2rem;
						h3 {
							margin-bottom: 1rem;
							font-weight: bold;
						}
					}
					.svg {
						background: linear-gradient(
							180deg,
							rgba(255, 255, 255, 0.6) 0%,
							rgba(255, 255, 255, 0) 100%
						);
						box-shadow: inset -5px -5px 250px rgba(255, 255, 255, 0.02);
						backdrop-filter: blur(20px);

						border-radius: 50%;
						padding: 1rem;
						border: 2px grey solid;

						svg {
							width: 2rem;
							height: 2rem;
							fill: $white;
						}
					}

					.arrow {
						svg {
							fill: $white;
							height: 1.2rem;
						}
					}
				}
			}
			#submit {
				background-color: #2e56e4;
				border-radius: 4rem;
				padding: 2rem;
				width: 100%;
				color: $white;
				border: none;
				&:disabled {
					background-color: rgba(202, 211, 255, 0.24) !important;
				}
			}
			.errorDisplay {
				position: absolute;
				top: 50%;
				transform: translateY(-50%);
				right: 1rem;
				color: $red;

				font-size: 1.3rem;
				font-weight: bolder;
				text-transform: uppercase;
				animation: wiggleSmall 0.1s linear 3;
			}
			.error {
				.validator {
					margin-top: 2rem;
					display: flex;
					div {
						&:first-of-type {
							svg {
								margin-left: 0;
							}
						}
						svg {
							vertical-align: middle;
							fill: $red;
							width: 1.2rem;
							margin: 0 1rem 0 3rem;

							&.validate {
								fill: $green;
							}
						}
					}
				}
			}
			.page {
				display: none;
				&.display {
					display: block;
				}

				&#page-2 {

				}

				&#page-3 {
					.input {
						input[type="checkbox"] {
							position: absolute;
							left: -9999rem;
							top: -9999rem;
							display: none;
							&:checked + label > .box {
								background-color: rgba(82, 110, 255, 1);
								svg {
									display: block;
								}
							}
						}
						.checkbox {
							margin-top: 1rem;
							display: grid;
							grid-template-columns: 1fr 1fr;
							input[type="checkbox"] {
								position: absolute;
								left: -9999rem;
								top: -9999rem;
								display: none;

								&:checked + label {
									position: relative;
									border: $green 1px solid;
									&::after {
										cursor: pointer;
										content: " ";
										width: 1.5rem;
										height: 1.5rem;
										position: absolute;
										right: -0.25rem;
										top: -0.25rem;

										mask: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBkPSJNMjAuMjg1IDJsLTExLjI4NSAxMS41NjctNS4yODYtNS4wMTEtMy43MTQgMy43MTYgOSA4LjcyOCAxNS0xNS4yODV6Ii8+PC9zdmc+");
										mask-size: contain;
										mask-repeat: no-repeat;
										background-color: $white;
									}
									&::before {
										cursor: pointer;
										content: " ";
										width: 2rem;
										height: 2rem;
										right: -0.5rem;
										top: -0.5rem;
										position: absolute;
										border-radius: 50%;
										background-color: $green;
									}
								}
							}
							label {
								cursor: pointer;
								display: flex;
								flex-direction: column;
								padding: 0.5rem;
								margin: 1rem 2rem 1rem 0;
								position: relative;
								background: radial-gradient(
									100% 100% at 0% 0%,
									rgba(255, 255, 255, 0.28) 0%,
									rgba(255, 255, 255, 0) 100%
								);
								box-shadow: inset -5px -5px 250px rgba(255, 255, 255, 0.02);
								backdrop-filter: blur(20px);
								border: 1px solid grey;
								max-width: 20rem;
								border-radius: 6px;
								img {
									width: 8rem;
									display: block;
								}
								p {
									display: block;
								}
							}
						}

						label.radio {
							margin-bottom: 1rem;
							display: flex;
							flex-direction: row !important;
							align-items: center;
							&.sport.error {
								border: red 1px solid;
							}
							a {
								color: rgb(121, 178, 252);
								&.no-wrap p {
									display: contents;
								}
							}
							&[type="radio"] {
								position: absolute;
								top: -9999rem;
								left: -9999rem;
								display: none;

								&:checked + label > .box {
									background-color: rgba(82, 110, 255, 1);
									svg {
										display: block;
									}
								}
							}
							.box {
								.div {
									width: 2rem;
									height: 2rem;
								}

								svg {
									@include center(true, true);
									display: none;
								}
							}
							p {
								width: 100%;
							}
						}
					}
				}
			}
			.page-select-right {
				justify-content: flex-end !important;
			}
			.page-select, .page-select-right {
				display: flex;
				justify-content: space-between;
				margin-top: 2rem;
				#submit {
					background-color: $green !important;
					padding-right: 3.5rem !important;
					height: 4rem;
					line-height: 0;
					text-align: left;
				}
				label {
					position: relative;
					height: 100%;
					width: 45%;
					&::after {
						cursor: pointer;
						content: " ";
						width: 2.5rem;
						height: 2.5rem;
						position: absolute;
						right: 1.4rem;
						top: calc(50%);
						transform: translateY(-50%);
						mask: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjMiIGhlaWdodD0iMjAiIHZpZXdCb3g9IjAgMCAyMyAyMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0xMi41MzY0IDMuNTc3MDlDMTIuMTc2MyAzLjI1MTY1IDExLjU5MjUgMy4yNTE2NSAxMS4yMzI0IDMuNTc3MDlDMTAuODcyMyAzLjkwMjUyIDEwLjg3MjMgNC40MzAxNiAxMS4yMzI0IDQuNzU1NkwxNi4xMTI5IDkuMTY2MzRINS40Mjk4OUM0LjkyMDY0IDkuMTY2MzQgNC41MDc4MSA5LjUzOTQ0IDQuNTA3ODEgOS45OTk2N0M0LjUwNzgxIDEwLjQ1OTkgNC45MjA2NCAxMC44MzMgNS40Mjk4OSAxMC44MzNIMTYuMTEyOUwxMS4yMzI0IDE1LjI0MzhDMTAuODcyMyAxNS41NjkyIDEwLjg3MjMgMTYuMDk2OCAxMS4yMzI0IDE2LjQyMjNDMTEuNTkyNSAxNi43NDc3IDEyLjE3NjMgMTYuNzQ3NyAxMi41MzY0IDE2LjQyMjNMMTguOTkxIDEwLjU4ODlDMTkuMzUxMSAxMC4yNjM1IDE5LjM1MTEgOS43MzU4NiAxOC45OTEgOS40MTA0MkwxMi41MzY0IDMuNTc3MDlaIiBmaWxsPSJ3aGl0ZSIvPgo8L3N2Zz4K");
						mask-size: contain;
						mask-repeat: no-repeat;
						background-color: $white;
					}
				}
				button {
					background-color: #2e56e4;
					border-radius: 4rem;
					display: flex;
					justify-content: space-between;
					width: 45%;
					height: 4rem;
					color: $white;
					border: none;
					padding-right: 3.8rem;
					padding-left: 3.8rem;
					&:first-of-type {
						background-color: #6f7fb5;
					}
					p {
						margin: auto 0;
						font-size: 16px;
					}
					svg {
						fill: $white;
						margin: auto 0;
						&.left {
							margin-right: 2rem;
						}
						&.right {
							margin-left: 2rem;
						}
					}
				}
			}

			.page-display {
				margin-bottom: 2rem;
				> span {
					border: 1px solid grey;
					border-radius: 50%;
					padding: 0.5rem 0.9rem;
					margin-right: 3rem;
					display: inline-block;
					position: relative;
					svg {
						display: none;
					}
					span {
						color: $white;
					}

					&.validate {
						position: relative;
						background-color: $green;
						border: transparent 1px solid;
						span {
							color: transparent;
						}
						svg {
							display: block;
							position: absolute;
							@include center(true, true);
						}
					}
					&.display {
						background-color: #2e56e4;
						border: transparent 1px solid;
					}
					&:last-of-type::after {
						display: none;
					}
					&::after {
						content: "";
						display: block;
						position: absolute;
						width: 2rem;
						height: 1px;
						background-color: grey;
						top: 50%;
						left: calc(100% + 0.5rem);
						transform: translateY(-50%);
					}
				}
			}
		}
	}
	.reset_pass {
		margin: 1rem 0;
		display: block;
	}
}
.confirm-message {
	&.display {
		visibility: visible;
		opacity: 1;
		transition: all $fade;
		line-height: 2rem;
	}

	visibility: hidden;

	opacity: 0;
	transition: all $fade;
	position: absolute;
	top: 30%;
	left: 50%;
	transform: translate(-50%, -50%);
	z-index: 15;
	display: flex;
	flex-direction: column;

	background: radial-gradient(
					100% 100% at 0% 0%,
					rgba(255, 255, 255, 0.28) 0%,
					rgba(255, 255, 255, 0) 100%
	);
	box-shadow: inset -5px -5px 250px rgba(255, 255, 255, 0.02);
	backdrop-filter: blur(20px);
	border-radius: 1rem;
	padding: 4rem;
	justify-content: center;
	align-items: center;
	text-align: center;

	.svg {
		background-color: $green;
		border-radius: 50%;
		width: 8rem;
		height: 8rem;
		svg {
			fill: $white;
			position: relative;
			top: 1.5rem;
			width: 5rem;
			height: 5rem;
		}
	}

	h2 {
		width: 100%;
		position: static;
		margin-top: 2rem;
		margin-bottom: 2rem;
		display: block;
		font-weight: bold;
		font-size: 3rem;
	}

	a {
		text-decoration: none;
		color: $white;
		margin-top: 2rem;
		background-color: $green;
		padding: 1rem;
		border-radius: 2rem;
		width: 50%;
		min-width: 110px;
		svg {
			margin-left: 1rem;
			fill: $white;
			vertical-align: middle;
		}
	}
	.validate_link {
		background: none;
		color: #65a4faff;
		text-decoration: underline;
		margin-top: 5px;
		&:hover {
			color: white;
			cursor: pointer;
		}
	}
}
.multiSelectContainer {
	padding-top: 10px;
	padding-bottom: 20px;
	.searchWrapper {
		background-color: rgba(202, 211, 255, 0.0676517);
		color: #ffffff !important;
		.chip, .highlightOption {
			background: #162234;
			border-radius: 4px;
		}
		input {
			color: #b1b1b1;
		}
	}
	.optionContainer {
		li {
			font-family: $fontRegular;
			color: grey;
			&.highlightOption {
				color: #b1b1b1;
			}
			&.option:hover {
				color: #b1b1b1;
				background: #162234;
			}
		}
		.highlightOption {
			background: #162234;
		}
	}
}


@media (max-width: $mobile) {
	.account-page {
		.banner {
			display: none;
		}
		.account {
			background: unset;
			.account-container {
				width: 89% !important;
				margin: 2rem auto;
				input[type="date"] {
					-webkit-appearance: none;
					border-radius: unset;
				}
			}
		}
	}
	.confirm-message {
		width: 68%;
		top: 50%;
		h2 {
			line-height: 1;
		}
	}
}
