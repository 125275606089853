.notifications {
	padding: 2rem 2rem 6rem 2rem;
	width: 100%;
	h3 {
		font-weight: bolder;
		font-size: 1.8rem;
		margin-bottom: 4rem;
		position: relative;
		top: 0.6rem;
		&::after {
			content: " ";
			position: absolute;
			bottom: -1rem;
			left: 0;
			width: 100%;
			height: 0.1rem;
			background-color: grey;
		}
	}

	article {
		margin-bottom: 3rem;
		h4 {
			font-weight: bold;
			font-size: 1.6rem;
			margin-bottom: 2rem;
		}
		.input {
			display: flex;
			label {
				display: block;
				width: 40rem;
				top: 0.7rem;
				background-color: transparent;
				margin-right: 2rem;
				padding: 0;
				font-size: 1.3rem;
			}
		}
	}
}
@media (max-width: $mobile) {
	.notifications {
		width: auto;
	}
}