.banner {
	.trigger-bottom-scroll-to {
		cursor: pointer;
		color: #ffffff;
		align-items: center;
		justify-content: space-around;
		flex-direction: column;
		display: flex;
		position: absolute;
		left: 0;
		right: 0;
		bottom: 3%;
		height: 5rem;
	}
	.events-container {
		position: relative;
		height: 100%;
		&.multi {
				.slick-active:nth-of-type(odd) {
					.event {
						margin-right: 1rem;
					}
				}
				.slick-active:nth-of-type(2n) {
					.event {
						margin-left: 1rem;
					}
				}
		}
		.slick-list {
			margin-bottom: 25px;
		}
		.event {
			position: relative;
			overflow: hidden;
			> img {
				width: 100%;
				height: 40vh;
				object-fit: cover;
				user-select: none;
				position: relative;
				display: block;
			}

			&::before {
				content: "";
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				opacity: 0.8;
				background: linear-gradient(
					180deg,
					rgba(7, 17, 32, 0.72) 0%,
					rgba(7, 17, 32, 0) 100%
				);
				z-index: 5;
			}

			&.display-1 {
				.title {
					position: absolute;
					bottom: 16%;
					left: 12%;
					text-transform: uppercase;
					z-index: 5;
					h1 {
						font-weight: 900;
						font-size: 3rem;
						margin-bottom: 1rem;
						backdrop-filter: blur(4px);
						border: 1px solid rgba(255, 255, 255, 0.6);
						display: block;
						padding: 1rem 5rem;
						transform: skewX(195deg);
						span {
							transform: skewX(-195deg);
							display: block;
							text-align: center;
						}
					}

					h2 {
						font-weight: 600;
						font-size: 1.7rem;
					}
				}
				.decorations {
					user-select: none;
					position: absolute;
					bottom: 0;
					img {
						position: absolute;
						bottom: 0;
						left: 0;
						height: 20vh;

						&:last-of-type {
							left: 100vw;
							transform: translateX(-100%);
							height: 35vh;
						}
					}
				}
				.dots {
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					z-index: 3;
					img {
						position: absolute;
						top: 0;
						left: 0;
						width: 100%;
						height: 100%;
						z-index: 3;
					}
				}
				.play {
					cursor: pointer;
					z-index: 5;
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%);
					border-radius: 50%;
					width: 6rem;
					height: 6rem;

					background: rgba(255, 255, 255, 0.31);
					border: 1px solid #ffffff;

					display: flex;
					justify-content: center;
					align-items: center;

					img {
						width: 3.5rem;
						height: 3.5rem;
						position: relative;
						left: 0.1rem;
					}
				}
			}

			&.display-2 {
				$space: -1.4rem;
				.title {
					z-index: 5;
					@include center(false, true);
					top: 48%;
					text-transform: uppercase;
					h1 {
						position: relative;
						width: 75vw;
						display: flex;
						justify-content: space-evenly;

						> div {
							position: relative;

							span.team {
								width: 25rem;
								font-size: 4rem;
								font-weight: bolder;
								white-space: nowrap;
								overflow-x: hidden;
								text-align: center;
								display: block;
								background-color: rgba(239, 47, 42, 1);
								padding: 1.5rem 2.5rem;
								clip-path: polygon(10% 0%, 100% 0%, 90% 100%, 0% 100%);
								position: relative;
							}
							.white-strip-top,
							.white-strip-bottom {
								height: 0.2rem;
								width: 20%;
								background-color: $white;
								position: absolute;

								top: -1rem;
								left: 3rem;

								&::after {
									content: "";
									display: block;
									height: 0.2rem;
									width: 30%;
									background-color: $white;
									position: absolute;

									top: 0rem;
									left: calc(100% + 0.5rem);
								}
							}
							.white-strip-bottom {
								top: unset;
								bottom: -1rem;
								left: unset;
								right: 3rem;
								transform: scaleX(-100%);
							}
							.decorations {
								display: block;
								position: absolute;
								top: 0;
								left: calc(#{-$space} / 2);
								height: 100%;
								li {
									display: inline-block;
									margin: 0 $space;
									width: 1rem;
									height: 100%;
									background-color: rgba(239, 47, 42, 1);
									transform: skewX(-22deg);
									position: relative;
									&::after,
									&::before {
										content: "";
										display: block;
										height: 50%;
										width: 0.15rem;
										background-color: $white;
										position: absolute;
										top: 0;
										left: 0;
									}
									&::before {
										display: none;
									}
									&:nth-of-type(1) {
										&::after {
											height: 35%;
											top: 75%;
											left: 0;
										}
									}
									&:nth-of-type(2) {
										&::after {
											height: 35%;
											top: -10%;
											left: 100%;
										}
									}
									&:nth-of-type(3) {
										&::after {
											height: 35%;
											top: 50%;
											transform: translateY(-50%);
											left: 100%;
										}
									}
									&:nth-of-type(4) {
										&::after {
											height: 35%;
											top: 70%;

											left: 100%;
										}
										&::before {
											display: block;
											height: 35%;
											top: 0%;

											left: 0;
										}
									}
									&:nth-of-type(5) {
										&::after {
											height: 75%;
											top: -12%;
											left: 0;
										}
									}
								}
							}

							&:last-of-type {
								span.team {
									clip-path: polygon(0% 0%, 90% 0%, 100% 100%, 10% 100%) !important;
									padding: 1.5rem 2.5rem 1.5rem 3rem;
								}
								.decorations {
									right: calc(#{-$space} / 2);
									transform: scaleX(-100%);
									li {
										transform: skewX(-25deg);
									}
								}

								.white-strip-top {
									left: unset;
									right: unset;
								}
								.white-strip-bottom {
									left: unset;
									right: 0;
								}
							}
						}
					}
					h2 {
						font-size: 2rem;
						font-weight: normal;
						text-shadow: 0.8px 0.8px 0 black;
						text-align: center;
						position: relative;
						top: 3rem;
					}
				}
				.play {
					cursor: pointer;
					z-index: 5;
					@include center(false, true);
					top: 50%;
					border-radius: 50%;
					width: 6rem;
					height: 6rem;

					background: rgba(255, 255, 255, 0.31);
					border: 1px solid #ffffff;

					display: flex;
					justify-content: center;
					align-items: center;

					img {
						width: 3.5rem;
						height: 3.5rem;
						position: relative;
						left: 0.1rem;
					}
				}
				.decorations {
					display: none;
				}
				.dots {
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					z-index: 3;
					img {
						position: absolute;
						top: 0;
						left: 0;
						width: 100%;
						height: 100%;
						z-index: 3;
					}
				}
			}

			&.display-3 {
				.title {
					z-index: 5;
					top: 60%;
					position: absolute;
					width: 100%;
					display: flex;
					justify-content: center;
					h1 {
						font-weight: 900;
						font-size: 3rem;
						width: fit-content;
						margin-bottom: 1rem;
						backdrop-filter: blur(4px);
						border: 1px solid rgba(255, 255, 255, 0.6);
						display: block;
						padding: 1rem 2rem;
						transform: skewX(195deg);
						span {
							transform: skewX(-195deg);
							display: block;
							text-align: center;
						}
					}

					h2 {
						font-weight: 600;
						font-size: 2rem;
					}
				}
				.decorations {
					user-select: none;
					position: absolute;
					bottom: 0;
					img {
						position: absolute;
						bottom: 0;
						left: 0;
						height: 20vh;

						&:last-of-type {
							left: 100vw;
							transform: translateX(-100%);
							height: 35vh;
						}
					}
				}
				.dots {
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					z-index: 3;
					img {
						position: absolute;
						top: 0;
						left: 0;
						width: 100%;
						height: 100%;
						z-index: 3;
					}
				}
				.play {
					cursor: pointer;
					z-index: 5;
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%);
					border-radius: 50%;
					width: 6rem;
					height: 6rem;

					background: rgba(255, 255, 255, 0.31);
					border: 1px solid #ffffff;

					display: flex;
					justify-content: center;
					align-items: center;

					img {
						width: 3.5rem;
						height: 3.5rem;
						position: relative;
						left: 0.1rem;
					}
				}
			}
		}
	}

	.slick-dots {
		position: absolute;
		bottom: -30px;
		left: 50%;
		transform: translateX(-50%);
		z-index: 5;
		display: flex;

		li {
			margin-right: 3rem;

			button {
				cursor: pointer;
				display: block;
				border: none;

				width: 4rem;
				height: 0.5rem;
				padding: 0;
				background-color: #ffffff;
				opacity: 0.6;
				position: relative;
				&::before {
					display: none;
				}
				&:after {
					content: "";
					width: calc(100% + 1rem);
					height: calc(100% + 3rem);
					position: absolute;
					top: -1.5rem;
					left: -0.5rem;
				}
			}
			&.slick-active {
				button {
					background-color: #ef2f2a !important;
					opacity: 1 !important;
				}
			}
		}
	}

	.arrow-slideshow {
		background-color: rgba(0, 0, 0, 0.4);
		border-radius: 1.5rem;
		padding: 2.2rem 1rem;
		border: none;
		position: absolute;
		top: calc(50% - 4.4rem);
		left: 1rem;
		z-index: 1;
		transition: all 0.3s linear;
		&:hover {
			transform: scale(102%);
			transition: all 0.3s linear;

			svg {
				transition: all 0.3s linear;
				transform: scale(98%);
			}
		}
		svg {
			transition: all 0.3s linear;
			transform: scale(100%);
			fill: $white;
			width: 2rem;
		}
		&.right {
			left: unset;
			right: 1rem;
		}
		&.disabled {
			svg {
				opacity: 0.3;
			}
		}
	}
}

@media (max-width: $mobile) {
	.banner {
		.display-1 {
			.decorations {
				img {
					height: 10vh !important;
				}
			}
			.title {
				left: 10% !important;
				h1 {
					font-size: 3rem !important;
					margin-bottom: 1.5rem !important;
				}
			}
		}

		.display-2 {
			.title {
				top: 65% !important;
				h1 {
					display: block !important;
					width: 75% !important;
					> div {
						span.team {
							font-size: 3rem !important;
							margin-bottom: 1rem;
							padding: 1rem 2rem !important;
						}
						.decorations {
							left: 1.1rem !important;
							li {
								transform: skewX(-29deg) !important;
								width: 0.9rem !important;
								margin: 0 -1.1rem !important ;
							}
						}
						.white-strip-top {
							top: -0.55rem !important;
							left: unset;
							right: unset;
						}
						.white-strip-bottom {
							display: none;
						}
						&:last-of-type {
							.decorations {
								right: -6.2rem !important;
								transform: scaleX(-100%);
								left: unset !important;
								li {
									transform: skewX(-29deg);
								}
							}

							.white-strip-top {
								display: none;
								left: unset;
								right: unset;
							}
							.white-strip-bottom {
								display: block;
								bottom: -0.5rem !important;
								left: unset;
								right: -7rem !important;
							}
						}
					}
				}
			}
			.play {
				top: 50% !important;
			}
			h2 {
				font-size: 1.7rem !important;
				top: 0.15rem !important;
			}
		}

		.slick-dots {
			button {
				height: 0.3rem !important;
			}
		}
	}
}
