#fullscreen_background {
	position: fixed;
	width: 100vw;
	height: 100vh;
	background-color: black;
	top: 0;
	z-index: 99;
}

.player-page {
	position: relative;
	padding-top: 6rem;
	.video-iframe {
		width: 70%;
		margin-right: 2%;
		height: 100%;
		img {
			display: block;
			width: 100%;
			height: auto;
		}
		&.fixed {
			#video {
				position: fixed !important;
				display: flex;
				justify-content: center;
				width: 100%;
				z-index: 99;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
			}
		}
	}
	.playlist {
		width: 20.5%;
		height: 100%;
		h4 {
			color: $white;
			font-size: 1.6rem;
			font-weight: bold;
			margin-bottom: 3rem;
		}
		.videos-container {
			overflow-y: auto;
			position: relative;
			height: calc(100% - 4.6rem);
			a {
				text-decoration: none;
				color: $white;

				.image-container {
					margin-bottom: 0.5rem;
					position: relative;
					width: 20rem;
					img {
						display: block;
						width: 100%;
					}

					span {
						position: absolute;
						bottom: 1rem;
						right: 1rem;
						background: rgba(17, 31, 58, 0.88);
						border-radius: 66px;
						padding: 0.5rem;
						font-weight: light;
						font-size: 1.2rem;
					}
				}

				.title-container {
					margin-bottom: 1.5rem;
					h5 {
						font-weight: normal;
					}
				}
			}
		}
	}
	.video-data {
		padding: 1.2rem 0;
		border-bottom: 1px solid rgba(255, 255, 255, 0.14);
		.first-row {
			display: flex;
			justify-content: space-between;
			h2 {
				font-weight: bolder;
				font-size: 3rem;
				text-transform: uppercase;
				width: 70%;
			}
			.share {
				position: relative;
				cursor: pointer;
				&:hover {
					ul {
						display: block;
					}
				}
				.share-button {
					margin-right: 4rem;
					height: 2rem;
					background: radial-gradient(
									100% 100% at 0% 0%,
									rgba(255, 255, 255, 0.28) 0%,
									rgba(255, 255, 255, 0) 100%
					);
					box-shadow: inset -5px -5px 250px rgba(255, 255, 255, 0.02);
					backdrop-filter: blur(20px);
					border-radius: 102px;
					text-decoration: none;
					color: $white;
					padding: 1rem 2rem;
					border: 1px solid grey;
					display: block;
					p {
						font-size: 1.7rem;
						font-weight: normal;
					}
					svg {
						fill: $white;
						vertical-align: middle;
						margin-right: 0.5rem;
					}
				}

				ul {
					display: none;
					position: absolute;
					bottom: calc(100% + 0rem);
					background: radial-gradient(
									100% 100% at 0% 0%,
									rgba(255, 255, 255, 0.28) 0%,
									rgba(255, 255, 255, 0) 100%
					);
					box-shadow: inset -5px -5px 250px rgba(255, 255, 255, 0.02);
					backdrop-filter: blur(400px);
					border-radius: 1rem;
					padding: 1rem 2rem;
					z-index: 9999;
					li {
						svg {
							fill: $white;
							vertical-align: middle;
							margin-right: 0.5rem;
						}
						&:not(.copy-message) {
							border-bottom: 1px grey solid;
							padding-bottom: 0.5rem;
							margin-bottom: 1rem;
							padding: 0.5rem;
							border-radius: 0.5rem;
							cursor: pointer;
							&:hover {
								background-color: rgba(255, 255, 255, 0.1);
							}
							&:last-of-type {
								margin-bottom: 0;
							}
						}

						&.copy-message {
							opacity: 0;
							position: absolute;
							left: calc(100% + 1rem);
							width: 17rem;
							white-space: nowrap;
							background-color: $green;
							padding: 0.7rem;
							border-radius: 0.4rem;
							top: calc(0% + 0.5rem);
							animation: wiggleFade 2s;
						}
					}
				}
			}
		}

		p {
			text-transform: uppercase;
			font-weight: lighter;
			font-size: 2rem;
		}
	}

	.webtv-data {
		width: 100%;
		padding: 1.2rem 0;
		margin-bottom: 1rem;
		border-bottom: 1px solid rgba(255, 255, 255, 0.14);
		display: flex;
		align-items: center;
		.logo {
			width: 7rem;
			height: 7rem;
			display: block;
			border-radius: 50%;
			img {
				width: 100%;
				height: 100%;
				object-fit: contain;
			}
		}

		.title {
			margin-left: 2rem;
			margin-right: 3rem;
			a {
				text-decoration: none;
				color: $white;

				h3 {
					font-size: 2rem;
					font-weight: bolder;
				}
			}
			> p {
				display: inline-block;
				margin-left: 1rem;
			}
			svg {
				fill: $white;
				width: 1.5rem;
				vertical-align: bottom;
				position: relative;
				top: 0.1rem;
			}
		}
		> button {
			background-color: transparent;
			border: 2px solid grey;
			border-radius: 102px;
			text-decoration: none;
			color: $white;
			padding: 1rem 2rem;
			position: relative;
			transition: all 0.1s linear;
			top: -0.2rem;
			box-sizing: border-box;
			text-transform: capitalize;
			p {
				margin: unset;
				font-size: unset;
			}
			&.follow,
			&:hover {
				background: #2e56e4;
				box-shadow: 0px 2px 2px -2px rgba(34, 38, 50, 0.3);
				border: transparent 2px solid;
				transition: all 0.1s linear;
			}
		}
	}

	.descr {
		grid-area: descr;
		border-bottom: 1px solid rgba(255, 255, 255, 0.14);
		padding-bottom: 1.2rem;
		margin-bottom: 1rem;
		h3 {
			margin-bottom: 1rem;
			font-weight: bold;
		}
		p {
			font-weight: lighter;
		}
	}

	.similar-video-container {
		.similar-grid {
			display: flex;
			flex-wrap: wrap;
			margin-top: 2.5rem;
			.video {
				position: relative;
				margin: 0 1.5rem 1.5rem 0;
				width: 18.8%;
				a {
					color: white;
					text-decoration: none;
					width: 100%;
					height: 100%;
					> img {
						width: 100%;
						max-height: 122px;
					}
					.title-container {
						position: relative;
						max-width: 100%;
						height: 5rem;

						display: flex;
						align-items: center;
						h4 {
							z-index: 3;
							margin-left: 1rem;
						}
						img {
							position: absolute;
							right: 0;
							bottom: 0;
							height: 100%;
						}
					}
					span {
						position: absolute;
						bottom: 35% !important;
						right: 0.4rem;
						background-color: rgba(31, 46, 74, 0.88);
						border-radius: 6rem;
						font-size: 1.1rem;
						padding: 0.5rem;
						z-index: 9;
					}
				}
			}
		}
	}
}
.decorations-player {
	position: absolute;
	left: 0;
	top: 0;
	overflow: hidden;
	width: 100%;
	height: calc(100% + 10rem);

	img.shapes {
		position: absolute;
		filter: blur(200px);
		opacity: 0.1;
		max-width: 100vw;
		&#dec_red1 {
			left: 0;
			top: 0;
		}
		&#dec_red2 {
			left: 0;
			top: 0;
		}
		&#dec_pink1 {
			left: 0;
			top: 0;
		}
	}
	&::before {
		content: "";
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 25%;
		opacity: 1;
		background: linear-gradient(0deg, #091629 0%, rgba(7, 17, 32, 0) 100%);
		z-index: 1;
	}
}

@media (max-width: $mobile) {
	.player-page {
		.video-iframe {
			width: 100%;
			margin-right: 2%;
			height: 100%;
			img {
				display: block;
				height: auto;
			}
			iframe {
			}
		}
		.webtv-data {
			display: grid;
			grid-template-columns: auto auto;
			grid-gap: 5px;
			.logo-container {
				grid-row: 1/3;
			}
			.follow {
				grid-column: 2/3
			}
		}
		.video-data {
			.first-row {
				align-items: center;
				h2 {
					font-size: 1.8rem !important;
				}

				.share {
					position: relative;
					cursor: pointer;
					&:hover {
						ul {
							display: block;
						}
					}
					.share-button {
						margin-right: 0;
						height: 100%;
						background: radial-gradient(
										100% 100% at 0% 0%,
										rgba(255, 255, 255, 0.28) 0%,
										rgba(255, 255, 255, 0) 100%
						);
						box-shadow: inset -5px -5px 250px rgba(255, 255, 255, 0.02);
						backdrop-filter: blur(20px);
						border-radius: 102px;
						text-decoration: none;
						color: $white;
						padding: 1rem 2rem;

						border: 1px solid grey;

						display: block;
						p {
							font-size: 1.4rem;
							font-weight: normal;
							display: none;
						}
						svg {
							fill: $white;
							vertical-align: middle;
							margin-right: 0.2rem;
							width: 2rem;
						}
					}

					ul {
						left: -5rem;
						li {
							svg {
								width: 2rem;
								margin-right: 0.2rem;
							}
							&:not(.copy-message) {
								border-bottom: 1px grey solid;
								padding-bottom: 0.5rem;
								margin-bottom: 1rem;
								padding: 0.5rem;
								border-radius: 0.5rem;
								cursor: pointer;
								&:hover {
									background-color: rgba(255, 255, 255, 0.1);
								}
								&:last-of-type {
									margin-bottom: 0;
								}
							}

							&.copy-message {
								left: -2rem;

								width: 17rem;

								top: calc(100% + 0.5rem);
							}
						}
					}
				}
			}
		}

		.playlist {
			width: 100%;
			.videos-container {
				max-height: 65rem;
				display: flex;
				a {
					margin-right: 1.5rem;
				}
			}
		}
	}
}