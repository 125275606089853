.header-clubs {
	position: relative;
	overflow: hidden;
	.cover {
		width: 100vw;
		height: 65rem;
		&::before {
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 25%;
			opacity: 1;
			background: linear-gradient(
				180deg,
				rgb(7, 17, 32) 0%,
				rgba(7, 17, 32, 0) 100%
			);
			z-index: 3;
		}
		.dots {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			z-index: 3;
			img {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				z-index: 3;
			}
		}
		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}
	.header-data {
		position: absolute;
		z-index: 5;
		bottom: 4rem;
		left: 50%;
		transform: translate(-50%, -3rem);
		width: 90%;
		height: 20rem;
		background: radial-gradient(
			100% 100% at 0% 0%,
			rgba(255, 255, 255, 0.4) 0%,
			rgba(255, 255, 255, 0) 100%
		);
		box-shadow: inset -5px -5px 250px rgba(255, 255, 255, 0.02);
		backdrop-filter: blur(20px);
		border-radius: 24px;
		border: 1px solid;

		border-image-source: linear-gradient(
			169.15deg,
			rgba(255, 255, 255, 0.44) 0%,
			rgba(238, 237, 237, 0.22) 96.79%
		);

		display: flex;
		justify-content: space-between;

		.logo-container {
			margin: 0 4rem;
			flex: 20rem;
			position: relative;
			top: -4rem;
			overflow: hidden;
			border-radius: 50%;
			max-height: 20rem;
			max-width: 20rem;
			box-shadow: 0 0 20px rgba(0, 0, 0, 0.616);
			img {
				width: 100%;
				height: 100%;
				object-fit: contain;
			}
		}

		.name-follow {
			flex: 2.5;
			align-self: center;
			h2 {
				font-size: 6rem;
				font-weight: bolder;
				margin-bottom: 3rem;
			}
			svg {
				fill: $white;
				margin-right: 1.5rem;
			}
			p {
				display: inline-block;
				font-size: 2rem;
				margin-right: 3rem;
			}
			button {
				background-color: transparent;
				border: 2px solid grey;
				border-radius: 102px;
				text-decoration: none;
				color: $white;
				padding: 1rem 2rem;
				position: relative;
				transition: all 0.1s linear;
				top: -0.2rem;
				box-sizing: border-box;
				text-transform: capitalize;
				p {
					margin: unset;
					font-size: unset;
				}
				&.follow,
				&:hover {
					background: #2e56e4;
					box-shadow: 0px 2px 2px -2px rgba(34, 38, 50, 0.3);
					border: transparent 2px solid;
					transition: all 0.1s linear;
				}
			}
		}

		.social {
			flex: 1.5;
			align-self: center;
			position: relative;
			ul.icons {
				display: flex;
				justify-content: flex-end;

				a {
					display: block;
					&:last-of-type {
						li {
							margin-right: 7rem;
						}
					}
				}
				li {
					cursor: pointer;
					margin-right: 1.5rem;
					padding: 1rem;
					background: linear-gradient(
						180deg,
						rgba(255, 255, 255, 0.48) 0%,
						rgba(255, 255, 255, 0) 100%
					);
					box-shadow: inset -5px -5px 250px rgba(255, 255, 255, 0.02);
					backdrop-filter: blur(20px);

					border-radius: 50%;

					svg {
						fill: $white;
					}
				}
			}

			.share {
				width: 100%;
				right: 7rem;
				height: 4rem;
				margin-top: 3.5rem;
				position: relative;

				direction: rtl;
				span {
					background: radial-gradient(
						100% 100% at 0% 0%,
						rgba(255, 255, 255, 0.28) 0%,
						rgba(255, 255, 255, 0) 100%
					);
					box-shadow: inset -5px -5px 250px rgba(255, 255, 255, 0.02);
					backdrop-filter: blur(20px);
					border-radius: 102px;
					text-decoration: none;
					color: $white;
					padding: 1rem 2rem;
					font-size: 1.7rem;
					font-weight: normal;
					border: 1px solid grey;
					cursor: pointer;
				}
				ul {
					position: absolute;
					bottom: calc(100% - 7rem);
					background: radial-gradient(100% 100% at 0% 0%, rgba(255, 255, 255, 0.28) 0%, rgba(255, 255, 255, 0) 100%);
					backdrop-filter: blur(400px);
					border-radius: 1rem;
					padding: 1rem 2rem;
					direction: ltr;
				}
			}
		}
	}
}

@media (max-width: $mobile) {
	.header-clubs {
		.header-data {
			min-height: 40rem;
			height: 50vh;

			display: flex;
			flex-direction: column;

			.logo-container {
				flex: 15rem !important;
				top: -4rem !important;
				left: 50%;
				transform: translateX(-50%);
				margin: 0 0 !important;

				max-height: 15rem;
				max-width: 15rem;
				height: 15rem;
				width: 15rem;
			}

			.name-follow {
				margin-top: -6rem;
				flex: unset !important;
				h2 {
					font-size: 3rem;
					text-align: center;
				}

				button {
					margin: 0.5rem auto;
				}
			}

			.social {
				flex: unset !important;
				align-self: inherit !important;

				justify-content: space-evenly;
				ul.icons {
					justify-content: center !important;

					a {
						&:last-of-type {
							li {
								margin-right: 0rem !important;
							}
						}
					}
				}
				.share {
					position: relative;
					direction: ltr;
					margin: 3.5rem auto !important;
					text-align: center;
					right: unset;
					width: 100%;
					ul {
						position: absolute;
						margin-left: auto;
						margin-right: auto;
						left: 0;
						right: 0;
						width: max-content;
						text-align: center;
						background: radial-gradient(100% 100% at 0% 0%, rgba(255, 255, 255, 0.28) 0%, rgba(255, 255, 255, 0) 100%);
						backdrop-filter: blur(400px);
						border-radius: 1rem;
						padding: 1rem 2rem;
					}

				}
			}
		}
	}
}
