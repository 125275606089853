.bo-playlists {
	margin-top: 1rem;
	position: relative;
	.sticky {
		position: absolute;
		top: 3rem;
		right: -1rem;
		width: 0rem;
		.submit {
			width: 20rem;
		}
	}
	.list {
		background-color: rgba(129, 141, 207, 0.274);
		border-radius: 2rem;
		padding: 2rem 0 0.1rem 2rem;

		.search {
			max-width: 30rem !important;

			position: relative;
			label {
				position: absolute;
				top: 0rem;
				left: 0rem;
				width: 100%;
				svg {
					fill: $white;
					width: 1.5rem;
					top: 0.2rem;
					left: 0.4rem;
					position: absolute;

					&.cross {
						cursor: pointer;
						left: unset;
						right: 1.2rem;
						fill: darken(darkgray, 40%);
						z-index: 4;
					}
				}
			}

			input {
				display: block;
				width: 90%;
				height: 0.4rem;

				position: relative;
				font-family: $fontRegular;
				padding: 1.2rem 0rem 1.2rem 2.1rem;
				background-color: lighten(rgba(202, 211, 255, 0.336), 10%);
				border: none;
				border-bottom: 2px solid $white;
				color: $white;
				border-radius: 0.3rem;
				&::placeholder {
					color: rgba(99, 85, 85, 0.6);
				}
			}

			.result {
				opacity: 0.9;
				position: absolute;
				top: 100%;
				z-index: 10;
				background-color: rgb(38, 42, 68);
				width: calc(100% + 0.5rem);
				border-radius: 0 0 1rem 1rem;
				overflow: hidden;
				display: none;
				backdrop-filter: blur(500px);
				&.use {
					display: block;
				}
				ul {
					li {
						padding: 1rem;
						border-bottom: 1px solid black;
						position: relative;
						&:last-of-type {
							border-bottom: unset;
							padding-bottom: 1.1rem;
						}

						svg {
							fill: $red;
							width: 1.5rem;
							top: 50%;
							right: 0.8rem;
							transform: translateY(-50%);
							position: absolute;

							&.added {
								fill: $green;
							}
						}
					}
				}
			}
		}

		.webtvs {
			display: flex;

			.tv {
				margin-right: 1rem;
			}
		}
	}
	.playlists {
		display: flex;
		width: 100%;
		overflow-x: auto;
		overflow-y: hidden;
		margin: 2rem 0;
		max-width: 75vw;
		.playlist {
			margin-right: 1rem;
			margin-bottom: 1rem;
			position: relative;
			min-height: 10em;
			min-width: 200px;
			svg {
				position: absolute;
				top: 0.5rem;
				right: 0.5rem;
				fill: $white;
				z-index: 5;
				cursor: pointer;
				transition: 0.1s all;
				&:hover {
					fill: $red;
					transition: 0.1s all;
				}
			}
			img {
				height: 100%;
				width: 20rem;
				aspect-ratio: 16/9;
				object-fit: cover;
			}
			.title {
				position: absolute;
				bottom: -1rem;
				left: 0;
				width: 100%;

				box-sizing: border-box;
				background-color: $blue;

				h2 {
					font-size: 1.3rem;
					margin-top: 0.4rem;
					margin-bottom: 0.5rem;
					margin-left: 0.6rem;
				}
				H3 {
					font-size: 1.1rem;
					margin-bottom: 0.5rem;
					margin-left: 0.6rem;
				}
			}

			&::after {
				content: attr(data-number);
				position: absolute;
				display: block;
				top: 0.5rem;
				left: 0.5rem;
				width: 0.75rem;
				height: 0.75rem;
				color: $white;
				font-size: 1.2rem;
				line-height: 0.8rem;
				background-color: lighten($blue, 30%);
				padding: 1rem;
				border-radius: 50%;
				z-index: 4;
			}
		}
	}
}
