.bo-sport-page,
.bo-country-page,
.bo-notification {
	p {
		display: block !important;
	}
	header {
		display: flex;
		justify-content: center;
		h1 {
			align-self: center;
			margin-right: 2rem;
			font-size: 2rem;
			text-transform: uppercase;
		}
		> ul {
			display: flex;
			justify-content: center;

			li {
				padding: 1rem;

				border-radius: 1.5rem;
				margin-right: 1.2rem;
				border: 1.5px solid $white;
				cursor: pointer;
				transition: 0.2s all;

				&.selected,
				&:hover {
					transition: 0.2s all;
					border-color: transparent;
					background-color: $blue;
				}
			}
		}
	}
	.img {
		.input {
			display: flex;
		}

		input {
			position: absolute;
			top: -9999rem;
			left: -9999rem;
		}
		label {
			margin-top: 1rem;
			display: block;
			cursor: pointer;
			width: 20rem;
			text-align: center;
			margin-right: 1rem;
			background: transparent;
			padding: 2rem 1rem;
			border: 2px solid $white;
			border-radius: 1.5rem;
			color: $white;
			font-size: 1.5rem;
		}
		p {
			font-style: italic;
			margin-top: 1em;
		}
	}

	.bo-banner {
		.sticky {
			.submit {
				top: 4rem !important;
				right: 4rem;
			}
		}

		.img {
			img {
				border: 0.5rem solid $white;
				border-radius: 0.5rem;
				margin-left: 1.2rem;
			}
		}
	}

	.content {
		width: 100%;
		display: flex;
		h3 {
			font-size: 2rem;
		}
		.notifications {
			margin-top: 2rem;
			margin-left: auto;
			margin-right: auto;
			width: 50%;
			padding: 2rem;
			.row {
				display: flex;
				width: 100%;
				margin-top: 2rem;
				label {
					display: block;
					margin-right: 1rem;
					color: $white;
					width: 30%;
					font-size: 1.5rem;
					margin-top: auto;
					margin-bottom: auto;
				}
			}
			button {
				margin-left: auto;
				background-color: #5983d8;
				border: 1px #ffffff solid;
				border-radius: 2rem;
				padding: 1rem 2rem;
				color: $white;
				&:hover:not([disabled]) {
					background-color: #405f9f;
				}
			}
			.listing {
				ul {
					width: 100%;
					li {
						padding: 1rem 0;
						border-top: 1px solid $white;
						border-bottom: 1px solid $white;
						img {
							padding-left: 0.5rem;
							padding-right: 0.5rem;
							max-height: 80px;
							width: 100px;
						}
					}
				}
			}
		}
	}

}
