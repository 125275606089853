.input {
    position: relative;
    margin-bottom: 2rem;
    ::-webkit-calendar-picker-indicator {
        filter: invert(1);
    }
    label:not(.radio) {
        display: block;
        width: 100%;
        min-width: 25rem;
        min-height: 2.5rem;
        background-color: rgba(202, 211, 255, 0.0676517);
        border-radius: 0.3rem 0.3rem 0 0;
        font-size: 1.1rem;
        box-sizing: border-box;
        padding: 0.8rem 1rem;
    }
    &.radio {
        display: flex;
        margin-bottom: 2rem;
        justify-content: flex-start;
        fieldset {
            padding-right: 8px;
        }
    }
    label.radio {
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        .box {
            cursor: pointer;
            margin-right: 1rem;
            width: 2rem;
            height: 2rem;
            border: 2px solid rgba(82, 110, 255, 1);
            border-radius: 0.4rem;
            position: relative;
            svg {
                @include center(true, true);
                display: none;
            }
        }
    }
    textarea {
        min-height: 10rem !important;
    }
    input, textarea {
        -webkit-appearance: none;
        border-radius: unset;
        display: flex;
        width: 100%;
        box-sizing: border-box;
        min-width: 25rem;
        position: relative;
        font-family: $fontRegular;
        padding: 0.3rem 1rem 0.7rem;
        background-color: rgba(202, 211, 255, 0.0676517);
        border: none;
        border-bottom: 2px solid $white;
        color: $white;
        &::placeholder {
            color: rgba(255, 255, 255, 0.6);
        }

        &[type="radio"] {
            position: absolute;
            top: -9999rem;
            left: -9999rem;
            display: none;
            &:checked + label > .box {
                background-color: rgba(82, 110, 255, 1);
                svg {
                    display: block;
                }
            }
        }
        &.searchBox {
            border-bottom: none;
        }
    }
    .datepicker-toggle-button {
        content: "";
        display: block;
        box-sizing: border-box;
        position: absolute;
        width: 20px;
        height: 20px;
        bottom: .9rem;
        right: 10px;
        background: transparent url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTkiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTUgMHYxaDhWMGgxdjFoMmEyIDIgMCAwMTIgMnYxNGEyIDIgMCAwMS0yIDJIMmEyIDIgMCAwMS0yLTJWM2EyIDIgMCAwMTItMmgyVjBoMXptMTIgN0gxdjEwYTEgMSAwIDAwMSAxaDE0YTEgMSAwIDAwMS0xVjd6TTQgMTZ2MUgydi0xaDJ6bTMgMHYxSDV2LTFoMnptMyAwdjFIOHYtMWgyem0tNi0ydjFIMnYtMWgyem0zIDB2MUg1di0xaDJ6bTMgMHYxSDh2LTFoMnptMyAwdjFoLTJ2LTFoMnptMyAwdjFoLTJ2LTFoMnpNNCAxMnYxSDJ2LTFoMnptMyAwdjFINXYtMWgyem0zIDB2MUg4di0xaDJ6bTMgMHYxaC0ydi0xaDJ6bTMgMHYxaC0ydi0xaDJ6TTQgMTB2MUgydi0xaDJ6bTMgMHYxSDV2LTFoMnptMyAwdjFIOHYtMWgyem0zIDB2MWgtMnYtMWgyem0zIDB2MWgtMnYtMWgyek03IDh2MUg1VjhoMnptMyAwdjFIOFY4aDJ6bTMgMHYxaC0yVjhoMnptMyAwdjFoLTJWOGgyek00IDJIMmExIDEgMCAwMC0xIDF2M2gxNlYzYTEgMSAwIDAwLTEtMWgtMnYzaC0xVjJINXYzSDRWMnoiIGZpbGw9IiNBMEFFQzAiIGZpbGwtcnVsZT0iZXZlbm9kZCIvPjwvc3ZnPg==') no-repeat center center;
        z-index: 1;
        &:hover {
            cursor: pointer;
        }
    }
    input[type="date"]::-webkit-calendar-picker-indicator {
        opacity: 0
    }
    &.select {
        label,
        input {
            color: $white !important;
            background-color: rgba(202, 211, 255, 0.24) !important;
        }
    }

    .errorDisplay {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 1rem;
        color: $red;

        font-size: 1.3rem;
        font-weight: bolder;
        text-transform: uppercase;
        animation: wiggleSmall 0.1s linear 3;
    }

    &.error::before {
        content: " ";
        display: block;
        position: absolute;
        left: -1.5rem;
        height: calc(100%);
        width: 0.3rem;
        border-radius: 0.2rem;
        background-color: $red;

        animation: wiggle 0.1s linear 4;
    }

    select {
        width: 100%;
        padding: 0.4rem 0.5rem 0.5rem;
        border-radius: 0 0 0.3rem 0.3rem;
        background-color: rgba(202, 211, 255, 0.0676517);
        border: none;
        border-bottom: 2px solid #ffffff;
        color: #ffffff;
        font-family: "Fira Sans", sans-serif;
    }
}