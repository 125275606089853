.dashboard {
	display: flex;

	.nav {
		position: relative;
		width: 15rem;
		min-height: calc(100vh - 6rem);
		background: radial-gradient(
			100% 100% at 0% 0%,
			rgba(255, 255, 255, 0.41) 0%,
			rgba(255, 255, 255, 0) 100%
		);
		box-shadow: inset -5px -5px 250px rgba(255, 255, 255, 0.02);
		backdrop-filter: blur(42px);

		padding: 3rem;
		h1 {
			font-size: 3rem;
			font-weight: bolder;
			margin-bottom: 2rem;
		}

		nav {
			grid-column: 1/2;

			ul {
				li {
					margin-bottom: 2rem;
					cursor: pointer;
					position: relative;
					&.selected::before {
						content: "";
						display: block;
						position: absolute;
						top: -0.5rem;
						left: -3rem;
						border-radius: 0 1rem 1rem 0;
						width: calc(100% + 2rem);
						height: calc(100% + 1rem);
						background: radial-gradient(
							100% 100% at 0% 0%,
							rgba(255, 255, 255, 0.41) 0%,
							rgba(255, 255, 255, 0) 100%
						);
						box-shadow: inset -5px -5px 250px rgba(255, 255, 255, 0.02);
						backdrop-filter: blur(42px);
						z-index: -1;
					}

					a {
						color: $white;
						text-decoration: none;
					}
				}
			}
		}

		.logo {
			position: absolute;
			bottom: 2rem;
			width: 80%;
			left: 50%;
			transform: translateX(-50%);
			img {
				width: 100%;
				height: 100%;
			}
			a {
				color: $white;
				text-decoration: none;
			}
			h2 {
				text-align: center;
				margin-top: 1rem;
			}
		}
	}

	.window {
		padding: 7rem 0 1rem 0;
		width: 100%;
		@import "./homepage/homepage";
		@import "./alternate_page/alternate_page";
		@import "./translations/translations";
		@import "./subscription/subscription";
		@import "./subscription/add-subscription";
		@import "./account/edit-account";
		@import "./admin";
	}
}
