.bo-status {
	.sticky {
		.submit {
			top: 0;
			right: 3rem;
		}
	}
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	.controls {
		.italic {
			font-style: italic;
		}
		.bold {
			font-weight: bolder;
		}
		.underline {
			text-decoration: underline;
		}
	}
	.DraftEditor-root {
		cursor: text;
		padding: 0.5rem;
		margin-top: 1rem;
		border-radius: 1rem;
		background-color: $white;
		color: black;
		min-width: 25vw;
		li {
			margin-bottom: 1rem;
		}
	}
}
