#contact-form {
	visibility: hidden;
	&.display {
		visibility: visible;
		transform: translate(-50%, -50%);
		transition: all 0.2s linear;
		opacity: 1;
	}
	min-width: 45vw;
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -35%);
	transition: all 0.2s;
	opacity: 0;
	z-index: 10;
	background: radial-gradient(
		100% 100% at 0% 0%,
		rgba(255, 255, 255, 0.41) 0%,
		rgba(255, 255, 255, 0) 100%
	);
	box-shadow: inset -5px -5px 250px rgba(255, 255, 255, 0.02);
	backdrop-filter: blur(42px);
	border-radius: 1rem;
	padding: 2rem 4rem 2rem 2rem;

	.title {
		white-space: nowrap;
		position: relative;
		width: 20rem;
		z-index: 5;
		margin: auto;

		&:before,
		&:after {
			content: "";
			background: white;
			height: 1px;
			width: 5rem;
			position: absolute;
		}
		&:before {
			top: -0.65rem;
			right: 6rem;
		}
		&:after {
			bottom: -0.65rem;
			left: 1rem;
		}

		h3.decor {
			backdrop-filter: blur(4px);
			border: 1px solid rgba(255, 255, 255, 0.6);
			display: inline-block;
			padding: 1em 2em;
			transform: skewX(195deg);
			position: relative;

			span {
				font-weight: 900;
				font-size: 2rem;
				text-transform: uppercase;
				transform: skewX(-195deg);
				display: block;
				position: relative;
			}
			&:before,
			&:after {
				content: "";
				background: white;
				height: 1px;
				width: 25px;
				position: absolute;
			}
			&:before {
				top: -0.8rem;
				right: 0;
			}
			&:after {
				bottom: -0.7rem;
				left: 6rem;
			}
		}
	}
	form {
		margin: 3rem 2rem 2rem;
		.input {
			width: 70%;
			margin: 1.6rem auto;
		}

		input[type="submit"] {
			display: block;
			width: 20rem;
			background-color: rgba(46, 86, 228, 1);
			padding: 1.5rem;
			border: none;
			border-radius: 1.5rem;
			color: $white;
			margin: auto;
		}
	}
	.cross {
		position: absolute;
		top: 1rem;
		right: 1rem;
		margin: 1rem;
		cursor: pointer;
		height: 3rem;
		width: 3rem;
		background: radial-gradient(
			100% 100% at 0% 0%,
			rgba(255, 255, 255, 0.28) 0%,
			rgba(255, 255, 255, 0) 100%
		);
		box-shadow: inset -5px -5px 250px rgba(255, 255, 255, 0.02);
		backdrop-filter: blur(20px);
		border-radius: 50%;
		border: 1px solid grey;

		svg {
			width: 1.5rem;
			@include center(true, true);
			fill: $white;
		}
	}
}
@media (max-width: $mobile) {
	#contact-form {
		max-width: 75vw;
		.title {
			width: 50% !important;
		}
		form {
			margin: 0 !important;
			.input {
				margin: 1rem !important;
			}
			select {
				width: calc(100% + 4rem) !important;
				min-width: 25rem !important;
			}
		}
	}
}
