//COLORS

$background: #091629;
$white: #ffffff;
$red: #ef2f2a;
$green: rgba(33, 173, 86, 1);
$blue: rgb(19, 40, 82);

$scroll: rgb(32, 32, 32);
//FONTS

@import url("https://fonts.googleapis.com/css2?family=Fira+Sans:ital,wght@0,300;0,400;0,500;1,400&display=swap");
$fontRegular: "Fira Sans", sans-serif;

//ANIMATION

$fade: 0.04s;

//MEDIA QUERY
$mobile: 1000px;
