.under-menu {
	display: flex;
	flex-wrap: wrap;
	max-width: 90%;
	margin: auto;
	.sports, .country {
		width: 50%;
		h3 {
			font-size: 2rem;
			text-transform: uppercase;
			margin-bottom: 1rem;
		}
	}
	.sports {
		img {
			width: 50px;
			margin-bottom: 10px !important;
		}
	}
	.country {
		img {
			width: 65px;
			margin-bottom: 5px !important;
		}
	}
	ul {
		display: flex;
		li {
			&:first-child {
				margin-right: 20px;
			}
			&:not(:first-of-type) {
				margin: 0 20px;
			}
		}
		.item-menu {
			text-align: center;
			display: grid;
			img {
				margin: auto;
				height: auto;
			}
			span {
				text-transform: uppercase;
				font-size: 1.5rem;
				color: white;
			}
		}
	}
}
@media (max-width: $mobile) {
	.under-menu {
		.sports, .country {
			width: 100%;
		}
		.country {
			margin-top: 2rem;
		}
	}
}