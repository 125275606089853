.filter {
	.flex-between {
		display: flex;
		justify-content: space-between;
		width: 100%;
		align-items: center;

		.event-submenu {
			padding-bottom: 8rem;
		}

		.sport-menu {
			margin: auto 0;
			a,
			.link {
				border-bottom: 1px solid rgba(205, 222, 222, 0.5);
				padding-bottom: 2rem;
				opacity: 0.7;
				font-weight: 300;
				font-size: 17px;
				line-height: 24px;
				text-decoration: none;
				color: white;
				&:hover {
					cursor: pointer;
				}
				&.active {
					position: relative;
					border-bottom: 1px solid rgba(205, 222, 222, 0.35);
					&:before {
						content: "";
						position: absolute;
						left: 0;
						bottom: -1.5px;
						height: 1px;
						border-bottom: 2px solid rgba(239, 47, 42, 1);
					}
					font-weight: 400;
					opacity: 1;
				}
				&:last-child {
					&:before {
						width: 100%;
					}
				}
				&:not(:last-child) {
					padding-right: 4rem;
					&:before {
						width: calc(100% - 4rem);
					}
				}
			}
		}
		.sport-filter {
			margin: auto;
			span {
				opacity: 0.7;
				font-weight: 300;
				font-size: 17px;
				line-height: 24px;
				margin-right: 2.5rem;
			}
			.dropbtn {
				position: relative;
				background: radial-gradient(
					90.38% 100% at 5.77% 0%,
					rgba(0, 0, 0, 0.32) 0%,
					rgba(255, 255, 255, 0) 100%
				);
				box-shadow: -5px -5px 250px 0px rgba(255, 255, 255, 0.02) inset;
				backdrop-filter: blur(9px);
				border: 1px solid rgba(255, 255, 255, 0.9);
				border-radius: 0.8rem;
				font-weight: 400;
				font-size: 16px;
				color: #ffffff;
				padding: 1.2rem 2rem 1.2rem 1.5rem;
				text-shadow: 0 4px 9px rgba(255, 255, 255, 0.2);
				text-transform: capitalize;
				&.open {
					border-radius: 0.8rem 0.8rem 0 0;
				}
				&:after {
					content: '';
					position: absolute;
					left: 89%;
					top: 41%;
					width: 0;
					height: 0;
					border-left: 4px solid transparent;
					border-right: 4px solid transparent;
					border-top: 9px solid #e8e8e8;
					clear: both;
				}
			}
			.dropdown {
				position: relative;
				display: inline-block;
				width: 150px;
				&:not(:last-child) {
					margin-right: 1rem;
				}
				button {
					overflow: hidden;
					text-overflow: ellipsis;
					white-space: nowrap;
					width: 100%;
					text-align: left;
				}
			}

			.dropdown-content {
				max-height: 30rem;
				overflow: auto;
				position: absolute;
				width: calc(100% - 1.5px);
				z-index: 1;
				background: radial-gradient(
					90.38% 100% at 5.77% 0%,
					rgba(0, 0, 0, 0.32) 0%,
					rgba(255, 255, 255, 0) 100%
				);
				box-shadow: -5px -5px 250px 0px rgba(255, 255, 255, 0.02) inset;

				backdrop-filter: blur(9px);

				@supports not ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
					background-color: rgba(36, 26, 46, 0.946);
				}

				border-left: 1px solid rgba(255, 255, 255, 0.774);
				border-right: 1px solid rgba(255, 255, 255, 0.6);
				border-bottom: 1px solid rgba(255, 255, 255, 0.6);
				border-radius: 0 0 0.8rem 0.8rem;
				a {
					text-decoration: none;
					color: white;
					display: block;
					&:not(:last-child) {
						border-bottom: 1px solid rgba(255, 255, 255, 0.6);
					}
				}
				li {
					padding: 1.5rem;
					&:hover {
						cursor: pointer;
					}
					&:not(:last-child) {
						border-bottom: 1px solid rgba(255, 255, 255, 0.6);
					}
				}
			}

			.dropdown:hover .dropdown-content {
				display: block;
			}
		}
	}
}

@media (max-width: $mobile) {
	.flex-between {
		display: block !important;

		.sport-filter {
			display: grid;
			grid-template-columns: auto auto auto;
			grid-gap: 2%;
			margin-top: 1.5rem !important;
			.dropdown {
				width: 100% !important;
			}

			span {
				margin: 0 0 2rem !important;
			}
		}
	}
}
@media (max-width: 500px) {
	.flex-between {
		display: block !important;

		.sport-menu {
			margin: 0 auto 1rem !important;
			display: flex;
			overflow-x: auto;
			&::-webkit-scrollbar-thumb {
				display: none;
			}
			&::-webkit-scrollbar {
				height: 0;
				width: 0;
				display: none;
			}
			a {
				flex: 1;
				text-align: center;
			}
		}
		.event-submenu {
			padding-bottom: 1rem !important;
		}
		.sport-filter {
			display: grid;
			grid-template-columns: 49% 49%;
			grid-gap: 2%;
			span {
				margin: 0 0 2rem !important;
			}
			.dropdown {
				margin-top: 1.5rem;
				margin-bottom: 1rem;
			}
			.dropdown {
				width: 100% !important;
			}
		}
	}
}