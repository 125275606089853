@import "./reset.css";

@import "./settings";
@import "./functions";

html {
	font-size: 62.5%;
	@media (max-width: $mobile) {
		overflow-x: hidden !important;
	}
}

body {
	//FONT
	font-size: 1.4rem;
	background-color: $background;
	color: $white;
	font-family: $fontRegular;

	.App {
		//IMPORT HEADER
		@import "./components/header";

		//PAGES
		@import "./pages/explorer";
		@import "./pages/sports";
		@import "./pages/club";
		@import "./pages/player";
		@import "./pages/account";
		@import "pages/profile";
		@import "pages/loader";

		//COMPONENTS
		@import "./components/components";

		//FOOTER
		@import "./components/footer";

		@import "./components/redirect_app";

		//ANIMATION
		@import "./animation";

		//CAROUSEL
		@import "../../node_modules/slick-carousel/slick/slick.css";
		@import "../../node_modules/slick-carousel/slick/slick-theme.css";

		//DASHBOARD
		@import "./pages/back-office";

		//IFRAME
		@import "./pages/iframe";
	}
}

hr {
	width: 100%;
}
//CLASSES

.hidden {
	display: none;
}

.container {
	width: 90%;
	margin: auto;
	position: relative;
}

.position-absolute {
	position: absolute;
}

.simplebar-scrollbar:before {
	background-color: $white;
}

.slick-track {
	display: flex;
}

.d-none {
	display: none;
}

p {
	display: inline-block;
}
.count-followers {
	font-size: 2rem;
	margin: 0 4px;
}

//datapeacker styles start
.react-datepicker {
	position: absolute !important;
	width: 20rem;
	margin: 5px auto 0;
	font-family: $fontRegular;
	z-index: 9999;

	.react-datepicker__month-container {
		width: 100%;
	}
	.react-datepicker__current-month {
		font-size: 1.5rem;
		color: #17212e;
		font-family: $fontRegular;
	}
	.react-datepicker__month-dropdown-container,
	.react-datepicker__year-dropdown-container {
		width: 47%;
	}

	.react-datepicker__month-select,
	.react-datepicker__year-select {
		width: 100% !important;
		background-color: #697083 !important;
		font-size: 1.6em !important;
		color: white !important;
	}


	.react-datepicker__header__dropdown {
		margin: 1rem 0;
	}

	.react-datepicker__day-name,
	.react-datepicker__day,
	.react-datepicker__time-name {
		width: 12.3%;
		font-size: 1.3rem;
		font-family: $fontRegular;
	}
}
.bg-danger {
	background-color: red !important;
}

.bg-success {
	background-color: green !important;
}
.bottom-right {
    bottom: 0;
    right: 0;
}
.top-left {
    top: 0;
    left: 0;
}
.position-fixed {
    position: fixed;
}
.absolute-center {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);
}

.modal {
        background: #f9fafb;
        border-radius: 5px;
        z-index: 999;
}
.add-payment {
	font-family: "Gotham",sans-serif;
	font-size: 1.8rem;
	color: rgba(0, 0, 0, 0.51);
	font-weight: 500;
}

.float-right {
	float: right;
}
.mw-25 {
	max-width: 25%;
}
.m-auto {
	margin: auto !important;
}
.my-auto {
	margin-top: auto !important;
	margin-bottom: auto !important;
}
.mx-1 {
	margin-left: .5rem;
	margin-right: .5rem;
}
.mx-2 {
	margin-left: 1rem;
	margin-right: 1rem;
}
.mx-3 {
	margin-left: 1.5rem;
	margin-right: 1.5rem;
}
.mx-4 {
	margin-left: 2rem;
	margin-right: 2rem;
}
.m-0 {
	margin: 0 !important;
}
.mr-1 {
	margin-right: 0.5rem;
}
.mr-2 {
	margin-right: 1rem;
}
.mr-3{
	margin-right: 1.5rem;
}
.mr-4{
	margin-right: 2rem;
}
.ml-0 {
	margin-left: 0 !important;
}
.ml-1 {
	margin-left: 0.5rem;
}
.ml-2 {
	margin-left: 1rem;
}
.ml-3{
	margin-left: 1.5rem;
}
.ml-4{
	margin-left: 2rem;
}
.ml-7{
	margin-left: 3.5rem;
}
.mt-0 {
	margin-top: 0 !important;
}
.mt-1 {
	margin-top: .5rem;
}
.mt-2 {
	margin-top: 1rem;
}
.mt-3 {
	margin-top: 1.5rem;
}
.mt-4 {
	margin-top: 2rem;
}
.mb-0 {
	margin-bottom: 0 !important;
}
.mb-1 {
	margin-bottom: .5rem;
}
.mb-2 {
	margin-bottom: 1rem;
}
.mb-3 {
	margin-bottom: 1.5rem;
}
.mb-4 {
	margin-bottom: 2rem;
}
.mb-5 {
	margin-bottom: 2.5rem;
}
.my-0 {
	margin-left: 0 !important;
	margin-right: 0 !important;
}
.my-1 {
	margin-top: .5rem;
	margin-bottom: .5rem;
}
.my-2 {
	margin-top: 1rem;
	margin-bottom: 1rem;
}
.my-3 {
	margin-top: 1.5rem;
	margin-bottom: 1.5rem;
}
.my-4 {
	margin-top: 2rem;
	margin-bottom: 2rem;
}
.p-1 {
	padding: .5rem;
}
.p-2 {
	padding: 1rem;
}
.p-3 {
	padding: 1.5rem;
}
.p-4 {
	padding: 2rem;
}
.pl-0 {
	padding-left: 0 !important;
}
.pb-4 {
	padding-bottom: 2rem ;
}
.lh-6 {
	line-height: 3rem ;
}
.w-100 {
	width: 100% !important;
}
.h-100 {
	height: 100% !important;
}
.d-flex {
	display: flex;
	flex-wrap: wrap;
}
.d-grid {
	display: grid;
}
.justify-content-start {
	justify-content: flex-start;
}
.justify-content-center {
	justify-content: center;
}
.justify-content-space-between {
	justify-content: space-between;
}
.no-wrap {
	white-space: nowrap;
}

.simplebar-scrollbar:before {
	background-color: $white;
}
.white{
	color: #FFFF;
}
.blue{
	color:#0D1D34;
}
.black {
	color: #0000;
}
.grey {
	color: #667085 !important;
}
.red {
	color: red;
}
.bold {
	font-weight: bold;
}
.flex-between {
	display: flex;
	justify-content: space-between;
}

.success-animation {
	// Define vars we'll be using
	$brand-success: #5cb85c;
	$loader-size: 7em;
	$check-height: $loader-size/2;
	$check-width: $check-height/2;
	$check-left: ($loader-size/6 + $loader-size/12);
	$check-thickness: 3px;
	$check-color: $brand-success;

	height: 100%;
	width: 100%;
	position: absolute;
	top: 0;
	background-color: #0F347F;
	z-index: 9;

	.loader {
		@include center(true, true);
	}

	.circle-loader {
		border: 3px solid rgba(0, 0, 0, 0);
		border-left-color: $check-color;
		animation: loader-spin 1.2s infinite linear;
		transform-origin: center;
		display: inline-block;
		border-radius: 50%;
		width: $loader-size;
		height: $loader-size;
		margin: auto;
	}

	.load-complete {
		-webkit-animation: none;
		animation: none;
		border-color: $check-color;
		transition: border 500ms ease-out;
		.checkmark {
			display: block !important;
		}
	}

	.checkmark {
		display: none;

		&.draw:after {
			animation-duration: 800ms;
			animation-timing-function: ease;
			animation-name: checkmark;
			transform: scaleX(-1) rotate(135deg);
		}

		&:after {
			opacity: 1;
			height: $check-height;
			width: $check-width;
			transform-origin: left top;
			border-right: $check-thickness solid $check-color;
			border-top: $check-thickness solid $check-color;
			content: '';
			left: $check-left;
			top: $check-height;
			position: absolute;
		}
	}

	@keyframes loader-spin {
		0% {
			transform: rotate(0deg);
		}
		100% {
			transform: rotate(360deg);
		}
	}

	@keyframes checkmark {
		0% {
			height: 0;
			width: 0;
			opacity: 1;
		}
		20% {
			height: 0;
			width: $check-width;
			opacity: 1;
		}
		40% {
			height: $check-height;
			width: $check-width;
			opacity: 1;
		}
		100% {
			height: $check-height;
			width: $check-width;
			opacity: 1;
		}
	}
}
.btn {
	text-decoration: none;
	color: #ffffff;
	padding: 1rem;
	border-radius: 1.5rem;
	cursor: pointer;
	&.btn-red {
		background-color: #EF2F2A;
	}
	&.btn-transparent {
		background: transparent;
		border: 1px solid #ffffff;
	}
 }
.btn-stripe {
	border: none;
	border-radius: 8px;
	color: #ffffff;
	padding: 1rem 2rem;

	&.small {
		font-size: small;
	}
}
.scroll-wrapper {
	overflow-y: scroll;
}
#validUpdate {
	vertical-align: middle;
	border: 1px solid $green;
	margin-left: 2rem;
	margin-right: 2rem;
	padding: 1rem;
	border-radius: 1rem;
	text-align: center;
	opacity: 0;
	transition: all 0.1s linear;
	position: relative;
	&.error {
		border: 1px solid $red;
	}
	&.display {
		animation: wiggleSmall 0.1s 4;
		opacity: 1;
		transition: all 0.1s linear;
	}
}
.background {
	z-index: 5;
	position: absolute;
	width: 100%;
	height: 100%;
	background-size: cover;
	mix-blend-mode: multiply;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background: #00000073 no-repeat center;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
	-webkit-transition: "color 9999s ease-out, background-color 9999s ease-out";
	-webkit-transition-delay: 9999s;
}
.cursor-pointer {
	cursor: pointer;
}