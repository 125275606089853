.bo-edit-slide {
	margin-top: 2rem;
	display: grid;
	grid-template-columns: 33rem auto;

	.slides-container {
		max-height: 80vh;
		overflow-y: auto;
		background-color: rgba(129, 141, 207, 0.274);
		border-radius: 2rem;
		padding: 1rem;
		.slide {
			z-index: 20;
			position: relative;
			overflow: hidden;
			border-radius: 1rem;
			&.selected,
			&:hover {
				&::after {
					content: "";
					display: block;
					position: absolute;
					top: 0;
					left: 0;
					width: calc(100%);
					height: calc(100% - 1rem);
					background-color: rgba(92, 81, 155, 0.247);
				}
				&::before {
					content: " ";
					width: 4rem;
					height: 4rem;
					@include center(true, true);
					z-index: 20;
					mask: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBkPSJNMTQuMDc4IDQuMjMybC0xMi42NCAxMi42MzktMS40MzggNy4xMjkgNy4xMjctMS40MzggMTIuNjQxLTEyLjY0LTUuNjktNS42OXptLTEwLjM2OSAxNC44OTNsLS44NS0uODUgMTEuMTQxLTExLjEyNS44NDkuODQ5LTExLjE0IDExLjEyNnptMi4wMDggMi4wMDhsLS44NS0uODUgMTEuMTQxLTExLjEyNS44NS44NS0xMS4xNDEgMTEuMTI1em0xOC4yODMtMTUuNDQ0bC0yLjgxNiAyLjgxOC01LjY5MS01LjY5MSAyLjgxNi0yLjgxNiA1LjY5MSA1LjY4OXoiLz48L3N2Zz4=");
					mask-size: contain;
					mask-repeat: no-repeat;
					background-color: $white;
				}
			}
			margin: auto 1rem 1rem;
		}
	}
}
