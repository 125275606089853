#avatar, #id_card {
	display: flex;
	align-items: flex-start;
	img {
		width: 10rem;
		height: 100%;
		aspect-ratio: 1/1;
		object-fit: cover;
		border-radius: 50%;
		overflow: hidden;

	}
	.data {
		margin-left: 2rem;
		h4 {
			font-weight: bold;
			margin-bottom: 1rem;
		}

		p {
			margin-bottom: 2rem;
		}
		form {
			display: flex;
			align-items: center;
			input {
				position: absolute;
				top: -9999rem;
				left: -9999rem;
			}
			label {
				vertical-align: middle;
				cursor: pointer;
				background: transparent;
				padding: 1rem;
				border: 2px solid $white;
				border-radius: 1.5rem;
				color: $white;
			}
			p {
				margin: 0;
			}
		}
	}
}
