.to_app {
    padding: 1rem;
    top: 0;
    left: 0;
    right: 0;
    background: rgb(255, 255, 255) 93%;
    z-index: 9999;
    position: fixed;
    font-size: 1.3rem;
    display: flex;
    justify-content: space-between;

    img {
        height: 40px;
        width: auto;
        margin-top: auto;
        margin-bottom: auto;
    }

    .redirect-image {
        display: grid;
        grid-template-columns: auto auto;
        grid-gap: 10px;

        p {
            margin-top: auto;
            margin-bottom: auto;
            color: #d13635;
        }
    }

    .redirect-button {
        display: flex;
        margin-top: auto;
        margin-bottom: auto;

        a, span {
            &.close {
                color: #a5a5a5;
                border: 1px solid #a5a5a5;
            }
            color: #d13635;
            width: 6rem;
            height: 3rem;
            border-radius: 6rem;
            text-align: center;
            line-height: 3rem;
            margin-right: 1rem;
            border: 1px solid #d13635;
        }
    }
}