.playlist-videos {
	width: 100%;
	margin: 3rem auto;

	.playlist-slider {
		margin-top: 2rem;
		max-width: 95%;
		margin-left: auto;
		overflow: inherit;
		display: flow-root;
	}
	.title-thumbnail {
		float: left;
		position: relative;
		.bg-block {
			position: absolute;
			text-transform: uppercase;
			color: $white;
			align-items: center;
			justify-content: center;
			text-align: center;
			top: 50%;
			left: 0;
			right: 0;
			transform: translateY(-50%);
			padding: 30px;
			.svg-icon {
				max-height: 10rem;
				margin-top: 1rem;
				margin-bottom: 2rem;
			}
			h2 {
				margin-bottom: 2rem;
				display: flex;
				justify-content: center;
				align-items: center;
				.h2 {
					font-size: 6rem;
					font-weight: bold;
					display: flex;
					h6 {
						font-size: 4rem;
						line-height: 4.4rem;
						padding-left: 14px;
						font-weight: 500;
					}
				}
				.h3 {
					font-size: 5rem;
				}
				.play {
					border-radius: 50%;
					width: 3.5rem;
					height: 3.5rem;
					display: flex;
					background: rgba(255, 255, 255, 0.31);
					border: 4px solid #201748;
					justify-content: center;
					align-items: center;
				}
			}
			span {
				font-weight: bold;
				font-size: 4rem;
				width: 90%;
				display: block;
				margin: auto;
				transform:skew(-10deg);
			}
		}
		.emission {
			background: white;
		}
		.img-bg {
			object-fit: cover;
			height: 100%;
			margin: 0 10px 0 0;
			border-radius: 5px;
		}
	}

	.titles {
		width: 90%;
		margin: auto;
		display: flex;
		align-items: center;
		justify-content: space-between;
		text-transform: uppercase;
		a {
			color: $white;
			text-decoration: none;
		}
		h3 {
			font-size: 2rem;
		}
		h5 {
			cursor: pointer;
			font-size: 1.2rem;
			svg {
				vertical-align: middle;
				height: 1.1rem;
				fill: $white;
			}
		}
	}

	.videos-container {
		position: relative;
		&::after {
			content: "";
			display: block;
			background: linear-gradient(
				270.21deg,
				rgb(9, 22, 41) 10%,
				rgba(9, 22, 41, 0) 99.82%
			);
			width: 5%;
			height: 100%;
			position: absolute;
			top: 0;
			right: 0;
			z-index: 5;
		}
		.arrow-slideshow {
			background-color: #36598d;
			border-radius: 2rem;
			padding: 2rem 1.2rem;
			border: none;
			position: absolute;
			transform: translateY(-50%) scale(100%);
			top: 35%;
			left: -5rem;
			z-index: 6;
			transition: all 0.3s linear;
			&:hover {
				transform: translateY(-50%) scale(102%);
				transition: all 0.3s linear;
				background-color: #4168a8;
				svg {
					transition: all 0.3s linear;
					transform: scale(98%);
				}
			}
			svg {
				fill: $white;
				width: 1.5rem;
			}
			&.right {
				left: unset;
				right: 2rem;
			}
			&.disabled {
				svg {
					opacity: 0.3;
				}
			}
		}
		.slick-track {
			margin-left: 0;
		}
		.slick-slide {
			margin-right: 1rem;
		}
		.video {
			position: relative;
			width: 100%;

			.video-restrited:hover {
				cursor: pointer;
				.video-restricted-overlay {
					visibility: visible;
					opacity: 1;
				}
			}
			.video-restricted-overlay {
				transition: all 0.3s;
				display: flex;
				position: absolute;
				opacity: 0;
				visibility: hidden;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				background-color: rgba(199, 199, 199, 0.91);
    			align-items: center;
    			justify-content: space-between;
    			flex-direction: column;
    			flex-wrap: wrap;
    			align-content: stretch;
				z-index: 999;
			}

			.padlock-icon {
				transform: scale(1.3);
				margin-top: 3rem;
			}
			  
			.video-restricted-text {
				font-size: 1.3rem;
				color: black;
				text-align: center;
				margin: 0 2rem 0 2rem;
			}
			  
			  .video-restricted-link {
				font-size: 1.1rem;
				text-transform: uppercase;
				margin-left: 1rem;
				margin-right: 1rem;
				margin-bottom: 1.5rem;
				border: solid 1px #000;
				color: white;
				background-color:$background;
				border-radius: 2rem;
				padding: 1rem 1.5rem;
				box-sizing: border-box;
			}	
			
			.video_img_block {
				position: relative;
				> img {
					width: 100%;
					object-fit: cover;
					height: unset;
				}
			}

			a, .video-restrited {
				color: white;
				text-decoration: none;

				.video_img_block {
					position: relative;
					> img {
						width: 100%;
						object-fit: cover;
						height: unset;
					}

					.logo {
						position: absolute;
						top: 1rem;
						right: 1rem;
						width: 5rem;
						height: 5rem;
					}

					span {
						position: absolute;
						bottom: 1rem;
						right: 1.5rem;
						background-color: rgba(31, 46, 74, 0.88);
						border-radius: 6rem;
						font-size: 1.1rem;
						padding: 0.5rem;
					}

				}
				.title-container {
					margin-top: 2px;
					position: relative;
					display: flex;
					flex-direction: column;

					h4 {
						width: 100%;
						position: relative;
						z-index: 1;
						font-size: large;
					}
				}
					
			}
			margin-right: 2rem;
		}

		
	}
}

@media (max-width: $mobile) {
	.playlist-videos {
		.title-thumbnail {
			display: none;
		}
		.videos-container {
			.title-container {
				p {
					font-size: 1.2rem;
				}
				h4 {
					font-size: 1.4rem !important;
				}
			}
			.arrow-slideshow {
				top: 50%;

				&.left {
					left: 1rem;
					right: unset;
				}
			}
		}
	}
}
