.search-container {
	position: fixed;
	top: 0;
	left: 0;
	background-color: rgba(7, 17, 32, 0.96);
	width: 100%;
	height: 100%;
	min-height: 100vh;
	z-index: 100;
	isolation: isolate !important;
	.search {
		height: 100%;
		max-height: 100vh;
		overflow-y: auto;

		.search-section {
			display: flex;
			align-items: center;

			width: 100%;
			margin-bottom: -3rem;
			h4 {
				display: none;
			}
			.button {
				position: absolute;
				right: 0;
				top: 0;
				bottom: 0;
				height: auto;
				width: auto;
				padding: 1rem 2rem 1rem 2rem;
				border-radius: 0 4rem 4rem 0;
				color: $white;
				font-size: 2.2rem;
				cursor: pointer;
				background: radial-gradient(
					100% 100% at 80% -20%,
					rgba(255, 255, 255, 0.28) 0%,
					rgba(255, 255, 255, 0) 100%
				);
			}
			.close {
				margin: auto 8rem;
				width: 2rem;
				height: 2rem;
				background: radial-gradient(
					100% 100% at 0% 0%,
					rgba(255, 255, 255, 0.28) 0%,
					rgba(255, 255, 255, 0) 100%
				);
				box-shadow: inset -5px -5px 250px rgba(255, 255, 255, 0.02);
				backdrop-filter: blur(20px);
                cursor: pointer;
				border-radius: 50%;
				padding: 1rem;
				border: 1px solid rgba(255, 255, 255, 0.34);
				svg {
					fill: white;
					width: 1.5rem;
					position: relative;
					top: -0.2rem;
					left: 0.125rem;
					padding-right: 3px;
					padding-left: 1px;
				}
			}
			form {
				width: 100%;
				display: flex;
				position: relative;
				left: 10rem;
				span {
					width: 50%;
					margin: 6rem auto;
					display: flex;
					height: fit-content;
					position: relative;
					input {
						width: 100%;
						height: 4rem;
						padding: 1rem 6rem 1rem 6rem;
						background: radial-gradient(
							100% 100% at 0% 0%,
							rgba(255, 255, 255, 0.41) 0%,
							rgba(255, 255, 255, 0) 100%
						);
						box-shadow: inset -5px -5px 250px rgba(255, 255, 255, 0.02);
						backdrop-filter: blur(42px);
						border-radius: 4rem;
						color: $white;
						border: 1.5px solid #3e4551;
						font-size: 2rem;
					}
					svg {
						width: 3rem;
						bottom: 2rem;
						left: 2rem;
						fill: #fff;
						position: absolute;
						z-index: 9;
					}
				}
			}
		}

		.results {
			> span {
				width: 100%;
				text-align: center;
			}
			.popular-event {
				display: none;
				width: 50%;
				margin: auto;
				> .title {
					text-transform: uppercase;
					font-size: 1.5rem;
					margin: 0 0 20px 10px;
					span {
						&:after {
							content: "\A";
							width: 10px;
							height: 10px;
							border-radius: 50%;
							background: rgba(211, 43, 43, 1);
							display: inline-block;
							margin-left: 7px;
						}
					}
				}

				.date {
					margin-left: 10px;
				}
				.event {
					margin-right: 2rem;
					height: 13rem !important;
					text-align: center;
					min-width: 30rem !important;
					background: radial-gradient(
						100% 100% at 0% 0%,
						rgba(255, 255, 255, 0.41) 0%,
						rgba(255, 255, 255, 0) 100%
					);
					box-shadow: inset -5px -5px 250px rgba(255, 255, 255, 0.02);
					-webkit-backdrop-filter: blur(42px);
					backdrop-filter: blur(42px);
					border-radius: 8px;
					border: 2px solid rgba(255, 255, 255, 0.6);
					display: flex;
					grid-template-areas: "division" "logos" "live" "title" "date" "button";
					.logos {
						display: flex;
						align-items: center;
						margin: 10px;
						* {
							margin: 7px;
						}
						.vs {
							.VS-V {
								position: relative;
								top: -0.2rem;
							}
							.VS-S {
								position: relative;
								bottom: -0.2rem;
							}
							img {
								margin: -0.5rem;
								width: 1.5rem;
							}
						}
						.logo-container {
							width: 8rem;
							height: 9rem;
							img {
								margin: 0;
								object-fit: contain;
								width: 100%;
								height: 100%;
							}
						}
					}
					.event-content {
						text-align: left;
						margin: 15px auto;
						.title {
							text-transform: uppercase;
							font-size: 1.5rem;
							margin: 0 0 15px 10px;
							font-weight: bold;
						}
						.live {
							padding-left: 10px;
							span {
								display: block;
								margin: auto;
								padding: 6px;
								font-size: 14px;
								line-height: 19px;
								font-style: italic;
								cursor: pointer;
								border-radius: 20px;
								&.off {
									background: linear-gradient(
										rgba(255, 255, 255, 0.4),
										rgba(255, 255, 255, 0)
									);
									border: 1px solid rgba(167, 167, 167, 0.4);
								}
								&.live {
									background-color: #d32b2b;
								}
							}
						}
					}
					.division {
						display: flex;
						align-items: center;
					}
				}
			}

			.result {
				span.count {
					opacity: 1;
					font-weight: 400;
				}
				span {
					opacity: 0.7;
					font-weight: 300;
					font-size: 17px;
					line-height: 24px;
					text-transform: uppercase;
				}
			}
			.banner {
				overflow: hidden;
				width: 90%;
				height: 25rem;
				margin: 3rem auto;
				position: relative;
				border-radius: 2rem;
				.item {
					img {
						position: absolute;
						top: 0;
						bottom: 0;
						margin: auto;
						width: 100%;
					}
					.title {
						position: absolute;
						bottom: 25%;
						z-index: 5;
						transform: translate(-50%, -50%);
						left: 50%;
						a {
							font-weight: 900;
							font-size: 4.2rem;
							text-transform: uppercase;
							display: block;
							border-bottom: 5px solid red;
							padding-bottom: 18px;
							color: white;
							text-decoration: none;
						}
					}

					.dots {
						position: absolute;
						top: 0;
						left: 0;
						width: 100%;
						height: 100%;
						z-index: 3;
					}
				}
			}
			.video {
				position: relative;
				width: 35rem !important;
			}
			.webTVS {
				margin-bottom: 10rem !important;
			}
			.videos-container {
				margin-top: 2rem;
				position: relative;
				max-width: 95%;
				margin-left: auto;
			}
		}
	}
}

@media (max-width: $mobile) {
	.search-container {
		.search {
			.search-section {
				justify-content: center;
				.close {
					position: relative;
                    top: -3rem;
					left: -2rem;
					margin: auto 0rem !important;
				}

				form {
					position: relative;
                    top: -3rem;
					left: 2rem;
					span {
						width: 100% !important;
						input {
                            justify-content: center;
							height: 2rem;
                            width: 15rem;
						}
                        .button {
                            position: absolute;
                            right: unset;
                            top: 5rem;
                            bottom: 0;
                            height: 100%;
                            width: auto;
                            border-radius: 4rem 4rem 4rem 4rem;
                            color: $white;
                            font-size: 2rem;
                            background: radial-gradient(
                                100% 100% at 80% -20%,
                                rgba(255, 255, 255, 0.28) 0%,
                                rgba(255, 255, 255, 0) 100%
                            );
                        }
						svg {
							width: 2rem;
							bottom: 1.2rem !important;
						}
					}
				}
			}

			.results {
				.popular-event {
					width: 90%;
					.event {
						height: 35rem !important;
						text-align: center;
						min-width: 30rem !important;
						max-width: 20% !important;
						background: radial-gradient(
							100% 100% at 0% 0%,
							rgba(255, 255, 255, 0.41) 0%,
							rgba(255, 255, 255, 0) 100%
						);
						box-shadow: inset -5px -5px 250px rgba(255, 255, 255, 0.02);
						backdrop-filter: blur(42px);
						border-radius: 8px;
						border: 2px solid rgba(255, 255, 255, 0.6);

						display: grid !important;

						.logos {
							display: flex;
							align-items: center;
							justify-content: center;
							margin: 10px;
							* {
								margin: 10px;
							}
							.logo-container {
								width: 11rem;
								height: 11rem;
								> img {
									margin: 0;
									object-fit: contain;
									width: 100%;
									height: 100%;
								}
							}

							.vs {
								img {
									margin: -0.5rem;
									width: 1.5rem;
								}
								.VS-V {
									width: 1.5rem;
									position: relative;
									top: 0;
								}
								.VS-S {
									width: 1.5rem;
									position: relative;
									bottom: 0.8rem;
									right: -0.5rem;
								}
							}
						}
						.event-content {
							.title {
								h4 {
									width: 80%;
									margin: auto;
									text-align: center;
								}
							}
							.date {
								text-align: center;
								span {
									text-align: center;
									font-size: smaller;
									font-style: italic;
									color: grey;
								}
							}
							.division {
								max-height: 40px;
								padding: 3px;
								margin-top: 1rem;
								display: block;

								position: relative;
								&::after {
									content: "";
									display: block;
									position: absolute;
									top: 0;
									left: 50%;
									transform: translateX(-50%);
									width: 75%;
									height: 1px;
									background-color: white;
								}
								h5 {
									font-size: 1.5rem;
									display: inline-block;
									text-align: center;
									margin: auto;
									position: absolute;
									left: calc(50% + 2rem);
									top: 50%;
									transform: translate(-50%, -50%);
								}
								img {
									width: 4.5rem;
									height: 4.5rem;
									margin: auto;
									position: absolute;
									left: calc(50% - 4rem);
									top: 50%;
									transform: translate(-50%, -50%);
								}
								span {
									display: block;
									width: 20px;
									height: 20px;
								}
							}
							.live {
								margin-top: 5rem;
								display: block;
								span {
									text-align: center;
									line-height: 1.8rem;
									display: block;
									width: 50%;
									margin: auto;
									padding: 6px;
									font-size: small;
									text-transform: uppercase;
									border-radius: 20px;
									box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.3);
									&.live {
										background-color: #d32b2b;
									}
									&.off {
										background-color: grey;
									}
								}
							}
						}
					}
				}

				.result {
					span {
						display: inline-block;
						margin: 0 0.5rem 2rem 0 !important;
					}
				}

				.banner {
					margin: -3rem auto;
					border-radius: 4rem !important;
				}
				.playlist-videos {
					margin-bottom: 0 !important;
				}
			}
		}
	}
}
